import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';

export default class EditDLT extends Component {
  constructor(props){
    super(props);
    this.state={
      val : props.dltPrincipalEntityId || ''
    }
  }
  handleEditDLT = () =>{
    let {val} = this.state;
    if(val.trim().length){
      this.props.editDLT({dltPrincipalEntityId : val.trim()});
    } else {
      alert('Provide DLT Id');
    }
  }
  render(){
    const {val} = this.state;
    return(
      <Dialog
        open={true}
        onClose={this.props.toggleEditDLTId}
      >
        <DialogTitle>Edit DLT ID</DialogTitle>
        <DialogContent style={{paddingTop: 16}}>
          <TextField
            label="Enter DLT ID"
            value={val}
            size='small'
            fullWidth
            required
            variant="standard"
            onChange={(e)=>{this.setState({val : e.target.value})}} />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.toggleEditDLTId}>Cancel</Button>
          <Button onClick={this.handleEditDLT} variant='contained'>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
