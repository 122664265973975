import React, {Component} from 'react'
import {Box, CssBaseline, Toolbar} from '@mui/material'
import {connect} from 'react-redux'

import {getSMSDetails} from '../../actions/sms'
import {getFromLocalStorage, setCookie} from '../../lib/utils'
import MenuBar from './menuBar'
import MenuDrawer from './menuDrawer'

class MenuBarDrawer extends Component {
  constructor() {
    super()
    this.isMobile = window.isMobile()
    this.state = {
      openDrawer: !this.isMobile
    }
  }
  componentDidMount() {
    this.props.getSMSDetails()
  }
  componentWillReceiveProps(nextProps) {
    if (this.isMobile) {
      this.setState({openDrawer: false})
    }
  }
  deleteCookieRedirectLogin = () => {
    setCookie('userToken', '', -1)
    setCookie('userId', '', -1)
    window.location.href = '/login'
  }
  handleDrawer = () => {
    this.setState({openDrawer: !this.state.openDrawer})
  }
  render() {
    const {title, baseConfiguration, type, children} = this.props
    const {openDrawer} = this.state
    if (!baseConfiguration) {
      this.deleteCookieRedirectLogin()
    }
    return (
      <Box sx={{display: 'flex'}}>
        <CssBaseline />
        <MenuBar
          title={ title }
          instituteName={ baseConfiguration.instituteName || baseConfiguration.tenantName }
          handleDrawer={ this.handleDrawer }
        />
        <MenuDrawer
          extraNav={ baseConfiguration.groupData }
          role={ baseConfiguration.role }
          type={ type }
          open={ openDrawer }
        />
        {!this.isMobile || !openDrawer ?
          <Box component="main" sx={{flexGrow: 1}}>
            <Toolbar />
            {children}
          </Box>
          : null
        }
      </Box>
    );
  }
}
function mapStateToProps(state) {
  if (state.loginResponse.loginResponse) {
    return {
      baseConfiguration: state.loginResponse.loginResponse.data
    }
  }
  return {
    baseConfiguration: getFromLocalStorage()
  }
}
export default connect(mapStateToProps, {getSMSDetails})(MenuBarDrawer)
