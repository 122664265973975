import Api from '../api';
import {
  GROUP_DETAILS,
  GROUP_URL,
  GROUP_CONTACT_URL,
  ADD_USER_GROUP,
  DELETE_USER_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  ADD_GROUP,
  ALL_GROUP_DETAILS,
  DOWNLOAD_GROUP_CONTACT,
  DOWNLOAD_GROUP_CONTACT_URL
} from '../lib/constant';
import { routeToLogin, getCookie, addToLocalStorage } from '../lib/utils';

export function groupDetails(gId){
    const request = Api.get(GROUP_URL + '/' + gId,'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:GROUP_DETAILS,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function addUserGroup(params,gId){
    const request = Api.post(GROUP_CONTACT_URL.replace('gId',gId), params, 'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:ADD_USER_GROUP,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function deleteUserGroup(gId,params){
    const request = Api.put(GROUP_CONTACT_URL.replace('gId',gId),params,'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:DELETE_USER_GROUP,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
export function updateGroup(gId,params){
    const request = Api.put(GROUP_URL + '/' + gId, params, 'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            if(data.data){
                addToLocalStorage(data.data.baseData);
            }
            dispatch({type:UPDATE_GROUP,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
export function addGroup(params){
    const request = Api.post(GROUP_URL, params, 'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            if(data.data){
                addToLocalStorage(data.data.baseData);
            }
            dispatch({type:ADD_GROUP,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function deleteGroup(gId){
    const request = Api.delete(GROUP_URL+ '/' + gId,'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            if(data.data){
                addToLocalStorage(data.data.baseData);
            }
            dispatch({type:DELETE_GROUP,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function downloadGroupContact(gId){
    const request = Api.get(DOWNLOAD_GROUP_CONTACT_URL.replace('gId',gId),'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:DOWNLOAD_GROUP_CONTACT,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
