import React, {Component} from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogTitle} from '@mui/material';

import {GAEventTracker} from '../../lib/googleAnalytics';

export default class UserDelete extends Component {
  constructor(){
    super();
    this.state = {
      btnDisabled : false
    }
    this.callbackHandler = this.callbackHandler.bind(this);
  }
  deleteUser(){
    this.setState({btnDisabled : true});
    this.props.handleDelete();
    GAEventTracker({category : 'Delete-Contact', action : 'Delete'});
  }
  callbackHandler = () => this.setState({btnDisabled:false});
  render(){
    let {details,closeDelete,bulkDelete} = this.props;
    let {btnDisabled} = this.state;
    return(
      <Dialog open={true}> 
        <DialogTitle>Delete {bulkDelete ? "selected contacts" : details.name} ?</DialogTitle>
        <DialogActions>
          <Button onClick={closeDelete}>Cancel</Button>
          <Button disabled={btnDisabled} onClick={this.deleteUser} variant='contained' color="error">Delete</Button>
          {
            btnDisabled ?
              <CircularProgress size={20} thickness={2} />
              : null
          }
        </DialogActions>
      </Dialog>
    )
  }
}
