import {combineReducers} from 'redux';
import login from './login';
import logout from './logout';
import user from './user';
import sms from './sms';
import student from './student';
import teacher from './teacher';
import smsHistory from './smsHistory';
import group from './group';
import website from './website';
import adminPanel from './adminPanel';
import dashboard from './dashboard';
import blockedContacts from './blockedContacts';
import template from './template';
import creditHistory from './creditHistory'

export default combineReducers({
  loginResponse: login,
  logoutResponse: logout,
  userResponse: user,
  smsResponse: sms,
  student,
  teacher,
  smsHistory,
  group,
  website,
  adminPanel,
  dashboard,
  blockedContacts,
  template,
  creditHistory
});
