/* eslint-disable max-len */
import React from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';

import {
  ITIndustryLogo,
  OTPLogo,
  autoMobileLogo,
  businessLogo,
  ecommerceLogo,
  entertainmentLogo,
  financeLogo,
  gymLogo,
  hospitalLogo,
  hotelLogo,
  logisticsLogo,
  marketingLogo,
  promoSMSLogo,
  realEstateLogo,
  schoolLogo,
  storeLogo,
  transSMSLogo,
  travelLogo
} from './WebLogo';
import Footer from './footer';
import Header from './header';

const Services = () => {
  const brandName = process.env.REACT_APP_BRAND_NAME
  return (
    <div>
      <Helmet>
        <title>Bulk SMS Services | {brandName}</title>
        <meta name="keywords" content="Bulk SMS Service, send bulk sms online, send sms in bulk, send sms from mobile, send sms from pc, send message online, send text message online, send sms from excel sheet, excel sheet sms, send bulk sms in india." />
        <meta name="description" content="Send Promotional, Transactional, Marketing and Business Bulk SMS, Get Real Time Analytics" />
      </Helmet>
      <Header headerClass="activeBlue" activeMenu="Services" />
      <div className="container-lg services-container">
        <h1>We are providing these services for you.</h1>
        <div className="service-types">
          <Link to="/transactional-bulk-sms" className="service">
            <div className="svg-icon" dangerouslySetInnerHTML={{__html: transSMSLogo}}></div>
            <p className="dark">Transactional Bulk SMS</p>
            <p>Send Bulk SMS to any numbers any time with unique sender-id</p>
          </Link>
          <Link to="/promotional-bulk-sms" className="service">
            <div className="svg-icon" dangerouslySetInnerHTML={{__html: promoSMSLogo}}></div>
            <p className="dark">Promotional Bulk SMS</p>
            <p>Send Bulk SMS to promote your brand without any restrictions.</p>
          </Link>
          <Link to="/otp-sms-services" className="service">
            <div className="svg-icon" dangerouslySetInnerHTML={{__html: OTPLogo}}></div>
            <p className="dark">OTP Bulk SMS</p>
            <p>Send OTP SMS to your customer from your system.</p>
          </Link>
        </div>
        <Link className="feature-button" to="/bulk-sms-features">Features</Link>
        <div className="customer-group" style={{textAlign:'center',marginBottom:20}}>
          <p className="tagline">Multiple features integrated into a single service, designed for your use. Pick your service.</p>
          <div className="customer-sector">
            <div className="icon">
              <div className="svg-icon" dangerouslySetInnerHTML={{__html: marketingLogo}}></div>
            </div>
            <div className="text">
              <h2>Marketing</h2>
              <p>Do promotion of your brands and utilize for marketing purpose to reach out each and every individual.</p>
            </div>
          </div>
          <div className="customer-sector">
            <div className="text">
              <h2>Business</h2>
              <p>Make use of sms communication for each sector of groups (online, offline) to increase your business.</p>
            </div>
            <div className="icon">
              <div className="svg-icon " dangerouslySetInnerHTML={{__html: businessLogo}}></div>
            </div>
          </div>
          <div className="customer-sector">
            <div className="icon">
              <div className="svg-icon" dangerouslySetInnerHTML={{__html: ITIndustryLogo}}></div>
            </div>
            <div className="text">
              <h2>IT Industry</h2>
              <p>Do promotion of your brands and utilize for marketing purpose to reach out each and every individual.</p>
            </div>
          </div>
          <div className="customer-sector">
            <div className="text">
              <h2>Entertainment</h2>
              <p>Make use of sms communication for each sector of groups (online, offline) to increase your business.</p>
            </div>
            <div className="icon">
              <div className="svg-icon " dangerouslySetInnerHTML={{__html: entertainmentLogo}}></div>
            </div>
          </div>
          <div className="customer-sector">
            <div className="icon">
              <div className="svg-icon" dangerouslySetInnerHTML={{__html: schoolLogo}}></div>
            </div>
            <div className="text">
              <h2>Schools & Colleges</h2>
              <p>Do promotion of your brands and utilize for marketing purpose to reach out each and every individual.</p>
            </div>
          </div>
          <div className="customer-sector">
            <div className="text">
              <h2>Hospitals & Medicals</h2>
              <p>Make use of sms communication for each sector of groups (online, offline) to increase your business.</p>
            </div>
            <div className="icon">
              <div className="svg-icon " dangerouslySetInnerHTML={{__html: hospitalLogo}}></div>
            </div>
          </div>
          <div className="customer-sector">
            <div className="icon">
              <div className="svg-icon" dangerouslySetInnerHTML={{__html: hotelLogo}}></div>
            </div>
            <div className="text">
              <h2>Hotels & Restaurants</h2>
              <p>Do promotion of your brands and utilize for marketing purpose to reach out each and every individual.</p>
            </div>
          </div>
          <div className="customer-sector">
            <div className="text">
              <h2>Gym and Beauty Parlors</h2>
              <p>Make use of sms communication for each sector of groups (online, offline) to increase your business.</p>
            </div>
            <div className="icon">
              <div className="svg-icon " dangerouslySetInnerHTML={{__html: gymLogo}}></div>
            </div>
          </div>
          <div className="customer-sector">
            <div className="icon">
              <div className="svg-icon" dangerouslySetInnerHTML={{__html: travelLogo}}></div>
            </div>
            <div className="text">
              <h2>Travel & Tourism</h2>
              <p>Do promotion of your brands and utilize for marketing purpose to reach out each and every individual.</p>
            </div>
          </div>
          <div className="customer-sector">
            <div className="text">
              <h2>E-commerce</h2>
              <p>Make use of sms communication for each sector of groups (online, offline) to increase your business.</p>
            </div>
            <div className="icon">
              <div className="svg-icon " dangerouslySetInnerHTML={{__html: ecommerceLogo}}></div>
            </div>
          </div>
          <div className="customer-sector">
            <div className="icon">
              <div className="svg-icon" dangerouslySetInnerHTML={{__html: financeLogo}}></div>
            </div>
            <div className="text">
              <h2>Finance</h2>
              <p>Do promotion of your brands and utilize for marketing purpose to reach out each and every individual.</p>
            </div>
          </div>
          <div className="customer-sector">
            <div className="text">
              <h2>Logistics</h2>
              <p>Make use of sms communication for each sector of groups (online, offline) to increase your business.</p>
            </div>
            <div className="icon">
              <div className="svg-icon " dangerouslySetInnerHTML={{__html: logisticsLogo}}></div>
            </div>
          </div>
          <div className="customer-sector">
            <div className="icon">
              <div className="svg-icon" dangerouslySetInnerHTML={{__html: realEstateLogo}}></div>
            </div>
            <div className="text">
              <h2>Real Estate</h2>
              <p>Do promotion of your brands and utilize for marketing purpose to reach out each and every individual.</p>
            </div>
          </div>
          <div className="customer-sector">
            <div className="text">
              <h2>Store</h2>
              <p>Make use of sms communication for each sector of groups (online, offline) to increase your business.</p>
            </div>
            <div className="icon">
              <div className="svg-icon " dangerouslySetInnerHTML={{__html: storeLogo}}></div>
            </div>
          </div>
          <div className="customer-sector">
            <div className="icon">
              <div className="svg-icon" dangerouslySetInnerHTML={{__html: autoMobileLogo}}></div>
            </div>
            <div className="text">
              <h2>Automobiles</h2>
              <p>Do promotion of your brands and utilize for marketing purpose to reach out each and every individual.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )}
   
export default Services
