/* eslint-disable max-len */
import React, {Component} from 'react';
import {Helmet} from "react-helmet";

import {GAEventTracker} from '../../lib/googleAnalytics';
import {emailValidator} from '../../lib/utils';
import Footer from './footer';
import Header from './header';
import {UI_API_CONTACT_US_URL_PATH} from '../../lib/constant'

export default class ContactUs extends Component{
  state = {
    loading: false
  }
  sumbitContact = () => {
    let name = document.getElementById('fullname').value;
    let mobile = document.getElementById('mobile').value;
    let email = document.getElementById('email').value;
    let company = document.getElementById('company').value;
    if(!name){
      alert("Please enter name");
    } else if (!emailValidator(email)) {
      alert("Please enter correct email ID");
    } else if(!/^[789]\d{9}$/.test(mobile)){
      alert("Please enter correct phone number");
    }  else if(!/^[A-Za-z0-9]{3,30}$/.test(company)){
      alert("Please enter company Name.");
    } else{
      GAEventTracker({category : 'Website', action : 'Sumbit Contact Successfully'});
      this.setState({loading: true})
      var that = this
      let xhttp = new XMLHttpRequest();
      xhttp.open("POST", `${UI_API_CONTACT_US_URL_PATH}`, true);
      xhttp.setRequestHeader("Content-Type", "application/json");
      xhttp.onreadystatechange = function() {
        that.setState({loading: false})
        if (this.readyState == 4 && this.status == 200) {
          alert("Thank you for showing interest. Our team will contact you soon!")
        }
      };
      xhttp.send(JSON.stringify({name,mobile,email,company}));
    }
  }
  render(){
    const companyName = process.env.REACT_APP_COMPANY_NAME
    return(
      <div>
        <Helmet>
          <title>Contact-us | {companyName}</title>
          <meta name="keywords" content="Bulk SMS Service, Free Bulk SMS, Send Bulk SMS, Bulk SMS Gateway, Contact Management System, Bulk SMS API, SMS API Integration, Bulk Promotional SMS, Bulk Markrting SMS, Bulk Transactional SMS, Promotional Bulk SMS, Transactional Bulk SMS, Free SMS, Free SMS Service, Online SMS Service, Mobile SMS, SMS gateway, Bulk SMS gateway, Bulk SMS Provider, Bulk SMS Software, SMS Service Provider, Bulk SMS India" />
          <meta name="description" content="Bulk sms support service, free support sms service." />
        </Helmet>
        <Header headerClass="activeLightBlue" activeMenu="Contact Us" />
        <div className="globalContent">
          <main className="contact-us-page">
            <section className="contact-sales-header">
              <div className="headline">
                <div className="container-lg">
                  <h1 className="headline-text">Help Us To Serve You</h1>
                  <h2 className="common-IntroText">Send Messages and OTPs to your customers with our robust APIs along with user friendly web portal.</h2>
                </div>
              </div>
            </section>
            <div className="contact-us-wrapper container-lg">
              <div className="content-wrapper">
                <img alt="Contact Us Form" src={process.env.REACT_APP_CONTACT_US_IMG} />
                <hr />
                <div className="trusted-section">
                  <div className="trusted-label">Trusted By</div>
                  <div className="trusted-list">
                    <div className="brand-name">KoshEx</div>
                    <div className="brand-name">EduGorilla</div>
                    <div className="brand-name">MaitriBodh</div>
                    <div className="brand-name">Mahanager Gas</div>
                    <div className="brand-name">IIFL</div>
                    <div className="brand-name">SpiderG</div>
                  </div>
                </div>
              </div>
              <div className="content-form">
                <form>
                  <fieldset>
                    <div className="form-row">
                      <label className="fullname" htmlFor="fullname">Your Name*</label>
                      <input id="fullname" name="fullname" type="text" placeholder="Enter your name" maxLength="30" required="" />
                    </div>
                    <div className="form-row">
                      <label className="email" htmlFor="email">Your Email*</label>
                      <input id="email" name="email" type="email" pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/" maxLength="30" placeholder="Enter Bussiness Mail ID" required="" />
                    </div>
                    <div className="form-row">
                      <label className="mobile" htmlFor="mobile">Phone Number*</label>
                      <input id="mobile" name="mobile" type="text" maxLength="10" placeholder="Enter your phone number" required="" />
                    </div>
                    <div className="form-row">
                      <label className="company" htmlFor="company">Company Name*</label>
                      <input id="company" name="company" type="text"  placeholder="Enter your company name" required="required" />
                    </div>
                  </fieldset>
                  <div>
                    <input type="submit" className={`submit-button common-Button common-Button--default ${this.state.loading ? "btn-disabled" : ""}`} value="Submit" onClick={e=>{e.preventDefault();this.sumbitContact();}} disabled={this.state.loading} />
                  </div>
                </form>
              </div>

            </div>
          </main>
        </div>
        <Footer />
      </div>
    )
  }
}
