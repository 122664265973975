import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';

import Select from '../../components/Select';
import CONSTANT from './constant';

export default class UpdateOperator extends Component {
  constructor(props){
    super(props);
    this.state={
      val : props.operatorId || ''
    }
  }
  handleChange = (e) => this.setState({val: e.target.value});
  render(){
    const {val} = this.state;
    return(
      <Dialog
        open={true}
        onClose={this.props.toggleUpdateOperatorId}
      >
        <DialogTitle>Update Operator ID</DialogTitle>
        <DialogContent style={{paddingTop: 16}}>
          <Select
            label='Select Operator'
            value={ val }
            fullWidth
            onChange={ this.handleChange }
            options={CONSTANT.OPERATOR_OPTS}
            sx={{minWidth: 150}}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.toggleUpdateOperatorId}>Cancel</Button>
          <Button onClick={() => this.props.updateOperator({operatorId : val})} variant='contained'>Update</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
