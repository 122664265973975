import { Map } from 'immutable';

import { WEBSITE } from '../lib/constant';

const initialState = Map({websiteResponse : {}});

export default function(state = initialState , action ) {
	switch(action.type){
        case WEBSITE :
            return {...state,websiteResponse:action.payload};
        default :
		     return state;
    }
}
