import React, {Component} from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';

export default class GroupEdit extends Component {
  constructor(props){
    super(props);
    this.state = {
      btnDisabled : false,
      name : props.name
    }
    this.callbackHandler = this.callbackHandler.bind(this);
  }
  editGroup(){
    this.setState({btnDisabled : true});
    this.props.handleEdit(this.state.name);
  }
  handleNameInput = (name) => this.setState({name, btnDisabled : name.length ? false : true});
  callbackHandler = () => this.setState({btnDisabled:false});
  render(){
    let {showEdit,closeEdit} = this.props;
    let {name,btnDisabled} = this.state;
    return(
      <Dialog open={showEdit}>
        <DialogTitle>
          Edit Group Name
        </DialogTitle>
        <DialogContent style={{width: 300, paddingTop: 16}}>
          <TextField
            value={name}
            onChange={(e)=>{this.handleNameInput(e.target.value)}}
            autoFocus
            size="small"
            required
            fullWidth
            variant="standard"
            label="Group Name" />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEdit}>
            Cancel
          </Button>
          <Button disabled={btnDisabled} onClick={this.editGroup} variant='contained'>
            Save
          </Button>
          {
            btnDisabled ?
              <CircularProgress size={20} thickness={2}/>
              : null
          }
        </DialogActions>
      </Dialog>
    )
  }
}
