import { Map } from 'immutable';

import { LOGOUT } from '../lib/constant';

const initialState = Map({logoutResponse : {}});

export default function(state = initialState , action ) {
	switch(action.type){
        case LOGOUT :
            return {...state,logoutResponse:action.payload};
        default :
		     return state;
    }
}
