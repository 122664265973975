import React, {Component} from 'react';
import {HighlightOff, Search} from '@mui/icons-material';
import {Button, CircularProgress, IconButton, Snackbar, TextField, Tooltip} from '@mui/material';
import {throttle} from 'lodash';
import {connect} from 'react-redux';

import {addGroup} from '../../actions/group';
import {getAllUser} from '../../actions/user';
import Table from '../../components/Table';
import {GAEventTracker} from '../../lib/googleAnalytics';

class CreateGroup extends Component {
  constructor(props){
    super(props);
    this.state = {
      autoCompleteValue : '',
      name : '',
      saveBtn : false,
      contactList : {},
      openSnackBar : false,
      btnDisabled : false
    }
    this.autoComplete = this.autoComplete.bind(this);
    this.throttleInput = throttle(this.throttleInput.bind(this),300);
    this.userList = props.userResponse ? props.userResponse.data.contacts : [];
    this.newUserList = this.userList;
    this.receivedUser = false;
  }
  componentWillMount(){
    if(this.userList.length == 0){
      this.props.getAllUser();
    }
  }
  componentWillReceiveProps(nextProps){
    let {userResponse,addGroupResponse} = nextProps;
    if(!this.receivedUser && this.userList.length == 0 && userResponse && this.props.userResponse != userResponse){
      if(userResponse.success){
        this.userList = userResponse.data.contacts;
        this.receivedUser = true;
      } else if(userResponse.err){
        this.receivedUser = true;
        alert(userResponse.err.msg);
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }
    }
    if(addGroupResponse && addGroupResponse != this.props.addGroupResponse){
      if(addGroupResponse.success){
        alert("Group Created Successfully!");
        window.location.href = "/group/"+addGroupResponse.data.groupId;
      } else if(addGroupResponse.err){
        alert(addGroupResponse.err.msg);
        this.setState({btnDisabled:false});
      } else{
        alert("Something went wrong, Please try again!");
        this.setState({btnDisabled:false});
      }
    }
  }
  autoComplete = (e) => {
    this.setState({autoCompleteValue:e.target.value});
    this.throttleInput(e.target.value);
  }
  throttleInput = (val) => {
    this.newUserList = this.userList.filter(data => {return (data.name.toLowerCase().indexOf(val.toLowerCase()) > -1 || data.mobile.indexOf(val) > -1);});
    this.setState(prev => ({...prev}))
  }
  removeAutoCompleteValue = (e) =>{
    e.stopPropagation()
    this.setState({autoCompleteValue : ''});
  }
  handleNameInput = (name) => this.setState({name, saveBtn : name.length ? true : false});
  handleCheckBox = (id, details) => {
    let {contactList,name} = this.state;
    if(contactList[id]){
      delete contactList[id];
    } else{
      contactList[id] = details;
    }
    this.setState({contactList,saveBtn:name.length ? true : false});
  }
  handleAllCheckBox = () => {
    let {contactList, name} = this.state;
    if (Object.keys(contactList).length === this.userList.length) {
      this.setState({contactList: {}, saveBtn: false});
    } else {
      const newList = {}
      for(var contact of this.userList){
        newList[contact.id] = contact;
      }
      this.setState({contactList: newList, saveBtn: !!name});
    }
  }
  handleSnackBarClose = () => this.setState({openSnackBar : false});
  addGroup = () => {
    let {name,contactList} = this.state;
    let params = {
      name,
      contacts : Object.keys(contactList)
    };
    GAEventTracker({category : 'Create-Group', action:'Click'});
    this.props.addGroup(params);
    this.setState({btnDisabled:true});
  }
  render() {
    let {autoCompleteValue,saveBtn,name,contactList,openSnackBar,btnDisabled} = this.state;
    let nameList = autoCompleteValue == '' ? this.userList : this.newUserList;
    const isMobile = window.isMobile();
    return (
      <div className='sms-parent-container' style={isMobile ? {padding: 8} : {}}>
        <div className='sms-small-container'>
          <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
            <TextField
              label="Group Name"
              value={name}
              onChange={(e)=>{this.handleNameInput(e.target.value)}}
              autoFocus
              size="small"
              variant="standard"
              className="group-name" />
            <Button disabled={!saveBtn || btnDisabled} onClick={this.addGroup} variant="contained">Add Group</Button>
            {
              btnDisabled ?
                <CircularProgress size={20} thickness={2} />
                : null
            }
          </div>
          {
            this.userList.length ?
              <>
                <p style={{marginTop:8, marginBottom: 8, fontSize:12}}>
                  Add contacts into Group
                  {
                    Object.keys(contactList).length ?
                      <span style={{marginLeft:8, fontWeight: 600}}>- Selected : {Object.keys(contactList).length}</span>
                      : null
                  }
                </p>
                <div style={{display: 'flex', alignItems: 'center', gap: 8, width: 250, marginBottom: 16}}>
                  <Search onClick={e => e.stopPropagation()} />
                  <TextField
                    label={isMobile ? "Search By Name" : "Search By Name/Number"}
                    fullWidth={true}
                    size="small"
                    variant="standard"
                    value={autoCompleteValue}
                    onChange={this.autoComplete.bind(this)} />
                  {
                    autoCompleteValue ?
                      <Tooltip title="Clear Search">
                        <IconButton aria-label='Clear' onClick={this.removeAutoCompleteValue}>
                          <HighlightOff />
                        </IconButton>
                      </Tooltip>
                      : null
                  }
                </div>
                {
                  nameList.length ?
                    <>
                      <Table
                        column={[
                          {name: 'Name', value: 'name'},
                          {name: 'Mobile', value: 'mobile'}
                        ]}
                        data={nameList}
                        enableCheckbox
                        handleSelect={this.handleCheckBox}
                        selected={Object.keys(contactList)}
                        showPagination
                        defaultPageSize={100}
                        showToolbar={false}
                        onSelectAllClick={this.handleAllCheckBox}
                      />
                    </>
                    :
                    <p style={{margin:30,fontSize:16}}>No Contact Available with searched keyword!</p>
                }
              </>
              : null
          }
        </div>
        {
          openSnackBar ?
            <Snackbar
              open={openSnackBar}
              message={'Group Added Successfully'}
              autoHideDuration={4000}
              onClose={this.handleSnackBarClose} />
            : null
        }
      </div>
    )
  }
}
function mapStateToProps(state){
  return {
    userResponse : state.userResponse.userResponse,
    addGroupResponse : state.group.addGroup
  };
}
export default connect(mapStateToProps, {getAllUser, addGroup})(CreateGroup);
