import Api from '../api';
import {GET_CREDIT_HISTORY_URL, GET_CREDIT_HISTORY} from '../lib/constant';
import {routeToLogin, getCookie} from '../lib/utils';

export function getAllCreditList(){
  const request = Api.get(GET_CREDIT_HISTORY_URL,'bearer '+getCookie('userToken'));
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({type:GET_CREDIT_HISTORY,payload:data})
    }).catch((data) => {
      if(data.response.status == 404 && data.response.data.success == false){
        routeToLogin();
      }
    })
  };
}
