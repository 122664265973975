import React, {Component} from 'react';
import {Email, Group, LocationCity, Person, Phone} from '@mui/icons-material';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';

import {GAEventTracker} from '../../lib/googleAnalytics';
import {emailValidator} from '../../lib/utils';
import Select from '../Select';

export default class UserAdd extends Component {
  constructor(){
    super();
    this.state = {
      name : '',
      mobile : '',
      email : '',
      address : '',
      saveBtn : false,
      emailError : false,
      btnDisabled : false,
      selectBoxValues : [],
      showConfirm: false
    };
    this.callbackHandler = this.callbackHandler.bind(this);
  }
  handleNameInput = (name) => {
    if(name && this.state.mobile.length == 10){
      this.setState({name, saveBtn : true});
    } else if(name == ''){
      this.setState({name, saveBtn : false});
    } else{
      this.setState({name});
    }
  }
  handleMobileInput = (mobile) => {
    if(mobile && mobile.length == 10 && this.state.name){
      this.setState({mobile, saveBtn : true});
    } else if(mobile == '' || mobile.length < 10){
      this.setState({mobile, saveBtn : false});
    } else{
      this.setState({mobile});
    }
  }
  handleSaveBtn = () => {
    if(this.state.email && !emailValidator(this.state.email)){
      this.setState({emailError : true});
    } else {
      this.setState({emailError : false,btnDisabled : true});
      let {name, mobile, email, address,selectBoxValues} = this.state;
      if(selectBoxValues.length){
        GAEventTracker({category : 'Add-Contact', action : 'Add to Group', value : selectBoxValues.length});
      }
      for (const data of this.props.userList) {
        if (data.mobile === mobile) {
          this.setState({showConfirm: true})
          return
        }
      }
      this.props.handleAddUser({name, mobile, email, address, groups : selectBoxValues});
    }
  }
  handleConfirmSaveBtn = () => {
    let {name, mobile, email, address,selectBoxValues} = this.state;
    this.props.handleAddUser({name, mobile, email, address, groups : selectBoxValues});
    GAEventTracker({category : 'Add-Contact', action : 'Add Existing Number'});
  }
  handleChange = (event) => {
    this.setState({selectBoxValues: event.target.value});
  }
  callbackHandler = () => this.setState({btnDisabled:false});

  handleIconClick = (e) => {e.stopPropagation()}
  
  render(){
    let {selectBoxValues,name,mobile,email,address,showConfirm} = this.state;
    let {label,closeAddUser} = this.props;
    return(
      <div>
        <Dialog
          open={true}
          onClose={closeAddUser}
        >
          <DialogTitle>{`Add ${label}`}</DialogTitle>
          <DialogContent dividers>
            <div style={{display: 'flex', alignItems: 'center', gap: 16, flexDirection: 'column', width: window.isMobile() ? 300 : 500}}>
              <div style={{display: 'flex', alignItems: 'center', gap: 8, width: 'inherit'}}>
                <Person onClick={this.handleIconClick} />
                <TextField
                  value={name}
                  onChange={(e)=>{this.handleNameInput(e.target.value)}}
                  label="Name"
                  required
                  autoFocus
                  size="small"
                  variant="standard"
                  fullWidth
                  onKeyPress={(event)=>{
                    if(!((event.charCode >= 97 && event.charCode <= 122) ||
                      (event.charCode >= 48 && event.charCode <= 57) ||
                      (event.charCode >= 65 && event.charCode <= 90) ||
                      event.charCode == 32 ||
                      event.charCode == 46 ||
                      event.charCode == 40 ||
                      event.charCode == 41 ||
                      event.charCode == 44 ||
                      event.charCode == 45 ||
                      event.charCode == 95)){
                      event.preventDefault()
                    }}}
                />
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 8, width: 'inherit'}}>
                <Phone onClick={this.handleIconClick}  />
                <TextField
                  label="Mobile Number"
                  required
                  maxLength="10"
                  type="number"
                  variant="standard"
                  value={mobile}
                  size="small"
                  fullWidth
                  onChange={(e)=>{this.handleMobileInput(e.target.value)}}
                />
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 8, width: 'inherit'}}>
                <Group onClick={this.handleIconClick}  />
                <Select
                  value={selectBoxValues}
                  onChange={this.handleChange}
                  label="Add to Group(s)"
                  sx={{minWidth: 470}}
                  multiple
                  options={this.props.groupList.map(el => ({value: el.id, name: el.name}))}
                />
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 8, width: 'inherit'}}>
                <Email onClick={this.handleIconClick}  />
                <TextField
                  value={email}
                  onChange={(e)=>{this.setState({email : e.target.value})}}
                  label="Email"
                  size="small"
                  variant="standard"
                  fullWidth
                  error={!!this.state.emailError}
                  helperText={this.state.emailError ? 'Please enter correct Email Id' : ''} />
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: 8, width: 'inherit'}}>
                <LocationCity onClick={this.handleIconClick}  />
                <TextField
                  value={address}
                  onChange={(e)=>{this.setState({address : e.target.value})}}
                  label="Address"
                  floatingLabelText="Address"
                  size="small"
                  variant="standard"
                  fullWidth
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeAddUser}>Cancel</Button>
            <Button disabled={!this.state.saveBtn || this.state.btnDisabled} onClick={this.handleSaveBtn} variant='contained'>Save</Button>
          </DialogActions>
        </Dialog>
        {showConfirm &&
          <Dialog open>
            <DialogTitle>Mobile Number already exists, do you still want to save it?</DialogTitle>
            <DialogActions>
              <Button onClick={e => this.setState({showConfirm: false})}>
                No
              </Button>
              <Button onClick={this.handleConfirmSaveBtn} variant='contained'>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    )
  }
}
