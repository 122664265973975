import React from 'react';

const labelMap = {TRANS : 'T', PROMO : 'P', OTP : 'O'};

const SMSBalance = ({smsDetails}) =>(
  <p style={{fontSize:14, background: 'white', padding: '4px 8px', borderRadius: 8, display: 'inline-block', marginBottom: 8}}>
    SMS Balance -
    <span style={{display:'inline-block',verticalAlign:'top'}}>
      {
        smsDetails.smsType.map(data=>(
          <p key={data+'count'} style={{display:'inline-block',verticalAlign:'top',marginLeft:10}}>
            <span style={{fontWeight:'500'}}>{labelMap[data]}</span> : {smsDetails.smsCount[data]}
          </p>
        ))
      }
    </span>
  </p>
)

export default SMSBalance