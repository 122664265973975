export function isStringBlank(str){
	if(str==undefined){
		return true;
	}
	let testExp = new RegExp("(^$|^null$|^undefined$|^[ ]+$)");
	return testExp.test(str);
}

export function isWebWorkerSupported(){
    return !!window.Worker;
}

export function setCookie(cname, cvalue, exdays) {
  let d = new Date();
  exdays = parseFloat(exdays);
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = exdays == -1 ? "expires=Thu, 01 Jan 1970 00:00:01 GMT" :  "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires+";path=/";
}

export function getCookie(cname){
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i=0; i<ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
  }
  return "";
}

export function routeToLogin(){
	setCookie("userToken","", -1);
	setCookie("userId","", -1);
	window.location.href = '/login';
}

export function mobileValidator(num){
	return /^[3-9]\d{9}$/.test(num);
}

export function emailValidator(email){
	return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

export function numPad(num, size, padVal){
	let numString = num + "";
	while(numString.length < size) {
		numString = padVal + numString;
	}
	return numString;
}

function  _getFormattedDate(date) {
  let year = date.getFullYear();
  year = year.toString().substring(2);

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return {
    date,day,month,year
  }
}

export function  spaceSeperatedDate(date){
  let {day, month,year } = _getFormattedDate(date);
  return `${day}-${month}-${year}`;
}


export function dateFromUnixEpoch(secondStr){
	let seconds = parseInt(secondStr)
	let milliSeconds = seconds *1000;
	let dateTime = new Date(milliSeconds);
	return dateTime
}

export function dateFormat(dateVal,isMobile,shortView){
	let mon = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	let date = new Date(dateVal);
	let AP = "AM";
	let MM = numPad(date.getMinutes(), 2, 0);
	let ss = numPad(date.getSeconds(), 2, 0);
	let hh = date.getHours();
	let fff = date.getMilliseconds();
	let HH = hh;
	if (hh>11) {
		HH = hh%12;
		AP = "PM";
	}
	if(HH==0) {
		HH = 12;
	}
	HH = numPad(HH, 2, 0);
	hh = numPad(hh, 2, 0);
	let dd = numPad(date.getDate(), 2, 0);
	let mm = date.getMonth();
	let mmm = mon[mm];
	mm = numPad(mm+1, 2, 0);
	let yyyy = date.getFullYear();
	let yy = numPad(yyyy%100,2,0);
	if(isMobile){
		if(shortView){
			return (dd+" "+mmm+"'"+yy+", "+HH+":"+MM+":"+ss+" "+AP);
		} else{
			return (dd+"/"+mm+"/"+yy);
		}
	} else{
		return (dd+" "+mmm+" "+yyyy+", "+HH+":"+MM+":"+ss+" "+AP);
	}
}

const OLDCurrentLocalStorage = "epadhaiBaseData" // this will be current for existing user
const NewLocalStorage = "epadhaiBaseData_v2"
export function addToLocalStorage(data) {
	try{
		window.localStorage.setItem(NewLocalStorage,JSON.stringify(data));
	} catch(err){
		routeToLogin()
	}
	// window.localStorage.setItem(OLDCurrentLocalStorage,JSON.stringify(data));
}

export function getFromLocalStorage() {
	try{
	let data  = JSON.parse(window.localStorage.getItem(OLDCurrentLocalStorage));
	if (data){
		window.localStorage.removeItem(OLDCurrentLocalStorage);
		routeToLogin()
		return data
	}else{
		data = JSON.parse(window.localStorage.getItem(NewLocalStorage));
	}
	return data 
} catch(err){
	routeToLogin()
}
}

export function removeFromLocalStorage(){
	try{
	window.localStorage.removeItem(NewLocalStorage);
	} catch(err){
		routeToLogin()
	}
}

export function isGroupAvaible(gId) {
	let groupData = getFromLocalStorage().groupData;
	let isGroup = false;
	for(let group of groupData){
		if(group.id == gId){
			isGroup = true;
			break;
		}
	}
	return isGroup;
}

export function isURLValid(url) {
	const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
	return re.test(url)
}

export function sortGroup(list) {
	return [...list].sort((first, second) => (first.name.toLowerCase() > second.name.toLowerCase()) ? 1 : -1)
}

export function isNotValidPromoTime() {
	const date = new Date();
	return date.getHours() <= 9 || date.getHours() >= 21 || (date.getHours() >= 20 && date.getMinutes() >= 55)
}

export const convertToLocale = num => num.toLocaleString('en-IN')

const convertToNormal = num => convertToLocale(parseFloat(num))


export const localeNumber = (number, percentage = false) => {
  try {
    return percentage
      ? parseFloat(parseFloat(convertToNormal(number)).toFixed(2))
      : convertToNormal(number)
  } catch (error) {
    return number
  }
}


const convertToNumber = (num, conversionValue) => {
  return convertToLocale(parseFloat(parseFloat(num / conversionValue).toFixed(2)))
}

const convertToLakh = num => `${convertToNumber(num, 100000)}L`

const convertToThousand = num => `${convertToNumber(num, 1000)}T`

export const formatNumber = number => {
  try {
    let num = 0

    if (number >= 100000) {
      num = convertToLakh(number)
    } else if (number < 100000 && number > 999) {
      num = convertToThousand(number)
    } else {
      num = convertToNormal(number)
    }

    return num
  } catch (error) {
    return number
  }
}

export const validateLoginRequest = (param) => {
  let errorJson = {
    userIdMobile : '',
    password : ''
  }
  let passValidation = true;
  if(isStringBlank(param.userIdMobile)){
    errorJson.userIdMobile = 'Please enter User Id/Mobile No';
    passValidation = false;
  }
  if(isStringBlank(param.password)){
    errorJson.password = 'Please enter Password';
    passValidation = false;
  }
  return {isValidated : passValidation, errorJson : errorJson};
}
