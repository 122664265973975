/* eslint-disable max-len */
import React from 'react';
import {CheckCircleOutline} from '@mui/icons-material';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';

import Footer from './footer';
import Header from './header';

const Pricing = () => {
  const PRICE = [
    {
      trans: {quantity: '10 Lakhs', price: '12', total: '1,20,000'},
      promo: {quantity: '10 Lakhs', price: '11', total: '1,10,000'}
    },
    {
      trans: {quantity: '5 Lakhs', price: '13', total: '65,000'},
      promo: {quantity: '5 Lakhs', price: '12', total: '60,000'}
    },
    {
      trans: {quantity: '1 Lakh', price: '14', total: '14,000'},
      promo: {quantity: '1 Lakh', price: '13', total: '13,000'}
    },
    {
      trans: {quantity: '50,000', price: '16', total: '8,000'},
      promo: {quantity: '50,000', price: '14', total: '7,000'}
    },
    {
      trans: {quantity: '25,000', price: '18', total: '4,500'},
      promo: {quantity: '25,000', price: '16', total: '4,000'}
    },
    {
      trans: {quantity: '10,000', price: '21', total: '2,100'},
      promo: {quantity: '10,000', price: '19', total: '1,900'}
    }
  ]
  const PACKAGE_INCL = [
    'Dedicated OTP Route',
    'DLT Registration Support',
    'Lifetime Validity',
    'No Monthly/Setup Charges',
    'API Integrations for multiple languages',
    'ISO 27001 Certified, 100% Data Secure',
    '99.99 % Uptime Via AWS Infrastructure'
  ]
  const brandName = process.env.REACT_APP_BRAND_NAME
  const contactPhonePrimary = process.env.REACT_APP_CONTACT_PHONE_PRIMARY
  return(
    <div>
      <Helmet>
        <title>Bulk SMS Pricing | {brandName}</title>
        <meta
          name="keywords"
          content="Bulk SMS Service, sms price, sms pricing packages,sms bundles, buy sms online, SMS Pricing, bulk sms price, cheap bulk sms, promotional bulk sms pricing, transactional sms pricing, business sms pricing, promotional sms cost, transactional sms cost"
        />
        <meta name="description" content="Buy Cheap bulk sms service with no hidden cost. Get a free trial for bulk sms service." />
      </Helmet>
      <Header headerClass="activeGreen" activeMenu="Pricing" />
      <div className="globalContent bulk-sms-pricing">
        <div className="Header">
          <div className="Header-container">
            <div className="row middle-xs center-xs">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-35">
                <div className="Plan Plan--standard">
                  <h2 className="Plan-title common-Uppercase">PACKAGE INCLUSIONS</h2>
                  <ul className="Plan-list">
                    {PACKAGE_INCL.map((el, index) => (
                      <li className="Plan-listItem" key={index} style={{display: 'flex', alignItems: 'center', gap: 4}}>
                        <CheckCircleOutline color="success" fontSize="small" /> {el}
                      </li>
                    ))}
                  </ul>
                  <p className='custom-price'>For Custom Price: Call @{contactPhonePrimary}</p>
                  <Link to="/contact-us" className="Plan-button common-Uppercase common-Link--arrow" style={{padding: '0px 16px 16px 16px'}}>
                                            Contact US
                  </Link>
                </div>
              </div>
              <div className="col-xs-11 col-sm-6 col-md-6 col-lg-6 col-65">
                <div className="Plan Plan--enterprise">
                  <h2 className="Plan-title Plan-title--enterprise common-Uppercase">Pricing Structure</h2>
                  <div className="Plan-tableContainer">
                    <table className="Plan-table">
                      <tbody>
                        <tr className="Plan-table-row">
                          <th className="Plan-table-cell Plan-table-cell--alternating">Transactional SMS</th>
                          <th className="Plan-table-cell">Promotional SMS with Sender Id*</th>
                        </tr>
                        {PRICE.map((el, index) => (
                          <tr key={index}>
                            <td className="Plan-table-cell light">
                              <span className="semi-bold">{el.trans.quantity}</span> SMS @<span className="semi-bold">{el.trans.price}</span> Paise/SMS - Rs {el.trans.total}
                            </td>
                            <td className="Plan-table-cell light">
                              <span className="semi-bold">{el.promo.quantity}</span> SMS @<span className="semi-bold">{el.promo.price}</span> Paise/SMS - Rs {el.promo.total}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <p style={{fontSize: 14, paddingBottom: 10, color: 'white'}}>
                        * Promotional messages can be sent only to non-DND numbers and between 10:00 AM to 9:00 PM only.
                    </p>
                  </div>
                  <p className="Plan-button Plan-Button--enterprise" style={{fontSize:16,padding:10}}>NOTE: 18% GST is applicable on each billing amount</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Pricing
