import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';

export default class AddSenderId extends Component {
  constructor(){
    super();
    this.state={
      val : ''
    }
  }
  handleAddSenderIds = () =>{
    let {val} = this.state;
    if(val.trim().length){
      this.props.addSenderId({senderIds : val.split(/(?:,| ,| , |, | |\n| \n|\n | \n )+/)});
    } else {
      alert('Provide Header');
    }
  }
  render(){
    const {val} = this.state;
    return(
      <Dialog
        open={true}
        onClose={this.props.closeAddSenderId}
      >
        <DialogTitle>
            Add Header
        </DialogTitle>
        <DialogContent style={{paddingTop: 16}}>
          <TextField
            label="Comma Separated Headers"
            size="small"
            fullWidth
            value={val}
            variant="standard"
            required
            sx={{minWidth: 300}}
            onChange={(e)=>{this.setState({val : e.target.value})}} />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeAddSenderId}>
            Cancel
          </Button>
          <Button onClick={this.handleAddSenderIds} variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
