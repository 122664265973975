import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';

import {mobileValidator} from '../../lib/utils';
import Number from './number';

export default class AddNumber extends Component {
  constructor() {
    super();
    this.state = {
      input: ''
    };
  }
  onChangeNumberField = (e) => this.setState({input: e.target.value})
  handleDialogDone = () => {
    const contactNos = [];
    const {input} = this.state;
    const nos = input.split(/(?:,| ,| , |, | |\n| \n|\n | \n )+/);
    for (const num of nos) {
      if (mobileValidator(num) && contactNos.indexOf(num) == -1) {
        contactNos.push(num);
      }
    }
    this.props.handleAddNumber(contactNos);
  }
  handleDialogCloseNoAction = () => {
    this.props.handleAddNumber();
  }


  render() {
    return (
      <Dialog open onClose={ this.handleDialogCloseNoAction }>
        <DialogTitle>
          Add Mobile Number(s) to Block
        </DialogTitle>
        <DialogContent sx={{width: window.isMobile() ? 300 : 600}}>
          <Number onChange={ this.onChangeNumberField } />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleDialogCloseNoAction}>
            Cancel
          </Button>
          <Button onClick={this.handleDialogDone} variant='contained'>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
