import React from 'react'
import {InputLabel, MenuItem, FormControl, Select as MuiSelect, Checkbox} from '@mui/material'

const Select = ({id, sx, label, value, onChange, options, disabled = false, multiple=false, required=false, onClose}) => (
  <FormControl required={required} sx={sx} size="small" variant='standard' key={id}>
    <InputLabel id={`${id}-label`} shrink={multiple ? value.length > 0 :  value === 0 ? true : !!value}>{label}</InputLabel>
    <MuiSelect
      labelId={`${id}-label`}
      multiple={multiple}
      id={id}
      value={value}
      label={label}
      onChange={onChange}
      disabled={disabled}
      {...(multiple ? {renderValue: selected => selected.join(', ')} : {})}
      required={required}
      {...(onClose ? {onClose} : {})}
    >
      {options.map(el => (
        <MenuItem
          key={el?.value ?? el}
          value={el?.value ?? el}
        >
          {multiple ? <Checkbox checked={value.includes(el?.value ?? el)} /> : null}
          {el?.name || el?.node || el}
        </MenuItem>
      ))}
    </MuiSelect>
  </FormControl>
)

export default Select