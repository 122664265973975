import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';


export default class ChangePassword extends Component {
  constructor(props){
    super(props);
    this.state={
      password : ''
    }
  }
  handleChange = (password) => this.setState({password});
  changePassword = () =>{
    const {password} = this.state;
    const {instituteId, userId,accountId} = this.props.data;
    if(password.length >= 6) {
      this.props.changePassword(instituteId,accountId,{
        password
      });
    } else {
      alert("Provide more than 6 length Password")
    }
  }
  render(){
    const {password} = this.state;
    return(
      <Dialog
        open={true}
        onClose={this.props.closeChangePassword}
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent style={{paddingTop: 16}}>
          <TextField
            label="Password"
            value={password}
            size="small"
            variant="standard"
            fullWidth
            required
            onChange={(e)=>{this.handleChange(e.target.value)}} />                    
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeChangePassword}>Cancel</Button>
          <Button onClick={this.changePassword} variant='contained'>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
