/* eslint-disable max-len */
import React from 'react';
import {Helmet} from "react-helmet";
import {Group, Info} from '@mui/icons-material';

import Header from './header';
import Footer from './footer';

const AboutUs = () => {
  const brandName = process.env.REACT_APP_BRAND_NAME
  const companyName = process.env.REACT_APP_COMPANY_NAME
  return (
    <div>
      <Helmet>
        <title>About Us | {companyName}</title>
      </Helmet>
      <Header headerClass="activeLightBlue" activeMenu="About Us" />
      <div className="globalContent">
        <section className="about">
          <div className="content">
            <div className="bio">
              <h2 style={{display: 'flex', alignItems: 'center', gap: 4}}><Info /> <span>About {brandName}</span></h2>
              <h3>
                {brandName} is the best way to send bulk messages through online via mobile and desktop. We provide promotional as well as transactional SMS at very cheap rate which is available in market. It is offardable by all types of users. Our first aim is to make customer happy. What separtes us from others is the rate and delivery rate along with super Contact Management System.
              </h3>
            </div>
            <div className="who">
              <h2 style={{display: 'flex', alignItems: 'center', gap: 4}}><Group /> <span>Who we are</span></h2>
              <h3>
                We are {companyName} which was founded in Aug 2017 by a Passionate Team of Three Tech Individuals with a Mission to connect different Institutes in India with their repective dependants through an Advanced User Friendly Web/Mobile Platform so that these Institutes ( Schools / Colleges / Coachings / Hospitals / Retail Stores / Other Business ) can communicate to their students/staffs/clients through Messages. The Company aims to provide a Smooth, Easy to Understand and Fast System with lots of features so that the system can be operated by common People and require least expertise.
              </h3>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default AboutUs