import React from 'react'
import {Menu} from '@mui/icons-material'
import {AppBar, IconButton, Toolbar} from '@mui/material'

import {BRAND_LOGO_LINK} from '../../lib/constant'
import {GAEventTracker} from '../../lib/googleAnalytics'
import AccountMenu from './AccountMenu'
import SMSBalanceHeader from './SMSBalanceHeader'

const MenuBar = ({instituteName, title, handleDrawer}) => {
  const isMobile = window.isMobile()
  return (
    <AppBar position="fixed" sx={{zIndex: 1201}}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{mr: 2}}
          onClick={e => {e.stopPropagation();handleDrawer()}}
        >
          <Menu />
        </IconButton>
        <div style={ isMobile ? {fontSize: 16, flexGrow: 1} : {fontSize: 24, flexGrow: 1} }>
          {instituteName}
          <span style={ isMobile ? {marginLeft: '24px', fontWeight: 300, fontSize: '16px'} : {marginLeft: '24px', fontWeight: 300, fontSize: '18px'} }>{title} </span>
        </div>
        { isMobile ? <AccountMenu /> :
          <div style={{display: 'flex', alignItems: 'center'}}>
            <SMSBalanceHeader />
            <a
              style={{marginLeft: 16}}
              href='/'
              target='_blank'
              onClick={() => {GAEventTracker({category: 'Epadhai Logo', action: 'Logged-in User'});}}
            >
              <img
                src={BRAND_LOGO_LINK}
                alt=''
                style={{width: 50, height: 50, verticalAlign: 'middle'}}
              />
            </a>
            <AccountMenu />
          </div> }
      </Toolbar>
    </AppBar>
  )
}

export default MenuBar
