import React, {Component} from 'react';
import {FileUpload} from '@mui/icons-material';
import {Button} from '@mui/material';

import UploadPanel from './uploadPanel';

export default class BulkContactUpload extends Component {
  constructor() {
    super();
    this.state = {
      show: false
    };
  }
  showUploadPanel = (e) => {
    e.stopPropagation()
    this.setState({show: true});
  }
  hideDialog = () => {
    this.setState({show: false});
  }
  render() {
    return (
      <>
        <Button startIcon={<FileUpload />} onClick={this.showUploadPanel} variant='outlined'>
          Add/Import Bulk Block List
        </Button>
        {
          this.state.show ?
            <UploadPanel
              hideDialog={ this.hideDialog }
              applyUpload={this.props.applyBulk}
            />
            : null
        }
      </>
    );
  }
}
