import { Map } from 'immutable';

import { SEND_SMS, GET_SCHEDULED_SMS, RE_SCHEDULED_SMS, CANCEL_SCHEDULED_SMS, SMS_DETAILS, SEND_CUSTOM_SMS, UPDATE_SMS_DETAILS } from '../lib/constant';

const initialState = Map({ sendSmsResponse: {}, sendCustomSmsResponse: {} });

export default function (state = initialState, action) {
  switch (action.type) {
    case SEND_SMS :
      return { ...state, sendSmsResponse: action.payload };
    case SEND_CUSTOM_SMS :
      return { ...state, sendCustomSmsResponse: action.payload };
    case GET_SCHEDULED_SMS :
      return { ...state, scheduledSMSResponse: action.payload };
    case RE_SCHEDULED_SMS :
      return { ...state, reScheduledSMSResponse: action.payload };
    case CANCEL_SCHEDULED_SMS :
      return { ...state, cancelScheduledSMSResponse: action.payload };
    case SMS_DETAILS :
      return { ...state, smsDetailsResponse: action.payload };
    case UPDATE_SMS_DETAILS :
      const prevSmsDetailsResponse = { ...state.smsDetailsResponse };
      prevSmsDetailsResponse.data.smsCount = action.payload;
      return { ...state, smsDetailsResponse: prevSmsDetailsResponse };
    default :
      return state;
  }
}
