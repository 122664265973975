import {Map} from 'immutable';
import {
  GET_ALL_SMS_HISTORY,
  GET_SMS_HISTORY,
  GET_TRACK_CAMPAIGN,
  SMS_HISTORY_DWNL_FILTER_REPORT_JOB_ID,
  SMS_HISTORY_SMART_STATUS,
  SMS_HISTORY_STATUS
} from '../lib/constant';

const initialState = Map({
  getHistoryResponse: {},
  getAllHistoryResponse: {},
  getSingleHistoryResponse: {},
  getSingleSmartHistoryResponse: {},
  getTrackCampaignResponse: {},
  getDownloadReportResponse: {},
  getSmartDownloadReportResponse: {},
  smsHistoryExportJobID: {}
});

export default function(state = initialState , action ) {
  switch(action.type){
  case GET_SMS_HISTORY :
    return {...state,getHistoryResponse:action.payload};
  case GET_ALL_SMS_HISTORY:
    return {...state,getAllHistoryResponse:action.payload};
  case SMS_HISTORY_STATUS :
    return {...state,getSingleHistoryResponse:action.payload};
  case SMS_HISTORY_SMART_STATUS :
    return {...state,getSingleSmartHistoryResponse:action.payload};
  case GET_TRACK_CAMPAIGN :
    return {...state,getTrackCampaignResponse:action.payload};
  case SMS_HISTORY_DWNL_FILTER_REPORT_JOB_ID: 
    return {...state, smsHistoryExportJobID: action.payload}
  default :
    return state;
  }
}
