import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';

import {GAEventTracker} from '../../lib/googleAnalytics';
import {mobileValidator} from '../../lib/utils';
import Number from './number';

export default class AddNumber extends Component {
  constructor(){
    super();
    this.state={
      input : ''
    };
  }
  handleDialogDone = () =>{
    let contactNos = [],contactNoMap = [],{input} = this.state;
    let nos = input.split(/(?:,| ,| , |, | |\n| \n|\n | \n )+/);
    for(let num of nos){
      if(mobileValidator(num) && contactNos.indexOf(num) == -1){
        contactNos.push(num);
        contactNoMap.push({id : num, name : num});
      }
    }
    this.props.handleAddNumber(contactNoMap);
    GAEventTracker({category : 'Add Extra Number', action : 'Done', value : contactNoMap.length});
  }
  handleDialogCloseNoAction = () => {
    this.props.handleAddNumber();
    GAEventTracker({category : 'Add Extra Number', action : 'Cancel'});
  }
  onChangeNumberField = (e) => this.setState({input : e.target.value})

  render(){
    return(
      <Dialog
        open
        onClose={this.handleDialogCloseNoAction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add Mobile Number(s)
        </DialogTitle>
        <DialogContent sx={window.isMobile() ? {width: 300} : {width: 600}} style={{paddingTop: 16}}>
          <Number onChange={this.onChangeNumberField} />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleDialogCloseNoAction}>Cancel</Button>
          <Button onClick={this.handleDialogDone} variant="contained">Add</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
