import React, {Component} from 'react'
import {Add, AdminPanelSettings, Assessment, Code, Contacts, CurrencyRupee, Dashboard as DashboardIcon, Email, ExpandLess, ExpandMore, Group, Groups, MarkEmailRead,
  MarkEmailUnread, Schedule, Subtitles} from '@mui/icons-material'
import {Box, Collapse, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar} from '@mui/material'
import {blue} from '@mui/material/colors';
import {styled} from '@mui/material/styles';
import {withRouter} from 'react-router-dom'

import {GAEventTracker} from '../../lib/googleAnalytics'
import {routeCodes} from '../../views/App'

const NAV = [
  {type: 'DASHBOARD', text: 'Dashboard', icon: DashboardIcon},
  {type: 'SMS', text: 'Send SMS', icon: Email},
  {type: 'CUSTOMIZE_SMS', text: 'Customize SMS', icon: MarkEmailUnread},
  {type: 'TEMPLATE', text: 'Manage Templates', icon: Subtitles},
  {type: 'CONTACTS', text: 'Manage Contacts', icon: Contacts}
]
const SEC_NAV = [
  {type: 'BLOCKED_CONTACTS', text: 'Manage Blocked Numbers', icon: Contacts},
  // {type: 'SCHEDULED_SMS', text: 'Scheduled SMS', icon: Schedule},
  {type: 'SMS_HISTORY', text: 'SMS Reports', icon: Assessment},
  {type: 'ALL_SMS_HISTORY', text: 'Sent Messages', icon: MarkEmailRead},
  {type: 'CREDIT_HISTORY', text: 'Credit History', icon: CurrencyRupee}
]
const drawerWidth = 264

const CustomListItemButton = styled(ListItemButton)({
  '&.Mui-selected': {
    borderRight: `1px solid ${blue[700]}`
  }
});

class MenuDrawer extends Component {
  constructor(props) {
    super(props)
    const {open, type, extraNav=[]} = props
    this.state = {
      openDrawer: open,
      showGroup: !!(type === 'GROUP' || type === 'CREATE_GROUP'),
      groupOpen: false
    };
    this.menuBar = []
    for (const nav of extraNav) {
      this.menuBar.push({type: 'GROUP', primaryText: nav.name, key: `GROUP${ nav.id }`, id: nav.id, icon: 'group'})
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.openDrawer) {
      this.setState({openDrawer: nextProps.open})
    }
  }
  navRoute = (e, route, id) => {
    e.stopPropagation()
    if (id) {
      this.props.history.push(route.replace(':groupId', id))
    } else {
      this.props.history.push(route)
    }
  }
  toggleGroup = (e) => {
    e.stopPropagation()
    const {showGroup, groupOpen} = this.state
    this.setState({showGroup: !showGroup, groupOpen: !groupOpen})
    GAEventTracker({category: 'Group-Toggle', action: 'Click'})
  }
  render() {
    let {type, role} = this.props
    const {showGroup, groupOpen, openDrawer} = this.state
    if (type === 'GROUP') {
      const gId = this.props.history.location.pathname.split('/group/')[1];
      type = `GROUP${ gId }`
    }
    return (
      openDrawer ?
        <Drawer          
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'}
          }}
          className={window.isMobile() ? 'iphone-touch-scroll' : ''}>
          <Toolbar />
          <Box sx={{overflow: 'auto'}}>
            <List>
              {role === 'admin' &&
                <ListItem key="ADMIN_PANEL" disablePadding>
                  <CustomListItemButton selected={type === 'ADMIN_PANEL'} onClick={(e) => this.navRoute(e, routeCodes.ADMIN_PANEL)}>
                    <ListItemIcon sx={{minWidth: 36}}>
                      <AdminPanelSettings {...(type === 'ADMIN_PANEL' ? {color:"primary"} : {})} />
                    </ListItemIcon>
                    <ListItemText primary="Admin Panel" {...(type === 'ADMIN_PANEL' ? {primaryTypographyProps: {color: "#1976d2"}} : {})} />
                  </CustomListItemButton>
                </ListItem>}
              {NAV.map(el =>
                <ListItem key={el.type} disablePadding>
                  <CustomListItemButton selected={type === el.type} onClick={(e) => this.navRoute(e, routeCodes[el.type])}>
                    <ListItemIcon sx={{minWidth: 36}}>
                      <el.icon {...(type === el.type ? {color:"primary"} : {})} />
                    </ListItemIcon>
                    <ListItemText primary={el.text} {...(type === el.type ? {primaryTypographyProps: {color: "#1976d2"}} : {})} />
                  </CustomListItemButton>
                </ListItem>
              )}
            </List>
            <Divider />
            <ListItem key="CREATE_GROUP" disablePadding>
              <CustomListItemButton selected={type === 'CREATE_GROUP'} onClick={(e) => this.navRoute(e, routeCodes.CREATE_GROUP)}>
                <ListItemIcon sx={{minWidth: 36}}>
                  <Add {...(type === 'CREATE_GROUP' ? {color:"primary"} : {})} />
                </ListItemIcon>
                <ListItemText primary="Create Group" {...(type === 'CREATE_GROUP' ? {primaryTypographyProps: {color: "#1976d2"}} : {})} />
              </CustomListItemButton>
            </ListItem>
            <Divider />
            <ListItem key="GROUPS" disablePadding>
              <CustomListItemButton onClick={this.toggleGroup}>
                <ListItemIcon sx={{minWidth: 36}}>
                  <Groups />
                </ListItemIcon>
                <ListItemText primary="Groups" />
                {groupOpen ? <ExpandLess /> : <ExpandMore />}
              </CustomListItemButton>
            </ListItem>
            <Collapse in={showGroup} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {this.menuBar.map((data) => (
                  <ListItem key={data.key} disablePadding sx={{pl: 3}}>
                    <CustomListItemButton selected={type === data.key} onClick={(e) => this.navRoute(e, routeCodes[data.type], data.id)}>
                      <ListItemIcon sx={{minWidth: 36}}>
                        <Group {...(type === data.key ? {color:"primary"} : {})} />
                      </ListItemIcon>
                      <ListItemText primary={data.primaryText} {...(type === data.key ? {primaryTypographyProps: {color: "#1976d2"}} : {})} />
                    </CustomListItemButton>
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <Divider />
            {SEC_NAV.map(el =>
              <ListItem key={el.type} disablePadding>
                <CustomListItemButton selected={type === el.type} onClick={(e) => this.navRoute(e, routeCodes[el.type])}>
                  <ListItemIcon sx={{minWidth: 36}}>
                    <el.icon {...(type === el.type ? {color:"primary"} : {})} />
                  </ListItemIcon>
                  <ListItemText primary={el.text} {...(type === el.type ? {primaryTypographyProps: {color: "#1976d2"}} : {})} />
                </CustomListItemButton>
              </ListItem>
            )}
            <Divider />
            <ListItem key="DEVELOPERS_TOOL" disablePadding>
              <CustomListItemButton selected={type === 'DEVELOPERS_TOOL'} onClick={(e) => this.navRoute(e, routeCodes.DEVELOPERS_TOOL)}>
                <ListItemIcon sx={{minWidth: 36}}>
                  <Code {...(type === 'DEVELOPERS_TOOL' ? {color:"primary"} : {})} />
                </ListItemIcon>
                <ListItemText primary="Developers Tool" {...(type === 'DEVELOPERS_TOOL' ? {primaryTypographyProps: {color: "#1976d2"}} : {})} />
              </CustomListItemButton>
            </ListItem>
            <Divider />
          </Box>
        </Drawer>
        : null
    );
  }
}
export default withRouter(MenuDrawer)
