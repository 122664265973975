import React, {useEffect} from 'react'
import {PowerSettingsNew, QuestionMark} from '@mui/icons-material'
import {IconButton, Tooltip} from '@mui/material'
import {connect} from 'react-redux'

import {logoutRequest} from '../../actions/logout'
import {GAEventTracker} from '../../lib/googleAnalytics'
import {getCookie, setCookie} from '../../lib/utils'

const AccountMenu = ({logoutRequest, logoutResponse}) => {
  useEffect(() => {
    if (logoutResponse?.success) {
      setCookie('userToken', '', -1);
      setCookie('userId', '', -1);
      window.location.href = '/login';
    }
  }, [logoutResponse])

  const openGuide = (event) => {
    event.stopPropagation()
    GAEventTracker({category: 'Show Guidelines', action: 'Click'})
    window.open('/guide');
  }

  const logout = (event) => {
    event.stopPropagation()
    GAEventTracker({category: 'Sign Out', action: 'Click'})
    logoutRequest(`bearer ${ getCookie('userToken') }`)
  }

  return (
    <>
      <Tooltip title="Help Guide">
        <IconButton onClick={openGuide} aria-label='Help'>
          <QuestionMark fontSize="medium" sx={{color: 'white'}} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Log out">
        <IconButton onClick={logout} aria-label='Account'>
          <PowerSettingsNew fontSize="medium" sx={{color: 'white'}} />
        </IconButton>
      </Tooltip>
    </>
  );
}

function mapStateToProps(state) {
  return {
    logoutResponse: state.logoutResponse.logoutResponse
  }
}

export default connect(mapStateToProps, {logoutRequest})(AccountMenu)
