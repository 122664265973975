/* eslint-disable max-len */
const companyName = process.env.REACT_APP_COMPANY_NAME
const contactEmail = process.env.REACT_APP_CONTACT_EMAIL
const contactPhonePrimary = process.env.REACT_APP_CONTACT_PHONE_PRIMARY

export const guidelinePoints = [
  {
    ques:'1. Steps to Send SMS!',
    ans:'Sending SMS is super easy and fast with us. Choose the "Send SMS" Tab from the Left side Bar. Now, do the following:<ol><li>Select the contacts whom you need to send SMS.</li><li>Select the signature message from the dropdown(optional).</li><li>Click on "Enter SMS Text" field and type your message. Click on "SEND" Button.</li></ul>'
  },
  {
    ques:'2. How to Select the contacts whom you need to send SMS.',
    ans:'Selecting contacts/numbers is super flexible and easy. We can do it with the help of 3 buttons:<ol><li>"All Contacts&rdquo;: If you need to send SMS to all of your stored contacts/numbers, just click on this button.</li><li>"Add from Contacts/Groups": If you need to send SMS to any particular group(s) or any particular contact(s), just click on this button. Here, you can select contacts from multiple groups in one shot. On clicking it, a dialog box opens in the following way:<ul><li>You will find a "Select Group" dropdown. By default, "All" is selected and you see all contacts under it. If you click &ldquo;Select All&rdquo;, all contacts will be selected.</li><li>You can choose the required group from the dropdown. On choosing a group, you can see the contacts list present in that group. You can select the required contacts by clicking on checkbox(es). To select the whole group, click on "Select All" checkbox.</li><li>Similarly, you can keep selecting contacts from multiple groups by changing groups from dropdown in one shot and prepare your desired list. You can also search a contact by name/number with the help of Search Bar present in right. Contacts present in multiple groups will be selected only once to avoid repetition.</li><li>Once selection of contacts is done, click on OK button.</li></ul></li><li>"Add Extra Numbers": If you need to send SMS to mobile numbers which are not saved in the system, click on this button. On clicking this, a dialog opens, click on "Number" field, enter 10-digit mobile number. If you want to add/remove multiple mobile numbers, click on "+/-" button. Once done, click on "DONE" button.</li></ol>'
  },
  {
    ques:'3. What is signature message and how to select the signature message?', ans:'Signature message comes in the end of the SMS. We are storing signatures so that you do not need to type them every time! If you do not have any signature created, you can create one with the help of "Manage Signature" Button. You can delete/edit your existing signatures as well.<br />To create a signature, click on "Manage Signature" button, a dialog opens. Click on "Create Signature" button, enter your signature message (sample format mentioned below) and click on "SAVE" button. Once its created, you can choose it from the dropdown.<br />Sample Signature:<br />Regards,<br />Principal,<br />Grace Modern School,<br />Sitapur'
  },
  {
    ques:'4. How to Check SMS History/Reports/Delivery Status?', ans:'You can check history and report of all your SMS sent by clicking on "SMS History" Tab from the Left Side Bar. On click, you can find, &lsquo;Date and Time&rsquo;, message, count of SMS used, number of contacts sent and status of delivery for each entry. You can also download SMS Reports from here.'
  },
  {
    ques:'5. Contact Add/Update/Delete/Search!', ans:'You can manage all your stored contacts by clicking on "Contacts" Tab from the Left Side Bar. On Click, once contacts are loaded, you can also find them with the help of "Search" Bar by Name/Number. On click of each contact entry, you can see its Name, Mobile Number and Group/Groups in which it is present (if any).'
  },
  {
    ques:'5(a). How to Add a New Contact', ans:'Click on the Red color "+" icon at bottom right in "Contacts" Tab. On clicking it, a dialog opens, enter the name and the 10-digit mobile number for the contact. These are mandatory fields. You can add the contact in one or more existing groups from "Select Group" dropdown (it allows you to select more than one group). Once you select the group/groups, click anywhere outside to remove dropdown and click on "SAVE" button. Email and Address fields are optional.<br /> NOTE: You can add a single contact in multiple groups. (For Example - In case of school, a student can be added in a group named as "Class-5A" and can also be added in a group named as "Fees Defaulters"). '
  },
  {
    ques:'5(b). How to Delete a Contact', ans:'You can delete a contact by clicking on delete icon in right for each contact entry in "Contacts" Tab and confirm delete. Once you delete a contact from "Contacts" Tab, it gets permanently deleted. '
  },
  {
    ques:'5(c). How to Edit a Contact Detail', 
    ans:'You can edit a contact info by edit icon in right for each contact entry in "Contacts" Tab. On click of edit icon, a dialog opens, where you can edit its name, number, add the contact in one or more existing group(s) from "Select Group" dropdown. Once you select the group/groups, click anywhere outside to remove dropdown and click on "UPDATE" Button. Again, Email and Address fields are optional.'},
  {
    ques:'6. Groups Create/Delete/Add Contacts into Group/Remove Contacts!', ans:'You can organize your contacts by storing them into groups as per your needs. You can add an existing contact in one or more than one groups. To create, view or manage groups, click on "Groups" Tab in Left Side Bar. On click of it, you can see "+ Create Group" Button and list of already existing groups. On clicking any of the group, you can find the existing contacts present in the group with a Search Bar. '
  },
  {
    ques:'6(a). reate New Group', ans:' To create a new group, click on groups tab -&gt; click on "+ Create Group" Button, enter Group Name, add contacts you want to include into your group(optional as you can add contacts later too) and click on "Add Group" Button in the right.'
  },
  {
    ques:'6(b). Group Management:', ans:' By clicking on "Groups" Tab, once you see the list of existing groups, you can click on any particular group to check its details. On clicking over any group, you will find 3 Buttons as:.<ul><li>"Add Contacts into Group": It is used to add existing contacts into the group. On clicking, a dialog opens, you can select one or more contacts and click on OK. You can also search a contact by Name/Number with the help of Search Bar present.</li><li>"Delete Group": By clicking on this button, you can delete the group after confirmation. The group will be permanently deleted.</li><li>"Rename Group": By clicking on this button, you can change the name of the group.</li></ul>'
  },
  {
    ques:'6(c). How to Remove a Contact from a Group:', ans:' You can remove a contact from a group by clicking on "delete" icon in the right for each contact in that group tab.<br />NOTE: Removing a contact from a group does not delete the contact permanently. It will just remove it from that group. If you want to permanently delete it, go to "Contacts" tab and delete it.'
  },
  {
	 	ques:'7. SMS Rules/Norms!', ans: `For English Language, 160 characters will be counted as 1 SMS. Further, on adding each 153 characters, it will be counted as an addition to SMS Count. For other regional languages, 70 characters will be treated as one SMS. Further, each 67 characters will be treated as one SMS Count. Maximum one sms characters length can be 918. If any DND number complaints of receiving messages, please make sure to either inform us or remove his number from your list. ${companyName} is not responsible for any legal penalty/actions incurred for the complaints.`},
  {
    ques:'8.Contact Us!', ans:`Feel free to raise any query or ask for any issues/concerns at:<br>Email:    ${contactEmail}<br>Mobile: ${contactPhonePrimary}<br>`
  }
]
