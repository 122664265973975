import { Map } from 'immutable';

import { GET_TEMPLATE, DELETE_TEMPLATE, POST_TEMPLATE, EDIT_TEMPLATE } from '../lib/constant';

const initialState = Map({
  templateList: {},
  deleteTemplate: {},
  postTemplate: {},
  editTemplate: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TEMPLATE :
      return { ...state, templateList: action.payload };
    case DELETE_TEMPLATE :
      return { ...state, deleteTemplate: action.payload };
    case POST_TEMPLATE :
      return { ...state, postTemplate: action.payload };
    case EDIT_TEMPLATE :
      return { ...state, editTemplate: action.payload };
    default :
      return state;
  }
}
