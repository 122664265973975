import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {throttle} from 'lodash';
import ReactDOM from 'react-dom';

import ContactGroupChip from './contactGroupChip';

export default class ContactGroupChipList extends Component {
  constructor(){
    super();
    this.state={
      page: 1
    }
    this.scrollFunc = throttle(this.scrollFunction.bind(this),500);
  }
  componentDidMount(){
    window.addEventListener('scroll', this.scrollFunc, true)
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.scrollFunc, true)
  }
  scrollFunction = () =>{
    let el = ReactDOM.findDOMNode(this.refs.selectedContactGroupRef);
    if(el.scrollTop + el.offsetHeight + 50 >= el.scrollHeight){
      this.setState({'page': this.state.page + 1});
    }
  }
  render(){
    let chipAmt = this.state.page*100;
    return(
      <Dialog
        open
        onClose={this.props.handleSelectedDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Selected Contacts
        </DialogTitle>
        <DialogContent>
          <div ref="selectedContactGroupRef">
            <ContactGroupChip
              renderLess={false}
              chipAmt={chipAmt}
              selectedContacts={this.props.selectedContacts}
              selectedContactsCallback={this.props.selectedContactsCallback} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleSelectedDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
      
    )
  }
}
