import Api from '../api';
import { GET_OWNED_BLOCKED_CONTACT,OWN_BLOCKED_CONTACT_URL, DELETE_BLOCK_CONTACT} from '../lib/constant';
import {routeToLogin, getCookie } from '../lib/utils';


export function getOwnedBlockedContact() {
  const request = Api.get(OWN_BLOCKED_CONTACT_URL, 'bearer ' + getCookie('userToken'));
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: GET_OWNED_BLOCKED_CONTACT, payload: data })
    }).catch((data) => {
      if (data.response.status == 404 && data.response.data.success == false) {
        routeToLogin();
      }
    })
  };
}

export function getAllBlockedContact() {
  const request = Api.get(OWN_BLOCKED_CONTACT_URL, 'bearer ' + getCookie('userToken'));
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: GET_OWNED_BLOCKED_CONTACT, payload: data })
    }).catch((data) => {
      if (data.response.status == 404 && data.response.data.success == false) {
        routeToLogin();
      }
    })
  };
}

export function addBlockNumber(params) {
  const request = Api.post(OWN_BLOCKED_CONTACT_URL, params, 'bearer ' + getCookie('userToken'));
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: BLOCK_CONTACT, payload: data })
    }).catch((data) => {
      if (data.response.status == 404 && data.response.data.success == false) {
        routeToLogin();
      }
    })
  };
}

export function deleteBlockNumber(params) {
  const request = Api.deleteWithData(OWN_BLOCKED_CONTACT_URL, params, 'bearer ' + getCookie('userToken'));
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: DELETE_BLOCK_CONTACT, payload: data })
    }).catch((data) => {
      if (data.response.status == 404 && data.response.data.success == false) {
        routeToLogin();
      }
    })
  };
}
