import React, {Component} from 'react';
import {CircularProgress} from '@mui/material';
import {connect} from 'react-redux';

import {getAllCreditList} from '../../actions/creditHistory';
import Table from '../../components/Table';
import {convertToLocale, dateFormat} from '../../lib/utils';

class CreditHistory extends Component {
  constructor(props){
    super(props);
    this.state = {
      creditList : [],
      showWait : false
    }
    this.orgCreditList = [];
    this.receivedHistory = null;
  }
  componentDidMount(){
    this.receivedHistory = false;
    this.props.getAllCreditList({});
  }
  componentWillReceiveProps(nextProps){
    let {creditHistoryList} = nextProps;
    if(this.receivedHistory === false && this.state.creditList.length == 0 && creditHistoryList){
      if(creditHistoryList.success){
        let creditList = creditHistoryList.data.list;
        this.receivedHistory = true;
        this.orgCreditList = creditList
        this.setState({creditList});
      } else if(creditHistoryList.err){
        alert(creditHistoryList.err.msg);
        this.receivedHistory = true;
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }
    }
  }
  render() {
    let {creditList} = this.state;
    const isMobile = window.isMobile();
    return (
      <div className='sms-parent-container' style={isMobile ? {padding: 8} : {}}>
        {this.orgCreditList.length ? <div>
          {creditList.length ?
            <Table
              column={[
                {name: 'Date & Time', value: 'updatedAt', cell : (val) => dateFormat(val)},
                {name: 'Operation', value: 'creditOperation'},
                {name: 'SMS Type', value: 'smsType'},
                {name: 'Count', value: 'smsCreditCount', cell: val => convertToLocale(Number(val))},
                {name: 'Previous Balance', value: 'previousBalance', cell: (val, row) => convertToLocale(Number(val[row.smsType]))},
                {name: 'Updated Balance', value: 'updatedBalance', cell: (val, row) => convertToLocale(Number(val[row.smsType]))},
                {name: 'Remarks', value: 'notes'}
              ]}
              data={creditList}
              showPagination
              serialNumber
              defaultPageSize={100}
            /> : <p style={{fontSize: 16}}>No Credit History found!</p>
          }
        </div> :  <div>
          {this.receivedHistory ?
            <p style={{padding: 50, fontSize: 16, color: '#333'}}>No Credit History found!</p>
            :
            <CircularProgress size={80} thickness={5} />
          }
        </div>}
      </div>
    )
  }
}
function mapStateToProps(state){
  return {
    creditHistoryList: state.creditHistory.getCreditHistoryResponse
  };
}
export default connect(mapStateToProps, {getAllCreditList})(CreditHistory);
