import React from 'react';
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';

import {sortGroup} from '../../lib/utils';
import Select from '../Select';

const GroupContactUpload = ({contactData, groupList, selectValue, handleChange}) => {
  
  if(groupList && groupList.success){
    groupList = groupList.data.groups.length == undefined ? Object.values(groupList.data.groups) : groupList.data.groups;
  } else {
    groupList = [];
  }
  return(
    <div className="uploadExcelPreview">
      {contactData && contactData.length > 0 &&
          <div>
            <p style={{marginBottom: 10, color: '#1975d2'}}>Total Valid Contacts found : {contactData.length}</p>
            <p>Preview</p>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{padding: 5, height: 10}}>Name</TableCell>
                  <TableCell style={{padding: 5, height: 10}}>Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  contactData.slice(0, 3).map((row, index) => (
                    <TableRow key={'contactNameNumber'+index}  style={{height: 35}}>
                      <TableCell style={{padding: 5, height: 10}}>{row.name}</TableCell>
                      <TableCell style={{padding: 5, height: 10}}>{row.mobile}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </div>
      }
      <div>
        <p style={{margin: '16px 0', fontSize: 12, color: 'rgba(0, 0, 0, 0.6)'}}>
            Optional : You can add uploaded contacts to group as well, 
          {groupList.length ? ' Select Group to add contacts into group.' : ' Click on Create Group Button to create Group.'}
        </p>
        {
          groupList.length ?
            <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
              <Select
                value={selectValue}
                onChange={handleChange}
                label="Select Group"
                sx={{minWidth: 150}}
                options={[{value: 0, name: 'Select Group'}, ...sortGroup(groupList).map(el => ({value: el.id, name: el.name}))]}
              />
              <p>OR</p>
              <Button onClick={() => {window.location.href = "/create-group"}} variant='outlined'>
                  Create Group
              </Button>
            </div>
            : null
        }
      </div>
    </div>
  )
}

export default GroupContactUpload
