import React, {Component} from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import ReactFileReader from 'react-file-reader';
import * as XLSX from 'xlsx';

class UploadPanel extends Component {
  constructor() {
    super();
    this.state = {
      showWait: false,
      contactData: null,
      excelData: []
    };
  }
  numberCheck = contact => {
    const numRegex = new RegExp('^[3-9][0-9]{9}$');
    return numRegex.test(Number(contact));
  }
  handleUploadFiles = files => {
    const reader = new FileReader();
    this.setState({
      showWait: true,
      excelData: [],
      contactData: null
    });
    reader.onload = () => {
      const bstr = reader.result;
      const wb = XLSX.read(bstr, {type: 'binary'});
      const ws = wb.Sheets[wb.SheetNames[0]];
      const excelData = XLSX.utils.sheet_to_json(ws);
      const mobCol = Object.keys(excelData[0])[0];
      const contactData = [];
      excelData.forEach(data => {
        if (data[mobCol]) {
          const num = data[mobCol].toString().trim();
          if (this.numberCheck(num) && !contactData.includes(num)) {
            contactData.push(num);
          }
        }
      });
      this.setState({excelData, showWait: false, contactData});
    };
    reader.readAsBinaryString(files[0]);
  }


  handleApply = () => {
    this.props.hideDialog()
    this.props.applyUpload(this.state.contactData)
    // this.props.handleBulkUpload();
  }

  render() {
    const {showWait, contactData} = this.state;
    return (
      <>
        <Dialog open onClose={ this.props.hideDialog }>
          <DialogTitle>
            Add/Import Bulk Block List
          </DialogTitle>
          <DialogContent dividers>
            <div>
              <div>
                <div>
                  <ReactFileReader handleFiles={ this.handleUploadFiles } fileTypes={ '.csv,.xlsx,.xls' } elementId='fileUpload'>
                    <Button variant='outlined'>Upload Excel (.xlsx)</Button>
                  </ReactFileReader>
                </div>
                {contactData !== null &&
                  <p style={{marginTop: 10, fontSize: 16}}>
                    {contactData.length === 0 ? 'No Valid numbers are fetched' : `Total valid numbers from excel sheet to upload - ${contactData.length}`}
                  </p>
                }
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.hideDialog}>
              Close
            </Button>
            <Button onClick={this.handleApply} variant='contained'>
              Upload
            </Button>
          </DialogActions>
        </Dialog>
        {
          showWait ?
            <Dialog open
              contentStyle={ {width: 100} }
            >
              <DialogContent sx={{width: 100}}>
                <CircularProgress />
              </DialogContent>
            </Dialog>
            :
            null
        }
      </>
    );
  }
}


export default UploadPanel;
