import React, {Component} from 'react';
import {HighlightOff, Search} from '@mui/icons-material';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField} from '@mui/material';
import {throttle} from 'lodash';

import Table from '../../components/Table';

export default class RecipientList extends Component {
  constructor(props){
    super(props);
    this.state={
      autoCompleteValue : '',
      individualList : props.individualList,
      page : 1
    }
    this.autoComplete = this.autoComplete.bind(this);
    this.throttleInput = throttle(this.throttleInput.bind(this),300);
    this.userList = props.userList;
    this.newUserList = this.userList;
  }
  autoComplete = (e) => {
    this.setState({autoCompleteValue:e.target.value});
    this.throttleInput(e.target.value);
  }
  throttleInput = (val) => {
    this.newUserList = this.userList.filter(data => {return (data.name.toLowerCase().indexOf(val.toLowerCase()) > -1 || data.mobile.indexOf(val) > -1);});
    this.setState(prev => ({...prev}));
  }
  removeAutoCompleteValue = (e) => {
    e.stopPropagation()
    this.setState({autoCompleteValue : '',page : 1});
  }
  handleCheckBox = (id, details) => {
    let {individualList} = this.state;
    if(individualList[id]){
      delete individualList[id];
    } else{
      individualList[id] = details;
    }
    this.setState({individualList});
  }
  handleAllCheckbox = () => {
    let {individualList} = this.state;
    if(this.userList.length === Object.keys(individualList).length){
      individualList = {}
    } else{
      for(let gc of this.userList){
        if(!individualList[gc.id]){
          individualList[gc.id] = gc;
        }
      }
    }
    this.setState({individualList});
  }
  render(){
    let {individualList,autoCompleteValue} = this.state;
    
    let nameList = this.state.autoCompleteValue == '' ? this.userList : this.newUserList;
    const isMobile = window.isMobile();
    return(
      <Dialog open onClose={this.props.handleRecipientListDialogCloseWithNoAction}>
        <DialogTitle>All Contacts List</DialogTitle>
        <DialogContent dividers style={{paddingTop: 16}}>
          {
            this.userList.length ?
              <div>
                <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                  <Search onClick={e => e.stopPropagation()} />
                  <TextField
                    label={isMobile ? "Search By Name" : "Search Contact By Name/Number"}
                    fullWidth={true}
                    value={autoCompleteValue}
                    onChange={this.autoComplete.bind(this)}
                    size="small"
                    variant="standard"
                    sx={{width: isMobile ? 300 : 600}}
                  />
                  {
                    autoCompleteValue ?
                      <IconButton aria-label='Clear' onClick={this.removeAutoCompleteValue}>
                        <HighlightOff />
                      </IconButton>
                      : null
                  }
                </div>
                {
                  nameList.length ?
                    <Table
                      column={[
                        {name: 'Name', value: 'name'},
                        {name: 'Mobile', value: 'mobile'}
                      ]}
                      data={nameList}
                      enableCheckbox
                      handleSelect={this.handleCheckBox}
                      selected={Object.keys(individualList)}
                      onSelectAllClick={this.handleAllCheckbox}
                      showPagination
                      pageOptions={[10]}
                    />
                    :
                    <p style={{paddingTop:80,fontSize:16}}>No Contact Available with searched keyword!</p>
                }
              </div>
              : <p>No Contacts Available!</p>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleRecipientListDialogCloseWithNoAction}>
            Cancel
          </Button>
          <Button onClick={() => this.props.handleRecipientListDialogClose(individualList)} variant='contained'>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
