import React from 'react'
import {AccountBalanceWallet} from '@mui/icons-material'
import {connect} from 'react-redux'

import {convertToLocale} from '../../lib/utils'

const SMSBalanceHeader = ({smsDetails}) => {
  if (smsDetails?.data?.smsCount) {
    const {smsCount, smsType} = smsDetails.data
    return (
      <div style={{display: 'flex', alignItems: 'center', background: '#2196f3', padding: '4px 8px', borderRadius: 24}}>
        <AccountBalanceWallet onClick={e => e.stopPropagation()} />
        {smsType.map((data) => (
          <div
            key={data}
            style={{fontSize: 14, marginLeft: 8}}
          >
            {data}: {convertToLocale(smsCount[data])}
          </div>
        ))}
      </div>
    )
  }
  return null
}

function mapStateToProps(state) {
  return {
    smsDetails: state.smsResponse.smsDetailsResponse
  }
}

export default connect(mapStateToProps)(SMSBalanceHeader)
