import React from 'react';
import {Button, Chip} from '@mui/material';

const ContactGroupChip = ({selectedContactsCallback, selectedContacts, renderLess,handleShowMoreChip,selectAllShow,chipAmt}) => {
  const handleRequestDeleteContact = (id) => {
    if(id == 'all'){
      selectedContactsCallback({});
    } else{
      let selectedContactsNew = selectedContacts;
      let selectedContactsListRef = Object.keys(selectedContactsNew);
      const contactToDelete = selectedContactsListRef.map((data) => selectedContactsNew[data].id).indexOf(id);
      delete selectedContactsNew[selectedContactsListRef[contactToDelete]];
      selectedContactsCallback(selectedContactsNew);
    }
  };
  const renderChip = (name,id) => {
    return (
      <Chip
        key={'contact'+id}
        label={name}
        variant="outlined"
        onDelete={e => handleRequestDeleteContact(id)}
      />
    );
  }
  let showLess = false;
  const isMobile = window.isMobile();
  let selectedContactsListRef = Object.keys(selectedContacts);
  if(chipAmt){
    selectedContactsListRef = selectedContactsListRef.slice(0,chipAmt);
  }
  const renderLessVal = isMobile ? 1 : 6;
  if(renderLess && selectedContactsListRef.length > renderLessVal){
    showLess = true;
    selectedContactsListRef = selectedContactsListRef.splice(0,renderLessVal);
  }
  return(
    <>
      {
        selectAllShow ?
          <Chip
            label="All"
            variant="outlined"
            onDelete={e => handleRequestDeleteContact('all')}
          />
          :
          <div style={{display:'flex',flexWrap:'wrap', gap: 8}}>
            {selectedContactsListRef.map((data)=>{return renderChip(selectedContacts[data].name,data)})}
            {
              showLess ?
                <Button onClick={handleShowMoreChip}>Show More...</Button>
                : null
            }
          </div>
      }
    </>
  )
}

export default ContactGroupChip
