import React from "react"
import WarningIcon from '@mui/icons-material/Warning';

const ErrorMsg = ({msg}) => {
  return (
    <div className="dashboard-error-msg">
      <WarningIcon onClick={e => e.stopPropagation()} />
      <p>{msg  || 'Something went wrong'}</p>
    </div>
  )
}

export default ErrorMsg
