import Api from '../api';
import {
  GET_ALL_SMS_HISTORY,
  GET_ALL_SMS_HISTORY_URL,
  GET_SMS_HISTORY, GET_SMS_HISTORY_URL,
  GET_TRACK_CAMPAIGN, GET_TRACK_CAMPAIGN_DELIVERY_URL,
  SMS_HISTORY_DWNL_FILTER_REPORT_JOB_ID,
  SMS_HISTORY_DWNL_FULL_REPORT_URL,
  SMS_HISTORY_DWNL_REPORT_JOB_STATUS_URL,
  SMS_HISTORY_DWNL_REPORT_URL,
  SMS_HISTORY_SMART_STATUS,
  SMS_HISTORY_SMART_STATUS_URL,
  SMS_HISTORY_STATUS,
  SMS_HISTORY_STATUS_URL
} from '../lib/constant';
import {Polling} from '../lib/poll';
import {getCookie, routeToLogin} from '../lib/utils';

export function getSMSHistory(params){
  const request = Api.post(GET_SMS_HISTORY_URL, params, 'bearer '+getCookie('userToken'));
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({type:GET_SMS_HISTORY,payload:data})
    }).catch((data) => {
      if(data.response.status == 404 && data.response.data.success == false){
        routeToLogin();
      }
    })
  };
}

export function getAllSMSHistory(params){
  const request = Api.post(GET_ALL_SMS_HISTORY_URL, params, 'bearer '+getCookie('userToken'));
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({type:GET_ALL_SMS_HISTORY,payload:data})
    }).catch((data) => {
      if(data.response.status == 404 && data.response.data.success == false){
        routeToLogin();
      }
    })
  };
}

export function getStatusSMSHistory(id){
  let url = SMS_HISTORY_STATUS_URL.replace('{campaign_id}',id)
  const request = Api.get(url,'bearer '+getCookie('userToken'));
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({type:SMS_HISTORY_STATUS,payload:data})
    }).catch((data) => {
      if(data.response.status == 404 && data.response.data.success == false){
        routeToLogin();
      }
    })
  };
}

export function getSmartStatusSMSHistory(id){
  const request = Api.get(SMS_HISTORY_SMART_STATUS_URL.replace('tId',id),'bearer '+getCookie('userToken'));
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({type:SMS_HISTORY_SMART_STATUS,payload:data})
    }).catch((data) => {
      if(data.response.status == 404 && data.response.data.success == false){
        routeToLogin();
      }
    })
  };
}

export function getCampaignText(id, params){
  let url = GET_ALL_SMS_HISTORY_URL+`/${id}/text`;
  const request = Api.get(url, 'bearer ' + getCookie('userToken'), params);
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({type:GET_TRACK_CAMPAIGN,payload:data})
    }).catch((data) => {
      if(data.response.status == 404 && data.response.data.success == false){
        routeToLogin();
      }
    })
  };
}
export function getTrackCampaignDelivery(id, params){
  let url = GET_TRACK_CAMPAIGN_DELIVERY_URL.replace("{campaign_id}",id);
  const request = Api.get(url, 'bearer ' + getCookie('userToken'), params);
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({type:GET_TRACK_CAMPAIGN,payload:data})
    }).catch((data) => {
      if(data.response.status == 404 && data.response.data.success == false){
        routeToLogin();
      }
    })
  };
}

const responseHandler = (resp) => resp?.data?.status === "completed" || resp?.data?.status === "failed"

export async function getSMSReportSync(id, isSmartURL = 0){
  const request = Api.post(SMS_HISTORY_DWNL_REPORT_URL.replace('{campaign_id}',id),{},'bearer '+getCookie('userToken'),{isSmartURL});
  //start  sync file fetch as payload send
  try {
    const {data} = await request;
    return data;
  } catch (data) {
    if (data.response.status == 404 && data.response.data.success == false) {
      routeToLogin();
    }
  }
}

export function getSMSReportExportJobID(params){
  const request = Api.post(SMS_HISTORY_DWNL_FULL_REPORT_URL,params,'bearer '+getCookie('userToken'));
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({type: SMS_HISTORY_DWNL_FILTER_REPORT_JOB_ID, payload: data})
    }).catch((data) => {
      if(data.response.status == 404 && data.response.data.success == false){
        routeToLogin();
      }
    })
  }
}

export function getSMSReportPolling(jobId){
  let jobStatusUrl = SMS_HISTORY_DWNL_REPORT_JOB_STATUS_URL.replace('{job_id}',jobId)
  
  const fetchParams = {
    "method": 'GET',
    "headers": {
      "authorization": "bearer "+ getCookie('userToken')
    }
  }
  const poll = new Polling(jobStatusUrl, fetchParams, responseHandler)
  return poll
}
