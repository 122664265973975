import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';

import Select from '../../components/Select';

export default class AddBalance extends Component {
  constructor(props){
    super(props);
    this.state={
      val : 2,
      smsVal : '',
      balanceType : 'add',
      remarks: ''
    }
  }
  handleChange = e => this.setState({val: e.target.value});
  handleChangeBalanceType = (e) => this.setState({balanceType: e.target.value});
  handleAddSMS = (smsVal) => this.setState({smsVal});
  addBalance = () =>{
    const {val,smsVal,balanceType,remarks} = this.state;
    if(val == 2){
      alert("Please select Account Type");
      return;
    }
    if(smsVal == 0 || isNaN(Number(smsVal))){
      alert("Please enter sms balance");
      return;
    }
    this.props.addBalance({
      smsCount : parseInt(smsVal),
      smsType : val,
      operation : balanceType,
      notes: remarks
    });
  }
  render(){
    const {val,smsVal,balanceType,remarks} = this.state;
    let {smsTypeTaken} = this.props.data;
    const isMobile = window.isMobile();
    return(
      <Dialog open={true} onClose={this.props.closeAddBalance}>
        <DialogTitle>Modify Balance</DialogTitle>
        <DialogContent style={{paddingTop: 16}}>
          <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
            <Select
              label="Select Account Type"
              value={val}
              fullWidth={isMobile}
              onChange={this.handleChange}
              size="small"
              required
              options={[{value: 2, name:'Select Account Type'}, ...smsTypeTaken]}
            />
            <Select
              label="Modify Balance"
              value={balanceType}
              fullWidth={isMobile}
              onChange={this.handleChangeBalanceType}
              size="small"
              required
              options={[{value: "add", name:'Add'},{value: "subtract",name: "Subtract"}, {value: 'set', name: 'Set'}]}
            />
            <TextField
              placeholder='Enter Balance'
              label="Balance"
              value={smsVal}
              fullWidth
              required
              type="number"
              variant="standard"
              onChange={(e)=>{this.handleAddSMS(e.target.value)}}
              size="small"
            />
            <TextField
              placeholder='Enter Remarks'
              label="Remarks"
              value={remarks}
              fullWidth
              variant="standard"
              onChange={(e)=> this.setState({remarks: e.target.value})}
              size="small"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeAddBalance}>Cancel</Button>
          <Button onClick={this.addBalance} variant='contained'>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
