import React from 'react'
import {Card, CardContent} from '@mui/material';

import Heading from './Heading';

const OfferUpdates = () => {
  return (
    <Card>
      <CardContent>
        <Heading>Offers and Updates</Heading>
        <ul className='dashboard-offers'>
          <li>
              Refer our Service to your Friends/Relatives and get <span style={{fontWeight:700}}>2000* SMS FREE</span> on their first payment. Contact us for more details.
          </li>
          <li>
              Keep checking this section for Latest Updates From Us!
          </li>
        </ul>
      </CardContent>
    </Card>
  )

}

export default OfferUpdates