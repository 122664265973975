import React, {Component} from 'react';
import {DataUsage} from '@mui/icons-material';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField} from '@mui/material';
import ReactFileReader from 'react-file-reader';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import * as XLSX from 'xlsx';

import {getSMSDetails, sendCustomizeSms, updateSMSCount} from '../../actions/sms';
import {getTemplate} from '../../actions/template';
import Select from '../../components/Select';
import Table from '../../components/Table';
import TemplateList from '../../components/TemplateList';
import {GAEventTracker} from '../../lib/googleAnalytics';
import {isNotValidPromoTime} from '../../lib/utils';
import Heading from '../NewDashboard/Heading';
import SMSBalanceMobile from '../Sms/smsBalanceM';
import SmsFlashOptionButton from "../Sms/smsFlashOptionButton";

const labelMap = {TRANS: 'Transactional', PROMO: 'Promotional', OTP: 'OTP'};

class Sms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlashEnabled: false,
      openSnackBar: false,
      smsText: '',
      snackBarMsg: '',
      btnDisabled: false,
      readFile: false,
      smsDetails: props.smsDetails && props.smsDetails.success ? props.smsDetails.data : {},
      senderId: null,
      smsType: null,
      excelData: null,
      cols: [],
      mobCol: "Choose",
      msgCol: "Choose",
      cstmMsg: [],
      showPreviewMsg: false,
      selectedTemplate: '',
      dynamicFieldInMsg: 0,
      dynamicMap: {}
    };
    this.smsSent = false;
    this.receivedSMSDetails = false;
    this.smsLengthRange = {
      normal: {
        single: 160,
        multi: 153
      },
      unicode: {
        single: 70,
        multi: 67
      }
    };
    this.smsLength = 0;
    this.senderIds = null;
    this.selectedTemplateId = null;
    this.isSmsFlashEnabledFn = this.isSmsFlashEnabledFn.bind(this);
  }
  componentDidMount() {
    window._epadhaiGTM.push({event: 'customizeSmsSection'});
    this.props.getSMSDetails();
    this.props.getTemplate();
  }
  componentWillReceiveProps(nextProps) {
    const {
      sendCustomSmsResponse, smsDetails
    } = nextProps;
    if (!this.receivedSMSDetails && smsDetails) {
      this.receivedSMSDetails = true;
      if (smsDetails.success) {
        const smsDet = smsDetails.data;
        this.senderIds = smsDet.senderIds;
        this.setState({
          smsDetails: smsDet, senderId: smsDet.defaultSenderId, smsType: smsDet.defaultSmsType
        });
      } else if (smsDetails.err) {
        alert(smsDetails.err.msg);
        window.location.reload();
      } else {
        alert('Something went wrong!');
        window.location.reload();
      }
    }
    if (this.smsSent && sendCustomSmsResponse) {
      this.smsSent = false;
      let snackBarMsg = 'SMS Submitted Successfully! View SMS Reports for More Details';
      if (sendCustomSmsResponse.err) {
        snackBarMsg = sendCustomSmsResponse.err.msg;
        GAEventTracker({category : 'Customize-SMS', action : 'Send SMS Error', label : snackBarMsg});
        alert(snackBarMsg);
        this.setState({btnDisabled: false, snackBarMsg, openSnackBar: true, showPreviewMsg: false});
      } else if (sendCustomSmsResponse.success) {
        GAEventTracker({category : 'Customize-SMS', action : 'Send SMS', value : this.processCustomMsg.length});
        const {smsDetails} = this.state;
        snackBarMsg = sendCustomSmsResponse.data.msg;
        smsDetails.smsCount = sendCustomSmsResponse.data.smsCount;
        this.props.updateSMSCount(sendCustomSmsResponse.data.smsCount);
        alert(snackBarMsg);
        this.setState({
          btnDisabled: false,snackBarMsg, openSnackBar: true, smsText: '', smsDetails, showPreviewMsg: false, isFlashEnabled: false, selectedTemplate: '',
          dynamicFieldInMsg: 0, dynamicMap: {}
        });
      } else {
        snackBarMsg = 'Something went wrong, Please try again!';
        GAEventTracker({category : 'Customize-SMS', action : 'Send SMS Error', label : snackBarMsg});
        alert(snackBarMsg);
        this.setState({btnDisabled: false, snackBarMsg, openSnackBar: true, showPreviewMsg: false});
      }
    }
  }
  isSmsFlashEnabledFn(isFlashEnabled){
    this.setState({isFlashEnabled})

  }
  handleSnackBarClose = () => this.setState({openSnackBar: false});
  sendParams = () => {
    const {smsType, senderId, isFlashEnabled, cstmMsg, smsText} = this.state;
    if (isFlashEnabled) {
      GAEventTracker({category : 'Flash-SMS', action : 'Send', label: 'Customize-SMS'});
    }
    const customMessages = {};
    cstmMsg.forEach(msg => {
      customMessages[msg.mobile] = msg.text;
    });
    const params = {
      senderId,
      smsType,
      customMessages,
      isFlashEnabled,
      templateId: this.selectedTemplateId
    };
    return params;
  }
  previewSMS = () => {
    const {smsText, excelData, mobCol, dynamicMap, dynamicFieldInMsg} = this.state;
    if (Object.keys(dynamicMap).length !== dynamicFieldInMsg) {
      alert('Please select columns for all dynamic field!');
      return;
    }
    let totalSMSCount = 0
    const keys = Object.keys(dynamicMap);
    const numRegex = [new RegExp('^[3-9][0-9]{9}$'), new RegExp('^91[3-9][0-9]{9}$'), new RegExp('^/+91[3-9][0-9]{9}$'), new RegExp('^/+91-[3-9][0-9]{9}$')]
    const filteredData = []
    excelData.forEach(el => {
      const num = el[mobCol]
      let i=0;
      for (; i<numRegex.length; i++) {
        if (numRegex[i].test(num)) {
          break
        }
      }
      if (i < numRegex.length) {
        let newNum = Number(num)
        if (i > 0) {
          newNum = Number(num.toString().slice(i+1))
        }
        filteredData.push({...el, [mobCol]: newNum})
      }
    })
    let cstmMsg = filteredData.map(data => {
      let text = smsText
      keys.forEach(key => {
        text = this.replaceNth(text, '{#var#}', data[dynamicMap[key]], 1);
      })
      totalSMSCount += this.calculateSmsText(text)
      return {text, mobile: data[mobCol]}
    })
    this.processCustomMsg = cstmMsg
    this.totalSMSCount = totalSMSCount
    this.setState({showPreviewMsg: true, cstmMsg})
  }
  handleSend = () => {
    const {smsType, smsDetails} = this.state;
    if (smsType == 'PROMO' && isNotValidPromoTime()) {
      alert('Promotional SMS can be sent only between 10:00 AM to 9:00 PM');
      return;
    }
    if (smsDetails.smsCount[smsType] - smsDetails.scheduledSmsCount[smsType] < this.totalSMSCount) {
      alert("Sorry, You can't send SMS as you have already Scheduled SMS which will not be sent due to lack of sms availablity!");
      return;
    }
    if (this.processCustomMsg.length > 50000) {
      alert('Sorry, We can send only 50,000 sms at a time. Please try sending less than 1,00,000 sms only.');
      return;
    }
    const params = this.sendParams();
    if (params) {
      this.smsSent = true;
      this.setState({btnDisabled: true});
      GAEventTracker({category: 'Customize-SMS', action: 'Send SMS Initiated', value: this.processCustomMsg.length});
      this.props.sendCustomizeSms(params);
    }
  };
  handleSmsTextChange = smsText => this.setState({smsText})

  calculateSmsText = (val) => {
    let smsLength = 0,
      text = val,
      isUnicode = false
    for (let charPos = 0; charPos < text.length; charPos++) {
      switch (text[charPos]) {
      case '[':
      case ']':
      case '\\':
      case '^':
      case '{':
      case '}':
      case '|':
      case '€':
        smsLength += 2;
        break;
      default:
        smsLength += 1;
      }
      if ((!isUnicode && text.charCodeAt(charPos) > 127 && text[charPos] !== '€') || text[charPos] === '\t') {
        isUnicode = true;
      }
    }
    const lenCal = isUnicode ? this.smsLengthRange.unicode : this.smsLengthRange.normal;
    const len = smsLength;
    return len <= lenCal.single ? 1 : (parseInt(len / lenCal.multi) + (len % lenCal.multi == 0 ? 0 : 1));
  }
  handleFiles = files => {
    const reader = new FileReader();
    const _this = this;
    this.setState({readFile: true});
    reader.onload = function (e) {
      const bstr = reader.result;
      const wb = XLSX.read(bstr, {type: 'binary'});
      const ws = wb.Sheets[wb.SheetNames[0]];
      let excelData = XLSX.utils.sheet_to_json(ws)
      _this.setState({excelData, readFile: false, cols: Object.keys(excelData[0]), dynamicMap: {}, mobCol: 'Choose'});
      GAEventTracker({category: 'Customize-SMS', action: 'Excel Uploaded', value: excelData.length});
    };
    reader.readAsBinaryString(files[0]);
  }
  countContacts = () => {
    return 1
  }
  handleToggle = (e) => this.setState({smsType: e.target.value, isFlashEnabled: false});
  openUploadExcel = () => {
    GAEventTracker({category: 'Customize-SMS', action: 'Upload Excel'});
  }
  handleSenderIdChange = (e) => this.setState({senderId: e.target.value, smsText: '', selectedTemplate: '', dynamicFieldInMsg: 0, dynamicMap: {}});
  handleColChange = (type) => (e) => this.setState({[type]: e.target.value})
  getNumberMessageCol = () => {
    const {excelData, mobCol, cols} = this.state;
    if (!excelData || excelData.length === 0) {
      return null;
    }
    return (
      <div style={{marginTop: 20}}>
        <Select
          id="mobCol"
          value={mobCol}
          onChange={this.handleColChange('mobCol')}
          sx={{width: 200}}
          options={cols}
          label="Mobile Number Column"
          required
        />
      </div>
    )
  }
  customMsgDropdown = (index) => {
    const {cols} = this.state;
    const colItems = []
    if (cols.length) {
      colItems.push({value: 0, name: 'Choose'})
      for(let col of cols){
        colItems.push({value: col, name: <div dangerouslySetInnerHTML={{__html: col}}></div>})
      }
    }
    return colItems;
  }
  replaceNth = (s, f, r, n) => {
    // From the given string s, replace f with r of nth occurrence
    //let replaced= s.replace(RegExp("^(?:.[\\s\\S]*?" + f + "){" + n + "}"), x => x.replace(RegExp(f + "$"), r));
    let replaced= s.replace(RegExp("^(?:.*?" + f + "){" + n + "}",'s'), x => x.replace(RegExp(f + "$"), r));
    return replaced
  }
  addCustomMsg = (dynamicIndex) => (event) =>{
    let {dynamicMap} = this.state;
    if(event.target.value == 0){
      delete dynamicMap[dynamicIndex]
    } else {
      dynamicMap[dynamicIndex] = event.target.value
    }
    this.setState({dynamicMap: dynamicMap})
    GAEventTracker({category : 'Customize-SMS', action : 'Signature Selected'});
  }
  closePreviewSMS = () => this.setState({showPreviewMsg: false})

  getTemplateList = () => {
    const {templateRes} = this.props;
    const {senderId} = this.state;
    if (templateRes && templateRes.success && templateRes.data && templateRes.data?.template.length) {
      return templateRes.data?.template.filter(data => data.senderId === senderId);
    }
    return [];
  }

  handleSelectTemplate = (e) => {
    const selectedItem = this.props.templateRes.data?.template.find(data => data.id === e.target.value)
    this.selectedTemplateId = selectedItem.id;
    this.setState({
      selectedTemplate: e.target.value, smsText: selectedItem.text, dynamicMap: {}, dynamicFieldInMsg: (selectedItem.text.match(/{#var#}/g) || []).length
    }, () => this.calculateSmsText(selectedItem.text));
  }

  getSMSPreview = () => {
    let {smsText, dynamicMap, cols} = this.state;
    const keys = Object.keys(dynamicMap)
    keys.forEach(data => {
      smsText = this.replaceNth(smsText, '{#var#}', dynamicMap[data], 1)
    })
    return smsText;
  }

  render() {
    const isMobile = window.isMobile();
    const {
      smsDetails, readFile, smsText, btnDisabled, openSnackBar, snackBarMsg, senderId, smsType, cols, showPreviewMsg, cstmMsg, mobCol, selectedTemplate,
      dynamicFieldInMsg, dynamicMap
    } = this.state;
    const smsDetailsLen = Object.keys(smsDetails).length;
    const templateList = this.getTemplateList();
    return (
      <div className='sms-parent-container' style={isMobile ? {padding: 8} : {}} id='sms-ui'>
        <div>
          {
            smsDetailsLen ?
              <>
                {isMobile && <SMSBalanceMobile smsDetails={ smsDetails } />}
                <Heading>Select Header and SMS Type</Heading>
                <div className='sms-small-container-new'>
                  {this.senderIds && this.senderIds.length > 0 &&
                <Select
                  id="senderId"
                  value={senderId}
                  onChange={this.handleSenderIdChange}
                  label="Select Header"
                  sx={{minWidth: 170}}
                  options={this.senderIds}
                  required
                />
                  }
                  {smsDetails.smsType && smsDetails.smsType.length > 0 &&
                <Select
                  id="smsType"
                  value={smsType}
                  onChange={this.handleToggle}
                  label="Select SMS Type"
                  sx={{minWidth: 170, marginLeft: 2}}
                  options={smsDetails.smsType.map(el => ({name: labelMap[el], value: el}))}
                  required
                />
                  }
                </div>
                <Heading>Choose Template</Heading>
                <div className='sms-small-container-new'>
                  <div>
                    {templateList.length > 0 ? 
                      <TemplateList selectedTemplate={selectedTemplate} templateList={templateList} onChange={this.handleSelectTemplate} />
                      :
                      <p style={{color: '#d32f2f', fontSize: 14, marginTop: 10}}>
                      No template found for selected Header, Please add template from <Link href='/template' color="error">Manage Templates</Link>.
                      </p>
                    }
                    <div style={{paddingTop: 8, fontSize: 14, color: 'rgba(0, 0, 0, 0.6)'}}>
                      Upload Excel File and choose columns for Mobile Number and Message from your uploaded file to send Customized SMS.
                    </div>
                    <div>
                      <ReactFileReader
                        handleFiles={ this.handleFiles }
                        fileTypes={ '.csv,.xlsx,.xls' }
                        elementId='fileUpload'
                      >
                        <Button variant="outlined" onClick={this.openUploadExcel}>Upload Excel (.xlsx,.xls,.csv)</Button>
                      </ReactFileReader>
                    </div>
                    {this.getNumberMessageCol()}
                    <div style={{marginTop: 16}}>
                      <div style={{display:'flex', gap: 8}}>
                        {
                          cols.length > 0 && dynamicFieldInMsg > 0 && (
                            [...Array(dynamicFieldInMsg).keys()].map(index => (
                              <Select
                                id={`dynamicFieldInMsg-${index}`}
                                key={index}
                                value={dynamicMap[index] || 0}
                                onChange={this.addCustomMsg(index).bind(this)}
                                sx={{width: 200}}
                                options={this.customMsgDropdown(index)}
                                label={`Select var ${ index + 1 } Column`}
                                required
                              />
                            ))
                          )
                        }
                      </div>
                      <TextField
                        id="customSms"
                        label="SMS Text"
                        multiline
                        rows={4}
                        value={ smsText }
                        onChange={ (e) => this.handleSmsTextChange(e.target.value) }
                        inputRef={ (input) => this.smsInput = input }
                        fullWidth
                        sx={{mt: 2}}
                        disabled
                        required
                        variant="standard"
                        inputProps={{maxLength: 918}}
                      />
                      <div style={{display: 'flex', marginTop: 8, alignItems: 'center', ...(isMobile ? {flexWrap: 'wrap'} : {})}}>
                        <div>
                          {smsDetails.smsType.length === 2 &&
                          <p style={ {fontSize: 14, color: '#d32f2f', marginTop: 5} }>Note : You have selected {smsType} type!</p>
                          }
                        </div>
                        <div style={{marginLeft: 'auto'}}>
                          {smsType !== 'OTP' && <SmsFlashOptionButton
                            isSmsFlashEnabledFn={ this.isSmsFlashEnabledFn }
                            pageType='Customize-SMS'
                          />
                          }
                          <Button variant="contained" disabled={ !smsText || mobCol === 'Choose' } onClick={this.previewSMS}>Preview</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              :
              <Dialog open>
                <DialogContent sx={{width: 100}}>
                  <div>
                    <CircularProgress />
                  </div>
                </DialogContent>
              </Dialog>
          }
        </div>
        {readFile &&
          <Dialog open>
            <DialogContent sx={{width: 325}}>
              <div>
                <p style={ {width: 200, margin: '0 auto', textAlign: 'center', marginBottom: 10} }>
                  <DataUsage sx={{color: '#1976d2', fontSize: 50}} onClick={e => e.stopPropagation()} />
                </p>
                <p>Please wait while we process your file!</p>
              </div>
            </DialogContent>
          </Dialog>
        }
        {showPreviewMsg &&
          <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open
            onClose={this.closePreviewSMS}
          >
            <DialogTitle id="alert-dialog-title">
              Preview Submission
            </DialogTitle>
            <DialogContent>
              <div>
                <div style={{marginBottom: 16}}>
                  <Table
                    column={[
                      {name: 'Mobile', value: 'mobile', style:{width:'20%'}},
                      {name: 'Message', value: 'text', style: {width:'70%', whiteSpace: 'break-spaces'}},
                      {name: 'Length', value: 'length', style: {width:'10%'}}
                    ]}
                    data={cstmMsg.map(el => ({...el, length: el.text.length}))}
                  />
                </div>
                <p>Total Contacts: {this.processCustomMsg.length}</p>
                <p>Total SMS: {this.totalSMSCount}</p>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closePreviewSMS}>Close</Button>
              <Button disabled={btnDisabled} onClick={this.handleSend} variant='contained'>Send Now</Button>
            </DialogActions>
          </Dialog>
        }
        {
          btnDisabled &&
          <Dialog open>
            <DialogContent sx={{width: 500}}>
              <div>
                <CircularProgress />
                <br />
                Please wait while we submit your message!
              </div>
            </DialogContent>
          </Dialog>
        }
        {openSnackBar &&
          <Snackbar
            open
            message={ snackBarMsg }
            autoHideDuration={ 4000 }
            onClose={this.handleSnackBarClose}
          />
        }
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    sendCustomSmsResponse: state.smsResponse.sendCustomSmsResponse,
    smsDetails: state.smsResponse.smsDetailsResponse,
    templateRes: state.template.templateList
  };
}
export default connect(mapStateToProps, {sendCustomizeSms, getSMSDetails, updateSMSCount, getTemplate})(Sms);
