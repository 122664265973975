import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';

import Select from '../../components/Select';

export default class ChangeDefaultSenderId extends Component {
  constructor(props){
    super(props);
    this.state={
      val : props.senderId
    }
  }
  handleChange = (e) => this.setState({val: e.target.value});
  render(){
    const {val} = this.state;
    return(
      <Dialog
        open={true}
        onRequestClose={this.props.closeChangeDefaultSenderId}
      >
        <DialogTitle>Change Default Header</DialogTitle>
        <DialogContent style={{paddingTop: 16}}>
          <Select
            label="Select Default Header"
            value={val}
            fullWidth
            onChange={this.handleChange}
            options={this.props.senderIds}
            sx={{minWidth: 300}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeChangeDefaultSenderId}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => this.props.changeDefaultSenderId({defaultSenderId: val})}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
