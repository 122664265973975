import React from 'react';
import {Button, Dialog, DialogActions, DialogTitle} from '@mui/material';

const UserDelete = ({number, closeDelete, bulkDelete, handleDelete}) => (
  <Dialog open>
    <DialogTitle>
          Delete {bulkDelete ? 'selected numbers' : number} ?
    </DialogTitle>
    <DialogActions>
      <Button onClick={closeDelete}>
            Cancel
      </Button>
      <Button onClick={handleDelete} variant='contained' color="error">
            Delete
      </Button>
    </DialogActions>
  </Dialog>
)

export default UserDelete
