const CONSTANT = {
  SMS_TYPE_MAP: {
    PROMO: {name: 'Promotional', color: '#1e88e5'},
    TRANS: {name: 'Transactional', color: '#00897b'},
    OTP: {name: 'OTP', color: '#fb8c00'}
  },
  SMS_USAGE_TYPE:[
    {dataKey: 'submitted', label: 'Submitted'},
    {dataKey: 'delivered', label: 'Delivered'}
  ]
}

export default CONSTANT