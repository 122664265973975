import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {throttle} from 'lodash';
import ReactDOM from 'react-dom';

import RecipientChip from './recipientChip';

export default class SelectedContact extends Component {
  constructor(){
    super();
    this.state={
      page: 1
    }
    this.scrollFunc = throttle(this.scrollFunction.bind(this),500);
  }
  componentDidMount(){
    window.addEventListener('scroll', this.scrollFunc, true)
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.scrollFunc, true)
  }
  scrollFunction = () =>{
    let el = ReactDOM.findDOMNode(this.refs.selectedContactRef);
    if(el.scrollTop + el.offsetHeight + 100 >= el.scrollHeight){
      this.setState({'page': this.state.page + 1});
    }
  }
  calculateRenderItem = (contactList,page) =>{
    let total = 150*page;
    let newContactList = {
      all : false
    }
    if(contactList.contact.length){
      newContactList.contact = contactList.contact.slice(0,total);
    } else{
      newContactList.contact = [];
    }
    const newContactListLen = newContactList.contact.length;
    if(newContactListLen < total && contactList.group.length){
      newContactList.group = contactList.group.slice(0,total - newContactListLen);
    } else {
      newContactList.group = [];
    }
    const newGroupListLen = newContactList.group.length;
    if((newContactListLen + newGroupListLen) < total && contactList.extra.length){
      newContactList.extra = contactList.extra.slice(0,total - (newContactListLen+newGroupListLen));
    } else {
      newContactList.extra = [];
    }
    return newContactList;
  }
  render(){
    let contactListVisible = this.calculateRenderItem(this.props.contactList,this.state.page);
    return(
      <Dialog
        open
        onClose={this.props.handleSelectedDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Selected Contacts
        </DialogTitle>
        <DialogContent>
          <div ref="selectedContactRef">
            <RecipientChip
              renderLess={false}
              contactList={contactListVisible}
              originalContactList={this.props.contactList}
              contactListCallback={this.props.contactListCallback} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleSelectedDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
