import React, {Component} from 'react';
import {Add, Clear, Delete, Edit, FileDownload, HighlightOff, Search} from '@mui/icons-material';
import {Button, CircularProgress, Dialog, DialogContent, IconButton, Snackbar, TextField, Tooltip} from '@mui/material';
import {throttle} from 'lodash';
import {connect} from 'react-redux';

import {addUser, deleteBulkContact, deleteUser, downloadContact, getAllUser, getContactGroup, updateUser} from '../../actions/user';
import {GAEventTracker} from '../../lib/googleAnalytics';
import Table from '../Table';
import BulkContactUpload from '../bulkContactUpload';
import UserAdd from './add';
import UserDelete from './delete';
import UserEdit from './edit';

class User extends Component {
  constructor(props){
    super(props);
    this.state = {
      showDelete : false,
      openSnackBar : false,
      openAdd : false,
      openEdit : false,
      currentUserDetails : null,
      userList : [],
      autoCompleteValue : '',
      page: 1,
      showWait : false,
      showBulkDelete : false,
      deleteList : [],
      deleteAllCheck : false
    };
    this.receivedUser = false;
    this.deleteAction = false;
    this.addAction = false;
    this.updateAction = false;
    this.snackBarMsg = '';
    this.groupList = [];
    this.userList = [];
    this.throttleInput = throttle(this.throttleInput.bind(this),300);
    this.autoComplete = this.autoComplete.bind(this);
    this.receiveDownload = false;
    this.bulkDeleteAction = false;
    this.loadData = false;
  }
  componentWillMount(){
    let {userResponse} = this.props;
    if(userResponse && userResponse.success){
      let userList = userResponse.data.contacts;
      this.receivedUser = true;
      this.userList = userList;
      let {groups} = userResponse.data;
      let groupMap = Object.keys(groups);
      groupMap.map(data=>{
        this.groupList.push({id:groups[data].id,name:groups[data].name});
      });
      this.setState({userList});
    } else {
      this.props.getAllUser();
    }
  }
  componentWillReceiveProps(nextProps){
    let nProps = nextProps.user;
    let {userResponse,downContactRes} = nextProps;
    if(!this.receivedUser && this.state.userList && userResponse && userResponse != this.props.userResponse){
      if(userResponse.success){
        let userList = userResponse.data.contacts;
        this.receivedUser = true;
        this.userList = userList;
        if(!this.loadData){
          if(this.groupList.length == 0){
            let {groups} = userResponse.data;
            let groupMap = Object.keys(groups);
            groupMap.map(data=>{
              this.groupList.push({id:groups[data].id,name:groups[data].name});
            });
          }
          this.setState({userList});
        } else{
          this.loadData = false;
          this.setState({userList, showWait: false});
        }
      } else if(userResponse.err){
        this.receivedUser = true;
        alert(userResponse.err.msg);
      }else{
        alert("Something went wrong!");
        window.location.reload();
      }
      return;
    }
    if(this.deleteAction && nProps.deleteResponse){
      this.deleteAction = false;
      if(nProps.deleteResponse.success){
        this.snackBarMsg = this.state.currentUserDetails.name + " deleted Successfully!";
        this.receivedUser = false;
        this.loadData = true;
        this.props.getAllUser();
        this.props.getContactGroup();
        this.setState({showWait : true, showDelete : false, currentUserDetails : null, openSnackBar:true});
      } else if(nProps.deleteResponse.err){
        this.snackBarMsg = nProps.deleteResponse.err.msg;
        alert(nProps.deleteResponse.err.msg);
        this.refs.userDelete.callbackHandler();
        this.setState({openSnackBar:true});
      } else{
        this.snackBarMsg = "Something went wrong, Please try again!";
        alert("Something went wrong, Please try again!");
        this.refs.userDelete.callbackHandler();
        this.setState({openSnackBar:true});
      }
      return;
    }
    if(this.bulkDeleteAction && nProps.bulkDeleteResponse){
      this.bulkDeleteAction = false;
      if(nProps.bulkDeleteResponse.success){
        this.snackBarMsg = "All Selected contacts deleted Successfully!";
        this.receivedUser = false;
        this.loadData = true;
        this.props.getAllUser();
        this.props.getContactGroup();
        this.setState({showWait : true, showDelete : false, openSnackBar:true, showBulkDelete : false, deleteList: []});
      } else if(nProps.bulkDeleteResponse.err){
        this.snackBarMsg = nProps.bulkDeleteResponse.err.msg;
        alert(nProps.bulkDeleteResponse.err.msg);
        this.refs.userDelete.callbackHandler();
        this.setState({openSnackBar:true});
      } else{
        this.snackBarMsg = "Something went wrong, Please try again!";
        alert("Something went wrong, Please try again!");
        this.refs.userDelete.callbackHandler();
        this.setState({openSnackBar:true});
      }
      return;
    }
    if(this.addAction && nProps.addResponse){
      this.addAction = false;
      if(nProps.addResponse.success){
        this.snackBarMsg = this.props.label + " added Successfully!";
        this.receivedUser = false;
        this.loadData = true;
        this.props.getAllUser();
        this.props.getContactGroup();
        this.setState({showWait : true, openAdd : false, openSnackBar : true});
      } else if(nProps.addResponse.err){
        this.snackBarMsg = nProps.addResponse.err.msg;
        alert(nProps.addResponse.err.msg);
        this.refs.userAdd.callbackHandler();
        this.setState({openSnackBar : true});
      } else{
        this.snackBarMsg = "Something went wrong, Please try again!";
        alert("Something went wrong, Please try again!");
        this.refs.userAdd.callbackHandler();
        this.setState({openSnackBar : true});
      }
      return;
    }
    if(this.updateAction && nProps.updateResponse){
      this.updateAction = false;
      if(nProps.updateResponse.success){
        this.snackBarMsg = this.state.currentUserDetails.name + " updated Successfully!";
        this.receivedUser = false;
        this.loadData = true;
        this.props.getAllUser();
        this.props.getContactGroup();
        this.setState({showWait : true, openEdit : false, currentUserDetails : null, openSnackBar : true});
      } else if(nProps.updateResponse.err){
        this.snackBarMsg = nProps.updateResponse.err.msg;
        alert(nProps.updateResponse.err.msg);
        this.refs.userEdit.callbackHandler();
        this.setState({openSnackBar : true});
      } else{
        this.snackBarMsg = "Something went wrong, Please try again!";
        alert("Something went wrong, Please try again!");
        this.refs.userEdit.callbackHandler();
        this.setState({openSnackBar : true});
      }
      return;
    }
    if(this.receiveDownload && downContactRes){
      this.receiveDownload = false;
      if(downContactRes.success){
        let a = document.getElementById('download-contact');
        a.href = `${window.location.protocol}//${downContactRes.data}`
        a.click();
        this.setState({showWait : false});
      } else if(downContactRes.err){
        alert(downContactRes.err.msg);
        this.setState({showWait : false});
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }
      return;
    }
  }
  fetchUser = (userId) => {
    let uList = this.state.userList;
    let currentUser = null;
    for(var id in uList){
      if(uList[id].id == userId){
        currentUser = uList[id];
        currentUser.groupList = currentUser.groups ? currentUser.groups.map(data=>data.groupId) : [];
        break;
      }
    }
    return currentUser;
  }
  openDelete = (e, userId) => {
    e.stopPropagation()
    this.setState({showDelete : true, currentUserDetails : this.fetchUser(userId)});
  }
  deleteUserFromDetails = () => this.setState({showDelete : true});
  closeDelete = () => {
    GAEventTracker({category : 'Delete-Contact', action : 'Cancel'});
    this.setState({showDelete : false, currentUserDetails : null});
  }
  handleSnackBarClose = () => {
    this.snackBarMsg = '';
    this.setState({openSnackBar : false});
  }
  handleAddUser = (params) => {
    this.addAction = true;
    this.props.addUser(params);
    GAEventTracker({category : 'Add-Contact', action : 'Save'});
  }
  addUser = (e) => {
    e.stopPropagation()
    this.setState({openAdd : true});
  }
  editUser = (e, userId) => {
    e.stopPropagation()
    this.setState({openEdit : true, currentUserDetails : this.fetchUser(userId)});
  }
  editUserFromDetails = () => this.setState({openEdit : true})
  closeEditUser = () => {
    GAEventTracker({category : 'Edit-Contact', action : 'Cancel'});
    this.setState({openEdit : false, currentUserDetails : null});
  }
  closeAddUser = () => {
    this.setState({openAdd : false});
    GAEventTracker({category : 'Add-Contact', action : 'Cancel'});
  }
  handleDelete = () => {
    const {showBulkDelete,deleteList,currentUserDetails} = this.state;
    if(showBulkDelete){
      this.bulkDeleteAction = true;
      this.props.deleteBulkContact({contacts:deleteList});
    } else{
      this.deleteAction = true;
      this.props.deleteUser(currentUserDetails.id);
    }
  }
  handleEditUser = (params) => {
    this.updateAction = true;
    this.props.updateUser(params,params.id);
  }
  autoComplete = (e) => {
    this.setState({autoCompleteValue:e.target.value});
    this.throttleInput(e.target.value);
  }
  throttleInput = (val) => {
    let userList = this.userList.filter(data => {return (data.name.toLowerCase().indexOf(val.toLowerCase()) > -1 || data.mobile.indexOf(val) > -1);});
    this.setState({userList});
  }
  refreshList = () =>{
    this.receivedUser = false;
    this.props.getAllUser();
    this.props.getContactGroup();
  }
  removeAutoCompleteValue = (e) => {
    e.stopPropagation()
    this.setState({autoCompleteValue : '',userList:this.userList});
  }
  downloadContacts = (e) => {
    e.stopPropagation()
    this.receiveDownload = true;
    this.setState({showWait : true});
    this.props.downloadContact();
    GAEventTracker({category : 'Contacts', action : 'Download Contacts'});
  }
  showBulkDelete = () => this.setState({showBulkDelete : true});
  handleBulkDelete = () => {
    if(this.state.deleteList.length){
      this.setState({showDelete : true});
    } else{
      alert("Select Contacts to Delete!");
    }
  }
  clearBulkDelete = (e) => {
    e.stopPropagation()
    this.setState({showBulkDelete : false,deleteList : [],deleteAllCheck:false});
  }
  updateCheck = (id) => {
    let {deleteList,userList} = this.state;
    let idIndex = deleteList.indexOf(id);
    if(idIndex == -1){
      deleteList.push(id)
    } else{
      deleteList.splice(idIndex,1);
    }
    this.setState({deleteList,deleteAllCheck:userList.length == deleteList.length});
  }
  updateCheckAll = () =>{
    let {deleteAllCheck,userList} = this.state;
    let deleteList = [];
    if(deleteAllCheck){
      this.setState({deleteList,deleteAllCheck : false});
    } else{
      for(var delContact of userList){
        deleteList.push(delContact.id);
      }
      this.setState({deleteList,deleteAllCheck : true});
    }
  }
  render() {
    let {showWait,autoCompleteValue,userList,currentUserDetails,showDelete,openSnackBar,openAdd,openEdit,showBulkDelete,deleteList} = this.state;
    const isMobile = window.isMobile();
    const rightIconButtons = (userId) => {
      return isMobile ? null : (
        <>
          <Tooltip title="Edit Contact">
            <IconButton aria-label='Edit' onClick={(e)=>{this.editUser(e, userId)}}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Contact">
            <IconButton aria-label='Delete' onClick={(e)=>{this.openDelete(e, userId)}}>
              <Delete />
            </IconButton>
          </Tooltip>
        </>
      )
    }
    return (
      <div className='sms-parent-container' style={isMobile ? {padding: 8} : {}}>
        <div className='sms-small-container'>
          <p style={{fontSize: 16}}>{this.userList.length > 0 ? (autoCompleteValue ? `${userList.length} / ` : '') + this.userList.length : ''} Contacts</p>
          {this.userList.length && !userList.length ?
            <div style={{display: 'flex', alignItems: 'center', gap: 8, width: isMobile ? 300 : 350, marginTop: 8}}>
              <Search onClick={e => e.stopPropagation()} />
              <TextField
                label={isMobile ? "Search Contact" : "Search Contact By Name/Number"}
                size="small"
                fullWidth
                value={autoCompleteValue}
                variant="standard"
                onChange={this.autoComplete.bind(this)}
              />
              {
                autoCompleteValue ?
                  <IconButton aria-label='Clear Search' onClick={this.removeAutoCompleteValue}>
                    <HighlightOff />
                  </IconButton>
                  : null
              }

            </div> : null
          }
          {
            this.userList.length ?
              <>
                {
                  userList.length ?
                    <>
                      <Table
                        column={[
                          {name: 'Name', value: 'name'},
                          {name: 'Mobile', value: 'mobile'},
                          {name: 'Groups', value: 'groups', cell: val => val?.length > 0 ? val.map(el => el.name).join(', ') : ''},
                          ...(showBulkDelete ? [] : [{name: 'Actions', value: 'id', cell: val => rightIconButtons(val)}])
                        ]}
                        data={userList}
                        enableCheckbox={showBulkDelete}
                        handleSelect={this.updateCheck}
                        selected={deleteList}
                        onSelectAllClick={this.updateCheckAll}
                        showPagination
                        defaultPageSize={100}
                        actionEl={<div style={{display: 'flex', alignItems: 'center', gap: 8, marginLeft: 'auto', flexWrap: 'wrap', justifyContent: 'end'}}>
                          <div style={{display: 'flex', alignItems: 'center', gap: 8, width: isMobile ? 300 :350}}>
                            <Search onClick={e => e.stopPropagation()} />
                            <TextField
                              label={isMobile ? "Search Contact":  "Search Contact By Name/Number"}
                              size="small"
                              fullWidth
                              value={autoCompleteValue}
                              variant="standard"
                              onChange={this.autoComplete.bind(this)}
                            />
                            {
                              autoCompleteValue ?
                                <IconButton aria-label='Clear Search' onClick={this.removeAutoCompleteValue}>
                                  <HighlightOff />
                                </IconButton>
                                : null
                            }

                          </div>
                          {!showBulkDelete ? <><BulkContactUpload refreshList={this.refreshList} />
                            <Button startIcon={<Add />} onClick={this.addUser} variant="outlined">Add Contact</Button>
                            <Button startIcon={<Delete />} onClick={showBulkDelete ? this.handleBulkDelete : this.showBulkDelete} variant="outlined">Bulk Delete</Button>
                            {
                              this.userList.length && !isMobile ?
                                <Button startIcon={<FileDownload />} onClick={this.downloadContacts} variant="outlined">Download Contacts</Button>
                                : null
                            }</> :<>
                            <Button startIcon={<Clear />} onClick={this.clearBulkDelete} variant='outlined'>Clear Bulk Delete</Button>
                            <Button startIcon={<Delete />} onClick={this.handleBulkDelete} variant='outlined'>Confirm Delete</Button>
                          </>}
                        </div>}
                      />
                    </>
                    :
                    <p style={{margin:30,fontSize:16}}>No {this.props.label} Available with searched keyword!</p>
                }
                <a href="" id="download-contact" style={{display:'none'}}></a>
              </>
              :
              <div>
                {
                  this.receivedUser ?
                    <>
                      <div style={{display: 'flex', alignItems: 'center', gap: 8, marginLeft: 'auto', flexWrap: 'wrap', justifyContent: 'end'}}>
                        <BulkContactUpload refreshList={this.refreshList} />
                        <Button startIcon={<Add />} onClick={this.addUser} variant="outlined">Add Contact</Button>
                      </div>
                      <p style={{margin:50,fontSize:20,color:'#EF5350'}}>
                      Currently No {this.props.label} Available! Click on "Add Contact" button to create Your First {this.props.label}!
                      </p>
                    </>
                    :
                    <CircularProgress size={80} thickness={5} />
                }
              </div>
          }
        </div>
        {
          showDelete ?
            <UserDelete
              ref="userDelete"
              showDelete = {showDelete}
              bulkDelete = {showBulkDelete}
              details={currentUserDetails}
              handleDelete={this.handleDelete}
              closeDelete={this.closeDelete} />
            : null
        }
        {
          openSnackBar ?
            <Snackbar
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              open
              onClose={this.handleSnackBarClose}
              autoHideDuration={ 4000 }
              message={this.snackBarMsg}
            />
            : null
        }
        {
          openAdd ?
            <UserAdd
              ref="userAdd"
              open={openAdd}
              label={this.props.label}
              closeAddUser={this.closeAddUser}
              groupList={this.groupList}
              userList={this.userList}
              handleAddUser={this.handleAddUser} />
            : null
        }
        {
          openEdit ?
            <UserEdit
              ref="userEdit"
              open={openEdit}
              label={this.props.label}
              details={currentUserDetails}
              handleEditUser={this.handleEditUser}
              groupList={this.groupList}
              closeEditUser={this.closeEditUser} />
            : null
        }
        {
          showWait ?
            <Dialog open>
              <DialogContent sx={{width: 100}}>
                <div>
                  <CircularProgress />
                </div>
              </DialogContent>
            </Dialog>
            : null
        }
      </div>
    )
  }
}
function mapStateToProps(state){
  return {
    user : state.userResponse,
    userResponse : state.userResponse.userResponse,
    downContactRes : state.userResponse.downloadContacts
  };
}
export default connect(mapStateToProps, {deleteUser, updateUser, addUser, getAllUser, downloadContact, deleteBulkContact, getContactGroup})(User);
