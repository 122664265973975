import React, {Component} from 'react';
import {FileDownload, TrendingUp} from '@mui/icons-material';
import {Button, CircularProgress, Dialog, DialogContent, IconButton, Tooltip} from '@mui/material';
import moment from 'moment';
import {connect} from 'react-redux';

import {getSMSDetails} from '../../actions/sms';
import {getSMSHistory, getSMSReportSync, getSmartStatusSMSHistory, getStatusSMSHistory, getTrackCampaignDelivery} from '../../actions/smsHistory';
import Table from '../../components/Table';
import {GAEventTracker} from '../../lib/googleAnalytics';
import {dateFormat} from '../../lib/utils';
import Filter from './Filter';
import SmartSmsReport from './smartSmsReport';
import SmsReport from './smsReport';
import TrackCampaign from './trackCampaign';

class SmsHistory extends Component {
  constructor(props){
    super(props);
    this.state = {
      smsList : [],
      showAllContacts : false,
      showAllSmartContacts: false,
      showContactsList : [],
      showWait : false,
      anchorEl : null,
      activeyTrackCampaign: null,
      showTrackData: false,
      excelData: [],
      smsDetails: props.smsDetails && props.smsDetails.success ? props.smsDetails.data : {}
    }
    this.orgSMSList = [];
    this.receivedHistory = null;
    this.receiveSingleHistory = true;
    this.receiveSingleSmartHistory = true;
    this.receiveTrackCampaign = true;
    this.receiveReport = false;
    this.receivedSMSDetails = null;
    this.singleHistory = [];
    this.singleSmartHistory = []
    this.activeTrackData = null;
    this.smsTypeFilter = 'ALL'
    this.filterApplied = false
  }
  componentDidMount(){
    if (!this.state.smsDetails.senderIds) {
      this.receivedSMSDetails = false;
      this.props.getSMSDetails();
    } else {
      this.receivedSMSDetails = true;
      const params = {
        endDate: moment().valueOf(),
        startDate: moment().subtract(1, 'months').valueOf(),
        senderId: this.state.smsDetails.senderIds
      }
      this.receivedHistory = false;
      this.props.getSMSHistory(params);
    }
  }
  componentWillReceiveProps(nextProps){
    let {smsHistoryList, singleHistoryList, singleSmartHistoryList, downReportRes, trackCampaignRes, smsDetails} = nextProps;
    if(this.receivedHistory === false && this.state.smsList.length == 0 && smsHistoryList){
      if(smsHistoryList.success){
        let smsList = smsHistoryList.data.list?.map(el => ({...el, id: el.id})) || [];
        this.receivedHistory = true;
        this.orgSMSList = smsList
        if (this.smsTypeFilter !== 'ALL') {
          smsList = this.orgSMSList.filter(data => data.smsCountByType.type === this.smsTypeFilter)
          this.setState({smsList, excelData: this.makeExcelData(smsList)})
        } else {
          this.setState({smsList, excelData: this.makeExcelData(this.orgSMSList)});
        }
      } else if(smsHistoryList.err){
        alert(smsHistoryList.err.msg);
        this.receivedHistory = true;
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }
    }
    if(!this.receiveSingleHistory && singleHistoryList){
      if(singleHistoryList.success){
        this.singleHistory = singleHistoryList.data;
        this.receiveSingleHistory = true;
        this.setState({showAllContacts : true,showWait : false});
      } else if(singleHistoryList.err){
        alert(singleHistoryList.err.msg);
        this.receiveSingleHistory = true;
        this.setState({showWait : false});
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }
    }
    if(!this.receiveSingleSmartHistory && singleSmartHistoryList){
      if(singleSmartHistoryList.success){
        this.singleSmartHistory = singleSmartHistoryList.data;
        this.receiveSingleSmartHistory = true;
        this.setState({showAllSmartContacts : true,showWait : false});
      } else if(singleSmartHistoryList.err){
        alert(singleSmartHistoryList.err.msg);
        this.receiveSingleSmartHistory = true;
        this.setState({showWait : false});
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }
    }
    if(!this.receiveTrackCampaign && trackCampaignRes){
      if(trackCampaignRes.success){
        this.receiveTrackCampaign = true;
        this.setState({activeyTrackCampaign: trackCampaignRes.data, showTrackData: true});
      } else if(trackCampaignRes.err){
        alert(trackCampaignRes.err.msg);
        this.receiveTrackCampaign = true;
        this.setState({showTrackData: false, anchorEl: null});
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }
    }
    if(this.receiveReport && downReportRes){
      this.receiveReport = false;
      if(downReportRes.success){
        let a = document.getElementById('download-report');
        a.href = downReportRes.data?.generatedUrl;
        a.click();
        this.setState({showWait : false});
      } else if(downReportRes.err){
        alert(downReportRes.err.msg);
        this.setState({showWait : false});
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }
    }
    if (this.receivedSMSDetails === false && smsDetails) {
      this.receivedSMSDetails = true;
      if (smsDetails.success) {
        this.setState({smsDetails: smsDetails.data})
        const params = {
          endDate: moment().valueOf(),
          startDate: moment().subtract(1, 'months').valueOf(),
          senderId: smsDetails.data.senderIds
        }
        this.receivedHistory = false;
        this.props.getSMSHistory(params);
      } else if (smsDetails.err) {
        alert(smsDetails.err.msg);
        window.location.reload();
      } else {
        alert("Something went wrong!");
        window.location.reload();
      }
    }
  }
  showAllContacts = (id) => {
    this.receiveSingleHistory = false;
    this.setState({showWait : true});
    this.props.getStatusSMSHistory(id);
    GAEventTracker({category : 'SMS-History', action : 'Show Report'});
  }
  showAllContactsSmart = (id) => {
    this.receiveSingleSmartHistory = false;
    this.setState({showWait : true});
    this.props.getSmartStatusSMSHistory(id);
    GAEventTracker({category : 'SMS-History', action : 'Show Smart Report'});
  }
  showTrackCampaign = (event, data) => {
    event?.stopPropagation()
    this.activeTrackData = data;
    this.receiveTrackCampaign = false;
    this.setState({anchorEl : event.currentTarget, showTrackData: false});
    //Needs to be check
    this.props.getTrackCampaignDelivery(data.id, {isSmartURL: data.url ? 1 : 0});
    GAEventTracker({category : 'SMS-History', action : 'Show Track Campaign'});
  }
  closeAllContacts = () => this.setState({showAllContacts : false});
  closeAllSmartContacts = () => this.setState({showAllSmartContacts: false});
  closeTrackCampaign = () => this.setState({anchorEl: null, showTrackData: false});
  downloadReport = (e, id, isSmartUrl) => {
    e?.stopPropagation()
    this.receiveReport = true;
    this.setState({showWait : true});
    getSMSReportSync(id, isSmartUrl).then(dataChunks=>{
      // Function to decode Base64 data to binary
      function base64ToUint8Array(base64) {
        const binaryString = atob(base64);
        const bytes = new Uint8Array(binaryString.length);

        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return bytes;
    }

    // Combine all chunks into a single Uint8Array
    let totalLength = 0;
    dataChunks.forEach(dataChunk => {
        totalLength += base64ToUint8Array(dataChunk.chunk).length;
    });

    const combinedBytes = new Uint8Array(totalLength);
    let offset = 0;

    dataChunks.forEach(dataChunk => {
        const bytes = base64ToUint8Array(dataChunk.chunk);
        combinedBytes.set(bytes, offset);
        offset += bytes.length;
    });

    // Create a Blob from the combined data
    const blob = new Blob([combinedBytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Change MIME type if necessary

    // Create a URL for the Blob and set it as the href of a link element
    const url = URL.createObjectURL(blob);
    let a = document.getElementById('download-report');
    let elementIsNull = false;
    if (a==null){
      a = document.createElement('a');
      elementIsNull = true;
    }
    a.href = url;
    a.download = 'report.xlsx';  // Specify the filename for the download
    if (elementIsNull){
      document.body.appendChild(a);
    }
    a.click();
    this.setState({showWait : false});

    // Clean up
    if(elementIsNull){
      document.body.removeChild(a);
    }
    URL.revokeObjectURL(url);
      GAEventTracker({category : 'SMS-History', action : 'Download History'});
    }).catch(e=>{
      if(e && e.msg){
        alert(e.msg);
        this.setState({showWait : false});
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }
    });
   
  }

  //'Delivered SMS Counts': data.deliveryUpdates.DELIVRD || 0,
  //  'Delivery Percentage': `${(data.deliveryUpdates.DELIVRD || 0)*100/data.smsCountByType.val} %`,

  makeExcelData = (smsList) => smsList.map(data => (
    {
      'Date': dateFormat(data.createdAt),
      'SMS Text': data.smsText,
      'Header': data.senderId,
      'SMS Type': data.smsCountByType.type,
      'Contacts Counts': data.contactCount,
      'SMS Credits': data.smsCountByType.val,
      'Smart Tracking URL': data.url
    }
  ))

  isSameDate(a, b) {
    return Math.abs(a - b) < (1000 * 3600 * 24) && a.day() === b.day();
  }

  applyFilter = ({smsType, startDate, endDate, mobileNo, senderIds}, appliedFilter = true) => {
    this.filterApplied = appliedFilter
    const params = {
      senderId: senderIds
    }
    if (startDate || endDate) {
      if (startDate === '') {
        alert('Please provide start date!')
        return
      } else if (endDate === '') {
        alert('Please provide end date!')
        return
      } else {
        const sDate = startDate.startOf('day').valueOf()
        const eDate = endDate.endOf('day').valueOf()
        if (sDate > eDate) {
          alert('Start Date is greater than End Date, Please provide start date less than or equal to end Date')
          return
        } else {
          params.startDate = sDate
          params.endDate = eDate
        }
      }
    }
    if (mobileNo) {
      let numRegex = new RegExp("^[3-9][0-9]{9}$");
      if (numRegex.test(mobileNo)) {
        params.mobileNo = mobileNo
      } else {
        alert('Mobile Number is invalid, Please provide correct mobile number')
        return
      }
    }
    if (senderIds.length === 0) {
      alert('Please choose atleast one Header')
      return
    }
    this.smsTypeFilter = smsType
    this.setState({smsList: [], excelData: []})
    this.orgSMSList = [];
    this.receivedHistory = false;
    this.props.getSMSHistory(params);
  }

  render() {
    let {showAllContacts,smsList,showWait, showAllSmartContacts, activeyTrackCampaign, anchorEl, showTrackData, excelData, smsDetails} = this.state;
    const isMobile = window.isMobile();
    return (
      <>
        <div className='sms-parent-container' style={isMobile ? {padding: 8} : {}}>
          {this.receivedSMSDetails && <Filter applyFilter={this.applyFilter} excelData={excelData} smsDetails={smsDetails} />}
          {
            this.orgSMSList.length ?
              <div>
                {smsList.length ?
                  <>
                    <Table
                      column={[
                        {name: 'Date', value: 'createdAt', style: {width: 120}, cell: val => dateFormat(val)},
                        {
                          name:'SMS Text, Header',
                          value: 'smsText',
                          cell: (val, row) => (<><div style={{whiteSpace:'normal',wordWrap:'break-word'}}>{val}</div>
                            <div style={{fontSize:10, marginTop: 4, color: '#3b3d3e'}}>{row.senderId}</div></>)
                        },
                        {name: 'Credit', value: 'smsCount', cell : (val, row) => (<span>{val ||  row.smsCountByType.val}</span>)},
                        {name: 'Numbers', value: 'contactCount'},
                        {name: 'Track Campaign', value: 'deliveryUpdates', style: {width: 150}, cell: (val, row) => val && Object.keys(val).length ?
                          <Tooltip title="Track Campaign">
                            <IconButton aria-label='Track' onClick={(e) => this.showTrackCampaign(e, row)}>
                              <TrendingUp color='primary' />
                            </IconButton>
                          </Tooltip>
                          :
                          '---'},
                        {name: 'Status/Download', value: 'status', style: {width: 180}, cell: (val, row) => (val == 0 ? "Pending" :
                          <div style={{display: 'flex', gap: 8, flexWrap: 'wrap'}}>{row.url &&
                          <>
                            <Button size="small" variant='outlined' onClick={() => this.showAllContactsSmart(row.id)}>
                              {isMobile ? "Tracking" : "Smart URL Tracking"}
                            </Button>
                            <Tooltip title="Download">
                              <IconButton aria-label='Download' onClick={e=>this.downloadReport(e, row.id, 1)}>
                                <FileDownload color='primary' />
                              </IconButton>
                            </Tooltip>
                          </>}
                          
                          <Button size="small" variant='outlined' onClick={() => this.showAllContacts(row.id)}>
                            Show Status
                          </Button>
                          <Tooltip title="Download Report">
                            <IconButton aria-label='Download Report' onClick={e=>this.downloadReport(e, row.id, 0)}>
                              <FileDownload color='primary' />
                            </IconButton>
                          </Tooltip>
                          </div>)}
                      ]}
                      serialNumber
                      data={smsList}
                      showPagination
                      defaultPageSize={100}
                    />
                    <a href="" id="download-report" style={{display:'none'}}></a>
                  </>
                  : <p style={{fontSize: 16}}>No SMS Reports found with applied filter, Please reset the filter!</p>
                }
              </div>
              :
              <div>
                {
                  this.receivedHistory ?
                    <p style={{padding: 50, fontSize: 16, color: '#333'}}>
                      {this.filterApplied ? 
                        'No SMS Reports found with applied filter, Please reset the filter!' : 'Currently No SMS Reports Available, Please do send your first SMS!' }
                    </p>
                    :
                    <CircularProgress size={80} thickness={5} />
                }
              </div>
          }
        </div>
        {
          showAllContacts ?
            <SmsReport
              singleHistory={this.singleHistory}
              closeAllContacts={this.closeAllContacts} />
            : null
        }
        {
          showAllSmartContacts ?
            <SmartSmsReport
              singleHistory={this.singleSmartHistory}
              closeAllContacts={this.closeAllSmartContacts} />
            : null
        }
        {Boolean(anchorEl) && <TrackCampaign
          showTrackData={showTrackData}
          anchorEl={anchorEl}
          deliveryUpdates={activeyTrackCampaign}
          activeTrackData={this.activeTrackData}
          closeTrackCampaign={this.closeTrackCampaign} />
        }
        {
          showWait ?
            <Dialog open={showWait}>
              <DialogContent>
                <CircularProgress />
              </DialogContent>
            </Dialog>
            : null
        }
      </>
    )
  }
}
function mapStateToProps(state){
  return {
    smsHistoryList : state.smsHistory.getHistoryResponse,
    singleHistoryList : state.smsHistory.getSingleHistoryResponse,
    singleSmartHistoryList: state.smsHistory.getSingleSmartHistoryResponse,
    downReportRes : state.smsHistory.getDownloadReportResponse,
    trackCampaignRes : state.smsHistory.getTrackCampaignResponse,
    smsDetails: state.smsResponse.smsDetailsResponse
  };
}
export default connect(mapStateToProps, {getSMSHistory, getStatusSMSHistory, getTrackCampaignDelivery, getSmartStatusSMSHistory, getSMSDetails})(SmsHistory);
