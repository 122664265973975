import { Map } from 'immutable';

import { GET_STUDENT, DELETE_STUDENT, ADD_STUDENT, UPDATE_STUDENT } from '../lib/constant';

const initialState = Map({getResponse : {}, deleteResponse : {}, addResponse : {}, updateResponse : {}});

export default function(state = initialState , action ) {
	switch(action.type){
        case GET_STUDENT :
            return {...state,getResponse:action.payload};
        case DELETE_STUDENT :
            return {...state,deleteResponse:action.payload};
        case ADD_STUDENT :
            return {...state,addResponse:action.payload};
        case UPDATE_STUDENT :
            return {...state,updateResponse:action.payload};
        default :
		     return state;
    }
}
