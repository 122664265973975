import React, {Component} from 'react';
import {FileUpload} from '@mui/icons-material';
import {Button} from '@mui/material';

import {GAEventTracker} from '../../lib/googleAnalytics';
import UploadPanel from './uploadPanel';

export default class BulkContactUpload extends Component{
  constructor(){
    super();
    this.state={
      show : false
    };
  }
  showUploadPanel = (e) =>{
    e.stopPropagation()
    this.setState({show:true});
  }
  hideDialog = () =>{
    this.setState({show:false});
    GAEventTracker({category : 'Bulk-Contact-Upload', action : 'Close'});
  }
  render(){
    return(
      <>
        <Button startIcon={<FileUpload />} onClick={this.showUploadPanel} variant='outlined'>
          Import Bulk Contacts
        </Button>
        {
          this.state.show ?
            <UploadPanel
              hideDialog={this.hideDialog}
              refreshList={this.props.refreshList} />
            : null
        }
      </>
    )
  }
}
