import React from 'react';
import {Card, CardContent, CardHeader} from '@mui/material';

import {guidelinePoints} from '../../lib/guideline';

const Guide = () =>(
  <div className='sms-parent-container' style={window.isMobile() ? {padding: 8} : {}}>
    <div style={{paddingTop: 64}}>
      {
        guidelinePoints.map((data,index)=>{
          return(
            <Card key={"card"+index}>
              <CardHeader
                title={<p style={{fontSize: 20}} dangerouslySetInnerHTML={{__html: data.ques}} />}
              />
              <CardContent sx={{fontSize: 16}}>
                <div dangerouslySetInnerHTML={{__html: data.ans}} />
              </CardContent>
            </Card>
          )
        })
      }
    </div>
  </div>
)
  
export default Guide
