import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {MobileDatePicker, MobileTimePicker} from '@mui/x-date-pickers';
import moment from 'moment';

import {dateFormat, isNotValidPromoTime} from '../../lib/utils';

export default class ScheduleDateTime extends Component{
  constructor(props){
    super(props);
    const d = props.time ? moment(props.time) : moment();
    this.state={
      date : d,
      time : props.time ? d : null
    }
  }
  handleDateChange = (date) => {console.log(date); this.setState({date})}
  handleTimeChange = (time) => this.setState({time})
  handleDialogDone = () =>{
    let {date,time} = this.state;
    let {isPromotional} = this.props;
    if(time == null){
      alert('Time is not set, Please set the time');
      return;
    }
    date.hour(time.hour()).minute(time.minute())
    const cDate = moment();
    if (date.isBefore(cDate)) {
      alert('Can not schedule to past date!');
      return;
    }
    if(isPromotional && isNotValidPromoTime()){
      alert('Promotional SMS can be sent only between 10:00 AM to 9:00 PM, Please select time accordingly.');
      return;
    } else{
      this.props.handleScheduleSMS(date.valueOf());
    }
  }
  handleDialogCloseNoAction = () => {this.props.closeScheduleDialog();}
  formatDate = (date) => {return dateFormat(date,true);}
  render(){
    return(
      <Dialog
        open
        onClose={this.handleDialogCloseNoAction}
      >
        <DialogTitle>Schedule SMS</DialogTitle>
        <DialogContent style={{paddingTop: 16, display: 'flex', gap: 16}}>
          <MobileDatePicker
            label="Set Date"
            value={moment(this.state.date)}
            minDate={moment()}
            onChange={this.handleDateChange}
            format="DD/MM/YYYY"
            closeOnSelect
            sx={{minWidth: 150}}
          />
          <MobileTimePicker
            label="Set Time"
            value={this.state.time}
            onChange={this.handleTimeChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleDialogCloseNoAction}>Cancel</Button>
          <Button onClick={this.handleDialogDone} variant='contained'>Done</Button>
        </DialogActions>
      </Dialog>
     
    )
  }
}
