import React from 'react';
import {TextField} from '@mui/material';

const Number = ({onChange}) => (
  <div style={ {display: 'inline-block', width: '100%', marginTop: 16} }>
    <TextField
      label="Mobile Number(s)"
      placeholder={ window.isMobile() ? 'Enter comma separated mobile no(s)' : 'Enter 10-digit Mobile Number(s) comma or newline separated' }
      multiline
      required
      rows={ 3 }
      fullWidth={ true }
      variant="standard"
      onBlurCapture={onChange}
      onChange={onChange}
      onKeyPress={event => {
        if (!(
          (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 32 || event.charCode == 44 || event.charCode == 10 || event.charCode == 13)) {
          event.preventDefault();
        } }
      }
    />
  </div>
)
export default Number
