import Api from '../api';
import { GET_ALL_AGENT_DETAILS, AGENT_DETAILS_URL, POST_AGENT_DETAILS,ADD_BALANCE_URL,CHANGE_STATUS_URL,UPDATE_OPERATOR_URL,CHANGE_STATUS,UPDATE_OPERATOR,EDIT_DLT,EDIT_DLT_URL,ADD_BALANCE,ADD_SENDER_ID_URL,ADD_SENDER_ID,CHANGE_DEFAULT_SENDER_ID_URL,CHANGE_DEFAULT_SENDER_ID,ADD_SMS_TYPE,ADD_SMS_TYPE_URL, ADD_BLOCK_NUMBER_URL, ADD_BLOCK_NUMBER,GET_BLOCK_NUMBER, CHANGE_PASSWORD_URL, CHANGE_PASSWORD, DELETE_SENDER_ID_URL, DELETE_SENDER_ID } from '../lib/constant';
import { routeToLogin, getCookie } from '../lib/utils';

export function getAllAgent(){
    const request = Api.get(AGENT_DETAILS_URL, ('bearer '+ getCookie('userToken')));
    return (dispatch) => {
        request.then(({data}) => {
            data.data.institutes  = data.data.institutes.map(institute=>{
                let user = {}
                if (institute && institute.users && institute.users.length>0){
                    user = institute.users[0]
                    for (const property in user) {
                        if (user.hasOwnProperty(property)){
                            institute[`${property}`] = user[`${property}`]
                        }
                      }
                    institute.userId=institute.userName
                    institute.accountId=institute.accountId
                    institute.smsTypeTaken= institute.smstypes
                    institute.info= institute.customerName
                    //name refers to org name and info-- refers to customer name
                }
                return institute
            })
            dispatch({type:GET_ALL_AGENT_DETAILS,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function sumbitInstitute(params){
    const request = Api.post(AGENT_DETAILS_URL, params, ('bearer '+ getCookie('userToken')));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:POST_AGENT_DETAILS,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
export function addBalance(instituteId,params){
    const request = Api.put(ADD_BALANCE_URL.replace('{instId}',instituteId), params, ('bearer '+ getCookie('userToken')));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:ADD_BALANCE,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
export function addSMSType(instituteId,params){
    const request = Api.put(ADD_SMS_TYPE_URL.replace('{instId}',instituteId), params, ('bearer '+ getCookie('userToken')));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:ADD_SMS_TYPE,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
export function changeStatus(instituteId,params){
    const request = Api.put(CHANGE_STATUS_URL.replace('{instId}',instituteId), params, ('bearer '+ getCookie('userToken')));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:CHANGE_STATUS,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
export function updateOperator(instituteId,params) {
    const request = Api.put(UPDATE_OPERATOR_URL.replace('{instId}',instituteId), params, ('bearer '+ getCookie('userToken')));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:UPDATE_OPERATOR,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function editDLT(instituteId,params) {
    const request = Api.put(EDIT_DLT_URL.replace('{instId}',instituteId), params, ('bearer '+ getCookie('userToken')));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:EDIT_DLT,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function addSenderId(instituteId,params){
    const request = Api.put(ADD_SENDER_ID_URL.replace('{instId}',instituteId), params, ('bearer '+ getCookie('userToken')));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:ADD_SENDER_ID,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
export function changeDefaultSenderId(instituteId,params){
    const request = Api.put(CHANGE_DEFAULT_SENDER_ID_URL.replace('{instId}',instituteId), params, ('bearer '+ getCookie('userToken')));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:CHANGE_DEFAULT_SENDER_ID,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
export function addBlockNo(params){
    const request = Api.post(ADD_BLOCK_NUMBER_URL, params, ('bearer '+ getCookie('userToken')));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:ADD_BLOCK_NUMBER,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
export function getBlockNumber(){
    const request = Api.get(ADD_BLOCK_NUMBER_URL, ('bearer '+ getCookie('userToken')));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:GET_BLOCK_NUMBER,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
export function changePassword(instituteId,accountId,params,){
    let url= CHANGE_PASSWORD_URL.replace('{instId}',instituteId)
    url= url.replace('{accountId}',accountId)
    const request = Api.put(url, params, ('bearer '+ getCookie('userToken')));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:CHANGE_PASSWORD,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function deleteSenderId(senderId, instituteId) {
  let url= DELETE_SENDER_ID_URL.replace('{instId}',instituteId)
  url= url.replace('{senderid}',senderId)
  const request = Api.delete(url, `bearer ${ getCookie('userToken') }`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: DELETE_SENDER_ID, payload: data });
    }).catch((data) => {
      if (data.response.status == 404 && data.response.data.success == false) {
        routeToLogin();
      }
    });
  };
}
