import React, {Component} from 'react';
import {Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel} from '@mui/material';

export default class GroupDeleteContact extends Component {
  constructor(){
    super();
    this.state = {
      btnDisabled : false,
      checked : false
    }
    this.callbackHandler = this.callbackHandler.bind(this);
    this.updateCheck = this.updateCheck.bind(this);
  }
  deleteGroup(){
    this.setState({btnDisabled : true});
    this.props.handleDelete(this.state.checked);
  }
  callbackHandler = () => this.setState({btnDisabled:false});
  updateCheck = () => this.setState({checked : !this.state.checked});
  render(){
    let {showDelete,name,closeDelete,bulkDelete} = this.props;
    let {btnDisabled,checked} = this.state;
    return(
      <Dialog open={showDelete}>
        <DialogTitle>Remove {bulkDelete ? "Selected Contact" : name} from Group?</DialogTitle>
        <DialogContent>
          {
            bulkDelete ?
              <FormControlLabel
                control={
                  <Checkbox checked={checked} onChange={this.updateCheck.bind(this)} name="selectAll" />
                }
                label="Remove permanently from contacts?"
              />
              :
              null
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDelete}>
            Cancel
          </Button>
          <Button onClick={this.deleteGroup} disabled={btnDisabled} variant='contained' color="error">
            Delete
          </Button>
          {
            btnDisabled ?
              <CircularProgress size={20} thickness={2} />
              : null
          }
        </DialogActions>
      </Dialog>
    )
  }
}
