import { Map } from 'immutable';

import { GET_OWNED_BLOCKED_CONTACT, BLOCK_CONTACT, DELETE_BLOCK_CONTACT } from '../lib/constant';

const initialState = Map({ blockedContact: {}, addBlockContact: {}, deleteBlockContact: {} });

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OWNED_BLOCKED_CONTACT:
      return { ...state, blockedContact: action.payload };
    case BLOCK_CONTACT:
      return { ...state, addBlockContact: action.payload };
    case DELETE_BLOCK_CONTACT:
      return { ...state, deleteBlockContact: action.payload };
    default:
      return state;
  }
}
