import ReactGA from 'react-ga';
import {getCookie} from './utils';
import {GA_ID, GA_DEBUG} from './constant'

const userId = getCookie('userId');
ReactGA.initialize(GA_ID,{
    debug: GA_DEBUG == 'True' ? true : false,
    gaOptions: userId ? {userId: userId} : {}
});
export const fireTracking = () => {
    const userId = getCookie('userId');
    if(userId){
        ReactGA.set({userId: userId});
    }
    ReactGA.pageview(window.location.pathname);
};

export const GAModalView = (modalName) => {
    ReactGA.modalview(modalName);
}

export const GAEventTracker = (event) =>{
    ReactGA.event(event);
}
