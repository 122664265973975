import React, {Component} from 'react';
import {Add, Clear, Delete, Edit, FileDownload, HighlightOff, Search} from '@mui/icons-material';
import {Button, CircularProgress, Dialog, DialogContent, IconButton, Snackbar, TextField, Tooltip} from '@mui/material';
import {throttle} from 'lodash';
import {connect} from 'react-redux';

import {addUserGroup, deleteGroup, deleteUserGroup, downloadGroupContact, groupDetails, updateGroup} from '../../actions/group';
import {deleteBulkContact, getAllUser, getContactGroup} from '../../actions/user';
import Table from '../../components/Table';
import {GAEventTracker} from '../../lib/googleAnalytics';
import {isGroupAvaible} from '../../lib/utils';
import RecipientList from '../Sms/recipientList';
import GroupDelete from './delete';
import GroupDeleteContact from './deleteContact';
import GroupEdit from './edit';

class Group extends Component {
  constructor(props){
    super(props);
    this.state = {
      openRecipientListDialog : false,
      showDelete : false,
      userList : [],
      individualList : {},
      showEdit : false,
      showContactDelete : false,
      openSnackBar : false,
      autoCompleteValue : '',
      showWait : false,
      showBulkDelete : false,
      deleteList : [],
      deleteAllCheck : false
    }
    this.groupId = props.match.params.groupId;
    this.deleteRequest = false;
    this.deleteContactRequest = false;
    this.groupName = 'Group';
    this.updateRequest = false;
    this.receivedUser = true;
    this.receivedGroup = false;
    this.addContactRequest = false;
    this.deleteContactRequestId = null;
    this.deleteContactRequestName = null;
    this.snackBarMsg = "";
    this.contactList = [];
    this.userList = [];
    this.throttleInput = throttle(this.throttleInput.bind(this),300);
    this.autoComplete = this.autoComplete.bind(this);
    this.receiveDownload = false;
    this.bulkContactRemove = false;
    this.bulkContactGroupRemove = false;
  }
  componentWillMount(){
    if(isGroupAvaible(this.groupId)){
      this.props.groupDetails(this.groupId);
    } else{
      window.location.href = '/sms';
    }
    let {contactDetails} = this.props;
    if(contactDetails && contactDetails.success){
      this.receivedUser = false;
    } else {
      this.props.getAllUser();
    }
  }
  componentWillReceiveProps(nextProps){
    let {gDetails,gDelete,gUpdate,gContactDelete,contactDetails,gAddContact,downContactRes,contactDeleteRes} = nextProps;
    if(this.groupId !== nextProps.match.params.groupId){
      this.groupId = nextProps.match.params.groupId;
      this.receivedGroup = false;
      this.userList = [];
      this.groupName = "";
      this.setState({autoCompleteValue:'',showBulkDelete:false,deleteList:[],deleteAllCheck:false});
      this.props.groupDetails(this.groupId);
    }
    if(!this.receivedGroup &&  gDetails && gDetails.data.id == this.groupId){
      if(gDetails.success){
        let {data} = gDetails;
        this.groupName = data.name;
        this.userList = data.contacts;
        this.setState({userList:data.contacts,showWait:false})
      } else if(gDetails.err){
        alert(gDetails.err.msg);
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }
      this.receivedGroup = true;
    }
    if(this.deleteRequest && gDelete){
      if(gDelete.success){
        alert("Group Deleted Successfully!");
        window.location.href = "/sms";
      } else if(gDelete.err){
        alert(gDelete.err.msg);
        this.refs.groupDelete.callbackHandler();
      } else{
        alert("Something went wrong, Please try again!");
        this.refs.groupDelete.callbackHandler();
      }
      this.deleteRequest = false;
    }
    if(this.updateRequest && gUpdate){
      if(gUpdate.success){
        window.location.reload();
      } else if(gUpdate.err){
        alert(gUpdate.err.msg);
        this.refs.groupEdit.callbackHandler();
      } else{
        alert("Something went wrong, Please try again!");
        this.refs.groupEdit.callbackHandler();
      }
      this.updateRequest = false;
    }
    if(this.deleteContactRequest && gContactDelete){
      let showContactDelete = true;
      let {userList} = this.state;
      if(gContactDelete.success){
        this.receivedGroup = false;
        userList = [];
        this.userList = [];
        this.props.groupDetails(this.props.match.params.groupId);
        this.props.getContactGroup();
        this.props.getAllUser();
        this.snackBarMsg = "Contact Removed from Group Successfully!"
        showContactDelete = false;
      } else if(gContactDelete.err){
        this.snackBarMsg = gContactDelete.err.msg;
        alert(gContactDelete.err.msg);
        this.refs.groupContactDelete.callbackHandler();
      } else{
        this.snackBarMsg = "Something went wrong, Please try again!";
        alert("Something went wrong, Please try again!");
        this.refs.groupContactDelete.callbackHandler();
      }
      this.deleteContactRequest = false;
      this.setState({showContactDelete,openSnackBar : true,userList});
    }
    if(this.bulkContactGroupRemove && gContactDelete){
      let showContactDelete = true;
      let {userList} = this.state;
      if(gContactDelete.success){
        this.receivedGroup = false;
        userList = [];
        this.userList = [];
        this.props.groupDetails(this.props.match.params.groupId);
        this.props.getContactGroup();
        this.props.getAllUser();
        this.snackBarMsg = "Selected Contacts Removed from Group Successfully!"
        showContactDelete = false;
      } else if(gContactDelete.err){
        this.snackBarMsg = gContactDelete.err.msg;
        alert(gContactDelete.err.msg);
        this.refs.groupContactDelete.callbackHandler();
      } else{
        this.snackBarMsg = "Something went wrong, Please try again!";
        alert("Something went wrong, Please try again!");
        this.refs.groupContactDelete.callbackHandler();
      }
      this.bulkContactGroupRemove = false;
      this.setState({showContactDelete,openSnackBar : true,showBulkDelete : false,deleteList : [],userList});
      return;
    }
    if(this.bulkContactRemove && contactDeleteRes){
      let showContactDelete = true;
      let {userList} = this.state;
      if(contactDeleteRes.success){
        this.receivedGroup = false;
        userList = [];
        this.userList = [];
        this.props.groupDetails(this.props.match.params.groupId);
        this.props.getContactGroup();
        this.props.getAllUser();
        this.snackBarMsg = "Selected Contacts Removed from Group and Contacts Permanently!"
        showContactDelete = false;
      } else if(contactDeleteRes.err){
        this.snackBarMsg = contactDeleteRes.err.msg;
        alert(contactDeleteRes.err.msg);
        this.refs.groupContactDelete.callbackHandler();
      } else{
        this.snackBarMsg = "Something went wrong, Please try again!";
        alert("Something went wrong, Please try again!");
        this.refs.groupContactDelete.callbackHandler();
      }
      this.bulkContactRemove = false;
      this.setState({showContactDelete,openSnackBar : true,showBulkDelete : false,deleteList : [],userList});
      return;
    }
    if(this.receivedUser && contactDetails){
      if(contactDetails.data){
        this.receivedUser = false;
      } else if(contactDetails.err){
        this.receivedUser = false;
        alert(contactDetails.err.msg);
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }

    }
    if(this.addContactRequest && gAddContact){
      let showWait = true;
      let {userList} = this.state;
      if(gAddContact.success){
        this.receivedGroup = false;
        userList = [];
        this.userList = [];
        this.props.groupDetails(this.props.match.params.groupId);
        this.props.getContactGroup();
        this.props.getAllUser();
        this.snackBarMsg = "Contact Added into Group Successfully!"
      } else if(gAddContact.err){
        showWait = false;
        this.snackBarMsg = gAddContact.err.msg;
        alert(gAddContact.err.msg);
      } else{
        showWait = false;
        this.snackBarMsg = "Something went wrong, Please try again!";
        alert("Something went wrong, Please try again!");
      }
      this.addContactRequest = false;
      this.setState({openSnackBar : true,showWait,userList});
    }
    if(this.receiveDownload && downContactRes){
      this.receiveDownload = false;
      if(downContactRes.success){
        let a = document.getElementById('download-g-contact');
        a.href = 'http://'+downContactRes.data;
        a.click();
        this.setState({showWait : false});
      } else if(downContactRes.err){
        alert(downContactRes.err.msg);
        this.setState({showWait : false});
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }
    }
  }
  handleRecipientListDialogOpen = e => {
    e.stopPropagation()
    let {contactDetails} = this.props;
    if(contactDetails && contactDetails.data && contactDetails.data.contacts.length){
      this.contactList = contactDetails.data.contacts;
      this.setState({openRecipientListDialog : true});
    }
  }
  handleRecipientListDialogClose = () =>{
    let addContactList = [];
    let showWait = false;
    for(let contact in this.state.individualList){
      addContactList.push(contact);
    }
    if(addContactList.length){
      this.addContactRequest = true;
      showWait = true;
      this.props.addUserGroup({contacts:addContactList},this.groupId);
      GAEventTracker({category : 'Add-Contact-In-Group', action : 'Ok'});
    }
    this.setState({openRecipientListDialog : false,individualList : {},showWait});
  }
  handleRecipientListDialogCloseWithNoAction = () => {
    this.setState({openRecipientListDialog : false,individualList : {}});
    GAEventTracker({category : 'Add-Contact-In-Group', action : 'Cancel'});
  }
  individualListCallback = (individualList) => this.setState({individualList});
  openDelete = (e) => {
    e.stopPropagation()
    this.setState({showDelete : true});
  }
  closeDelete = () => this.setState({showDelete : false});
  openDeleteContact = (e, userId,name) => {
    e.stopPropagation()
    this.deleteContactRequestId = userId;
    this.deleteContactRequestName = name;
    this.setState({showContactDelete : true});
  }
  closeDeleteContact = () => this.setState({showContactDelete : false});
  openEdit = (e) => {
    e.stopPropagation()
    this.setState({showEdit : true});
  }
  closeEdit = () => this.setState({showEdit : false});
  handleDelete = () => {
    this.deleteRequest = true;
    GAEventTracker({category : 'Group', action : 'Delete Group'});
    this.props.deleteGroup(this.groupId);
  }
  handleDeleteContact = (contactRemove) => {
    const {showBulkDelete,deleteList} = this.state;
    if(showBulkDelete){
      if(contactRemove){
        this.bulkContactRemove = true;
        this.props.deleteBulkContact({contacts : deleteList});
      } else{
        this.bulkContactGroupRemove = true;
        this.props.deleteUserGroup(this.groupId,{contacts:deleteList})
      }
    } else{
      this.deleteContactRequest = true;
      this.props.deleteUserGroup(this.groupId,{contacts:[this.deleteContactRequestId]})
    }
    GAEventTracker({category : 'Group', action : 'Delete Contact from Group'});
  }
  handleEdit = (name) => {
    this.updateRequest = true;
    GAEventTracker({category : 'Group', action : 'Rename Group'});
    this.props.updateGroup(this.groupId,{name});
  }
  handleSnackBarClose = () => this.setState({openSnackBar : false});
  autoComplete = (e) => {
    this.setState({autoCompleteValue:e.target.value});
    this.throttleInput(e.target.value);
  }
  throttleInput = (val) => {
    let userList = this.userList.filter(data => {return (data.name.toLowerCase().indexOf(val.toLowerCase()) > -1 || data.mobile.indexOf(val) > -1);});
    this.setState({userList});
  }
  removeAutoCompleteValue = (e) => {
    e.stopPropagation()
    this.setState({autoCompleteValue : '',userList:this.userList});
  }
  downloadContacts = (e) => {
    e.stopPropagation()
    this.receiveDownload = true;
    this.setState({showWait : true});
    this.props.downloadGroupContact(this.groupId);
    GAEventTracker({category : 'Group', action : 'Download Contacts'});
  }
  showBulkDelete = (e) => {
    e.stopPropagation()
    this.setState({showBulkDelete : true});
  }
  handleBulkDelete = (e) => {
    e.stopPropagation()
    if(this.state.deleteList.length){
      this.setState({showContactDelete : true});
    } else{
      alert("Select Contacts to Delete!");
    }
  }
  clearBulkDelete = (e) => {
    e.stopPropagation()
    this.setState({showBulkDelete : false,deleteList : [],deleteAllCheck:false});
  }
  updateCheck = (id) => {
    let {deleteList,userList} = this.state;
    let idIndex = deleteList.indexOf(id);
    if(idIndex == -1){
      deleteList.push(id)
    } else{
      deleteList.splice(idIndex,1);
    }
    this.setState({deleteList,deleteAllCheck:userList.length == deleteList.length});
  }
  updateCheckAll = () =>{
    let {deleteAllCheck,userList} = this.state;
    let deleteList = [];
    if(deleteAllCheck){
      this.setState({deleteList,deleteAllCheck : false});
    } else{
      for(var delContact of userList){
        deleteList.push(delContact.id);
      }
      this.setState({deleteList,deleteAllCheck : true});
    }
  }
  render() {
    const rightIconButtons = (data) => {
      const {id: userId, name} = data
      return(
        <Tooltip title="Remove from Group">
          <IconButton aria-label="Remove" onClick={(e) => this.openDeleteContact(e, userId, name)}>
            <Delete />
          </IconButton>
        </Tooltip>
      )
    }
    let {showWait,autoCompleteValue,userList,openRecipientListDialog,individualList,showDelete,showEdit,showContactDelete,openSnackBar,showBulkDelete,deleteList} = this.state;
    const {contactDetails} = this.props;
    const isMobile = window.isMobile();
    return (
      <>
        <div className='sms-parent-container' style={isMobile ? {padding: 8} : {}}>
          <div className='sms-small-container'>
            <div style={{display: 'flex', alignItems: 'start', gap: 8}}>
              <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                <p style={{fontSize: 16, flex: 'none'}}>{this.groupName+`${this.userList.length ? (" ("+this.userList.length+")" ): ""}`}</p>
                <div style={{display: 'flex', alignItems: 'center', gap: 8, width: isMobile ? 300 : 350}}>
                  <Search onClick={e => e.stopPropagation()} />
                  <TextField
                    label={isMobile ? "Search Contact" : "Search Contact By Name/Number"}
                    size="small"
                    fullWidth
                    variant="standard"
                    value={autoCompleteValue}
                    onChange={this.autoComplete.bind(this)}
                  />
                  {
                    autoCompleteValue ?
                      <Tooltip title="Clear Search">
                        <IconButton aria-label='Clear Search' onClick={this.removeAutoCompleteValue}>
                          <HighlightOff />
                        </IconButton>
                      </Tooltip>
                      : null
                  }
                </div>
              </div>
              {!isMobile && <div style={{display: 'flex', alignItems: 'center', gap: 8, marginLeft: 'auto', justifyContent: 'end', flexWrap: 'wrap'}}>
                <Button onClick={this.handleRecipientListDialogOpen} variant='outlined'>Add Contacts in Group</Button>
                <Button onClick={this.openDelete} variant='outlined'>Delete Group</Button>
                <Button onClick={this.openEdit} variant="outlined">Edit Group</Button>
              </div>}
            </div>
            {isMobile &&
              <div style={{display: 'flex', alignItems: 'center', gap: 8, marginLeft: 'auto', justifyContent: 'end', flexWrap: 'wrap'}}>
                <IconButton aria-label="Add" onClick={this.handleRecipientListDialogOpen}>
                  <Add />
                </IconButton>
                <IconButton aria-label="Delete" onClick={this.openDelete}>
                  <Delete />
                </IconButton>
                <IconButton aria-label="Edit" onClick={this.openEdit}>
                  <Edit />
                </IconButton>
              </div>
            }
            {
              this.userList.length ?
                <>
                  {
                    userList.length ?
                      <>
                        <Table
                          column={[
                            {name: 'Name', value: 'name'},
                            {name: 'Mobile', value: 'mobile'},
                            ...(showBulkDelete ? [] : [{name: 'Actions', value: 'id', cell: val => rightIconButtons(val)}])
                          ]}
                          data={userList}
                          enableCheckbox={showBulkDelete}
                          handleSelect={this.updateCheck}
                          selected={deleteList}
                          onSelectAllClick={this.updateCheckAll}
                          showPagination
                          defaultPageSize={100}
                          actionEl={<div style={{display: 'flex', alignItems: 'center', gap: 8, marginLeft: 'auto', flexWrap: 'wrap', justifyContent: 'end'}}>
                            {!showBulkDelete ?
                              <>
                                <Button startIcon={<Delete onClick={e => e.stopPropagation()} />} onClick={this.showBulkDelete} variant="outlined">
                                    Bulk Remove
                                </Button>
                                {this.userList.length && !isMobile ?
                                  <Button startIcon={<FileDownload onClick={e => e.stopPropagation()} />} onClick={this.downloadContacts} variant='outlined'>
                                      Download Contacts
                                  </Button>
                                  : null
                                }
                              </> :<>
                                <Button startIcon={<Clear />} onClick={this.clearBulkDelete} variant='outlined'>Clear Bulk Remove</Button>
                                <Button startIcon={<Delete />} onClick={this.handleBulkDelete} variant='outlined'>Confirm Remove</Button>
                              </>}
                          </div>}
                        />
                      </>
                      :
                      <p style={{margin:30,fontSize:16}}>No {this.props.label} Available with searched keyword!</p>
                  }
                  <a href="" id="download-contact" style={{display:'none'}}></a>
                </>
                :
                <div>
                  {
                    this.receivedGroup ?
                      <div>
                        {
                          contactDetails && contactDetails.success && contactDetails.data && contactDetails.data.contacts.length == 0 ?
                            <p style={{fontSize:20,marginTop:36}}>
                              You haven't added any contacts Yet! Please add them using "Add Contacts" in "Contacts" Tab and Add them in Desired Groups!
                            </p>
                            :
                            <p style={{fontSize:20,marginTop:36}}>Currently no contacts are added in Group!</p>
                        }
                      </div>
                      :
                      <CircularProgress size={80} thickness={5} />
                  }
                </div>
            }
          </div>
        </div>
        {
          openRecipientListDialog ?
            <RecipientList
              open={openRecipientListDialog}
              handleRecipientListDialogClose={this.handleRecipientListDialogClose}
              handleRecipientListDialogCloseWithNoAction={this.handleRecipientListDialogCloseWithNoAction}
              individualList={individualList}
              individualListCallback={this.individualListCallback}
              userList = {this.contactList.filter(contact=>!this.userList.some(c=>c.id==contact.id))} />
            : null
        }
        {
          showDelete ?
            <GroupDelete
              ref="groupDelete"
              showDelete = {showDelete}
              handleDelete={this.handleDelete}
              closeDelete={this.closeDelete} />
            : null
        }
        {
          showEdit ?
            <GroupEdit
              ref="groupEdit"
              showEdit = {showEdit}
              handleEdit={this.handleEdit}
              name={this.groupName}
              closeEdit={this.closeEdit} />
            : null
        }
        {
          showContactDelete ?
            <GroupDeleteContact
              ref="groupContactDelete"
              showDelete={showContactDelete}
              handleDelete={this.handleDeleteContact}
              bulkDelete = {showBulkDelete}
              name={this.deleteContactRequestName}
              closeDelete={this.closeDeleteContact} />
            : null
        }
        {
          openSnackBar ?
            <Snackbar
              open={openSnackBar}
              message={this.snackBarMsg}
              autoHideDuration={4000}
              onClose={this.handleSnackBarClose} />
            : null
        }
        {
          showWait ?
            <Dialog open>
              <DialogContent sx={{width: 100}}>
                <CircularProgress />
              </DialogContent>
            </Dialog>
            : null
        }
      </>
      
    )
  }
}
function mapStateToProps(state){
  let {group} = state;
  return {
    gDetails : group.details,
    gDelete : group.deleteGroup,
    gUpdate : group.updateGroup,
    gContactDelete : group.deleteUser,
    gAddContact : group.addUser,
    contactDetails : state.userResponse.userResponse,
    contactDeleteRes : state.userResponse.bulkDeleteResponse,
    downContactRes : group.downloadContacts
  };
}

export default connect(
  mapStateToProps,
  {groupDetails, getAllUser, deleteGroup, updateGroup, deleteUserGroup, addUserGroup,downloadGroupContact, deleteBulkContact, getContactGroup}
)(Group);
