import Api from '../api';
import { GET_ALL_USER, USER_URL, ADD_USER, DELETE_USER, UPDATE_USER, BULK_CONTACT, BULK_CONTACT_URL, DOWNLOAD_CONTACT, DOWNLOAD_CONTACT_URL, DELETE_BULK_CONTACT, GET_CONTACT_GROUP_URL, GET_CONTACT_GROUP } from '../lib/constant';
import { routeToLogin, getCookie,isWebWorkerSupported } from '../lib/utils';
import PseudoWorker from "pseudo-worker";
import task from '../lib/task'

let worker;
if(isWebWorkerSupported()) {
  worker = new window.Worker(task);
}else {
  worker = new PseudoWorker(task);
}

export function getAllUser(smsUIReq,noAPICall,apiData){
    if(smsUIReq && noAPICall){
        return (dispatch) => {
            worker.postMessage({data: apiData});
            worker.onmessage = (e) => {
                dispatch({type: GET_ALL_USER, payload: e.data.data})
            }
            // worker.addEventListener('message', function(e) {
            //     dispatch({type: GET_ALL_USER, payload: e.data.data})
            // }, false);
        }
    }
    const request = Api.get(USER_URL, ('bearer '+ getCookie('userToken')));
    return (dispatch) => {
        request.then(({data}) => {
          if(smsUIReq && data.success){
              worker.postMessage({data: data});
          }else {
              dispatch({type:GET_ALL_USER,payload:data})
          }
          worker.onmessage = (e) => {
              dispatch({type: GET_ALL_USER, payload: e.data.data})
          }
        //   worker.addEventListener('message', function(e) {
        //       dispatch({type: GET_ALL_USER, payload: e.data.data})
        //   }, false);
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function addUser(params){
    const request = Api.post(USER_URL, params, 'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:ADD_USER,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function deleteUser(userId){
    let url = USER_URL + '/' + userId;
    const request = Api.delete(url,'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:DELETE_USER,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function updateUser(params,userId){
    let url = USER_URL + '/' + userId;
    const request = Api.put(url, params, 'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:UPDATE_USER,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function uploadBulkContact(params){
    const request = Api.post(BULK_CONTACT_URL, params, 'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:BULK_CONTACT,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function downloadContact(){
    const request = Api.get(DOWNLOAD_CONTACT_URL,'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:DOWNLOAD_CONTACT,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function deleteBulkContact(params){
    const request = Api.deleteWithData(BULK_CONTACT_URL, params, 'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:DELETE_BULK_CONTACT,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}

export function getContactGroup(){
    const request = Api.get(GET_CONTACT_GROUP_URL,'bearer '+getCookie('userToken'));
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:GET_CONTACT_GROUP,payload:data})
        }).catch((data) => {
            if(data.response.status == 404 && data.response.data.success == false){
                routeToLogin();
            }
        })
    };
}
