import React from 'react';
import {TextField} from '@mui/material';

const Number = ({onChange}) => (
  <TextField
    label={window.isMobile() ? "Enter comma seperated mobile no(s)" : "Enter 10-digit Mobile Number(s) comma or newline separated"}
    multiline
    rows={3}
    size="small"
    fullWidth
    defaultValue=""
    onChange={onChange}
    variant="standard"
    onKeyPress={event => {
      if (!(
        (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 32 || event.charCode == 44 || event.charCode == 10 || event.charCode == 13)) {
        event.preventDefault();
      } }
    }
  />
)
export default Number
