import React, {Component} from 'react';
import {Email, Group, LocationCity, Person, Phone} from '@mui/icons-material';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';

import {GAEventTracker} from '../../lib/googleAnalytics';
import {emailValidator} from '../../lib/utils';
import Select from '../Select';

export default class UserEdit extends Component {
  constructor(props){
    super(props);
    let {details} = props;
    this.state = {
      name : details.name,
      mobile : details.mobile,
      email : details.email ? details.email : '',
      address : details.address ? details.address : '',
      editBtn : details.name && details.mobile.length == 10,
      emailError : false,
      btnDisabled : false,
      selectBoxValues : details.groupList || []
    };
    this.callbackHandler = this.callbackHandler.bind(this);
  }
  handleNameInput = (name) => {
    if(name && this.state.mobile.length == 10){
      this.setState({name, editBtn : true});
    } else if(name == ''){
      this.setState({name, editBtn : false});
    } else{
      this.setState({name});
    }
  }
  handleMobileInput = (mobile) => {
    if(mobile && mobile.length == 10 && this.state.name){
      this.setState({mobile, editBtn : true});
    } else if(mobile == '' || mobile.length < 10){
      this.setState({mobile, editBtn : false});
    } else{
      this.setState({mobile});
    }
  }
  handleEditBtn = () => {
    if(this.state.email && !emailValidator(this.state.email)){
      this.setState({emailError : true});
    } else {
      let {name, mobile, email, address,selectBoxValues} = this.state;
      this.setState({emailError : false, btnDisabled : true});
      if(selectBoxValues.length){
        GAEventTracker({category : 'Edit-Contact', action : 'Add to Group', value : selectBoxValues.length});
      }
      this.props.handleEditUser({name, mobile, email, address, id : this.props.details.id, groups :selectBoxValues});
      GAEventTracker({category : 'Edit-Contact', action : 'Save'});
    }
  }
  handleChange = (event, index, selectBoxValues) => {
    this.setState({selectBoxValues});
  }
  callbackHandler = () => this.setState({btnDisabled:false});
  handleIconClick = (e) => {e.stopPropagation()}
  render(){
    let {closeEditUser} = this.props;
    let {editBtn,btnDisabled,selectBoxValues,name,mobile,address,email} = this.state;
    
    return(
      <Dialog open onClose={closeEditUser}>
        <DialogTitle>{`Edit ${this.props.label}`}</DialogTitle>
        <DialogContent dividers>
          <div style={{display: 'flex', alignItems: 'center', gap: 16, flexDirection: 'column', width: window.isMobile() ? 300 : 500}}>
            <div style={{display: 'flex', alignItems: 'center', gap: 8, width: 'inherit'}}>
              <Person onClick={this.handleIconClick} />
              <TextField
                value={name}
                onChange={(e)=>{this.handleNameInput(e.target.value)}}
                label="Name"
                required
                size="small"
                fullWidth
                variant="standard"
                onKeyPress={(event)=>{
                  if(!((event.charCode >= 97 && event.charCode <= 122) ||
                      (event.charCode >= 48 && event.charCode <= 57) ||
                      (event.charCode >= 65 && event.charCode <= 90) ||
                      event.charCode == 32 ||
                      event.charCode == 46 ||
                      event.charCode == 40 ||
                      event.charCode == 41 ||
                      event.charCode == 44 ||
                      event.charCode == 45 ||
                      event.charCode == 95)){
                    event.preventDefault()
                  }}}
              />
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: 8, width: 'inherit'}}>
              <Phone onClick={this.handleIconClick}  />
              <TextField
                label="Mobile Number"
                required
                type="number"
                maxLength="10"
                size="small"
                fullWidth
                value={mobile}
                variant="standard"
                onChange={(e)=>{this.handleMobileInput(e.target.value)}}
              />
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: 8, width: 'inherit'}}>
              <Group onClick={this.handleIconClick}  />
              <Select
                multiple
                value={selectBoxValues}
                onChange={this.handleChange}
                label="Select One/More Groups"
                sx={{minWidth: 470}}
                options={this.props.groupList.map(el => ({value: el.id, name: el.name}))}
              />
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: 8, width: 'inherit'}}>
              <Email onClick={this.handleIconClick}  />
              <TextField
                value={email}
                onChange={(e)=>{this.setState({email : e.target.value})}}
                label="Email"
                size='small'
                fullWidth
                variant="standard"
                error={!!this.state.emailError}
                errorText={this.state.emailError ? 'Please enter correct Email Id' : ''}
              />
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: 8, width: 'inherit'}}>
              <LocationCity onClick={this.handleIconClick}  />
              <TextField
                value={address}
                onChange={(e)=>{this.setState({address : e.target.value})}}
                floatingLabelText="Address"
                size="small"
                fullWidth
                variant="standard"
                label="Address" />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditUser}>
            Cancel
          </Button>
          <Button disabled={!editBtn || btnDisabled} onClick={this.handleEditBtn} variant='contained'>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
