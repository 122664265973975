import axios from 'axios';
//put timeout
let refreshCachedId = Math.floor(Math.random() * 100);
class Api {
  static headers() {
    let headers = {

    }
    return headers;
  }

  static get(route, header, param = {}) {
      if(header){
          axios.defaults.headers.common['authorization'] = header;
      }
      const params = {
        refreshCachedId, ...param
      }
      return new Promise((resolve, reject) => {
          return axios.get(route,{
            params
          }).then((data)=>{
              resolve(data);
          }).catch((err) => {
            refreshCachedId = refreshCachedId + 1;
              reject(err);
          });
      });
  }

  static put(route, params, header) {
      if(header){
          axios.defaults.headers.common['authorization'] = header;
      }
      return new Promise((resolve,reject) =>{
          return axios.put(route, params).then((data)=>{
            refreshCachedId = refreshCachedId + 1;
              resolve(data);
          }).catch((err)=>{
            refreshCachedId = refreshCachedId + 1;
              reject(err);
          });
      });
  }

  static post(route, params, header) {
      if(header){
          axios.defaults.headers.common['authorization'] = header;
      }
      return new Promise((resolve,reject) =>{
          return axios.post(route, params).then((data)=>{
            refreshCachedId = refreshCachedId + 1;
              resolve(data);
          }).catch((err)=>{
            refreshCachedId = refreshCachedId + 1;
              reject(err);
          });
      });
  }

  static delete(route, header) {
      if(header){
          axios.defaults.headers.common['authorization'] = header;
      }
      return new Promise((resolve,reject) =>{
          return axios.delete(route).then((data)=>{
            refreshCachedId = refreshCachedId + 1;
              resolve(data);
          }).catch((err)=>{
            refreshCachedId = refreshCachedId + 1;
              reject(err);
          });
      });
  }

  static deleteWithData(route, params, header) {
      if(header){
          axios.defaults.headers.common['authorization'] = header;
      }
      return new Promise((resolve,reject) =>{
          return axios.delete(route,{data:params}).then((data)=>{
            refreshCachedId = refreshCachedId + 1;
              resolve(data);
          }).catch((err)=>{
            refreshCachedId = refreshCachedId + 1;
              reject(err);
          });
      });
  }

  static xhr(route, params, verb) {

  }
}
export default Api
