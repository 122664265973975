import {Map} from 'immutable';

import {GET_CREDIT_HISTORY} from '../lib/constant';

const initialState = Map({getCreditHistoryResponse: {}})

export default function(state = initialState , action ) {
  switch(action.type){
  case GET_CREDIT_HISTORY :
    return {...state,getCreditHistoryResponse:action.payload};
  default:
    return state;
  }
}
