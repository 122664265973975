import React from 'react'
import {FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select} from '@mui/material'

const TemplateList = ({selectedTemplate, templateList, onChange, showMsg = false}) => {
  return (
    <FormControl size="small" required sx={{width: 300}} variant='standard'>
      <InputLabel id="selectedTemplate-label">Select Template</InputLabel>
      <Select
        labelId="selectedTemplate-label"
        id="selectedTemplate"
        value={selectedTemplate}
        label="Select Template"
        onChange={onChange}
        MenuProps={{sx: {width: 400}}}
        renderValue={id => templateList.find(el => el.id === id)?.name}
        required
      >
        {templateList.map(el => (
          <MenuItem
            key={el.id}
            value={el.id}
            sx={{display: 'block'}}
          >
            <ListItemText primary={el.name} />
            <span style={{fontSize: 14, whiteSpace: 'initial'}}>{el.text}</span>
          </MenuItem>
        ))}
      </Select>
      {showMsg && !selectedTemplate && <FormHelperText sx={{ml: 0}}>Please select template to send SMS.</FormHelperText>}
    </FormControl>
  )
}

export default TemplateList
