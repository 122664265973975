/* eslint-disable max-len */
import React from 'react';
import {Helmet} from "react-helmet";

import apiLogo from '../../static/assets/img/development.png';
import {dashboardLogo, deliveryLogo, dndFilterLogo, freeLogo, smsAPILogo, supportLogo} from './WebLogo';
import Footer from './footer';
import Header from './header';

const Api = () => {
  const brandName = process.env.REACT_APP_BRAND_NAME
  return(
    <div>
      <Helmet>
        <title>Bulk SMS API | EdumarcSMS</title>
        <meta name="keywords" content="Bulk SMS Service, free sms api, integrate sms api, SMS API gateway, sms api for website, bulk sms api, sms api php, send sms using php, sms php script, java sms code, send sms using java, java api for bulk sms, .net code for sms, .net api to send sms" />
        <meta name="description" content="Easily integrate SMS API to any application and start sending sms in minutes, sample codes for java, php, .net, node js, javascript." />
      </Helmet>
      <Header headerClass="activePurple" activeMenu="API Integration" />
      <div className="promotiona-section container">
        <div className="svg-icon">
          <img src={apiLogo} alt="API Integration" />
        </div>
        <h1>Secure, Scalable & Powerful Bulk SMS APIs</h1>
        <h2>{brandName} APIs allow you to send bulk SMS offers, trigger transactional updates and OTPs, receive inbound SMS, pull reports, manage contacts & more.</h2>
        <div className="promotional-features">
          <p>Benefist you will get with our system</p>
          <div className="promotional-features-section">
            <div className="list">
              <div className="box-list">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: freeLogo}}></div>
                  <p>No Extra Cost</p>
                </div>
                <p>Get it Free along with our Smart SMS Platform along with Contact Management System. No hidden charges.</p>
              </div>
            </div>
            <div className="list">
              <div className="box-list">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: supportLogo}}></div>
                  <p>Easy to integrate</p>
                </div>
                <p>Easily integrate into your systems and start sending SMS in minutes. No need to have a separate account</p>
              </div>
            </div>
            <div className="list">
              <div className="box-list">
                <div className="main">
                  <div className="svg-icon" dangerouslySetInnerHTML={{__html: deliveryLogo}}></div>
                  <p>Best in-class Delivery</p>
                </div>
                <p>Our intelligent SMS gateway routing assures best-in-class delivery.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="promotional-features-section">
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: dashboardLogo}}></div>
                <p>Dashboard</p>
              </div>
              <p>Dashboard is designed to provide graph representation of monthly usage, recent activities, SMS balances and many more.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: dndFilterLogo}}></div>
                <p>Reports</p>
              </div>
              <p>Track and download SMS campaign reports with full insights in each sms.</p>
            </div>
          </div>
          <div className="list">
            <div className="box-list">
              <div className="main">
                <div className="svg-icon" dangerouslySetInnerHTML={{__html: smsAPILogo}}></div>
                <p>Extra Capabilities</p>
              </div>
              <p>Get reports and many other capabilities using our seamless APIs which can be integrated directly into your system.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Api
