import React from 'react'

const ErrorFallback = ({error}) => {
  return (
    <>
      <div role="alert" style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 16}}>
        <p>Something went wrong: </p>
        <pre style={{color: "red"}}>{error.message}</pre>
        <p>Please refresh the Page!</p>
      </div>
    </>
  );
}

export default ErrorFallback
