import React from 'react';
import {Card, CardContent, Grid, Skeleton} from '@mui/material';
import {isEmpty} from 'lodash';
import {connect} from 'react-redux';

import ErrorMsg from './ErrorMsg';
import Heading from './Heading';
import SubHeading from './SubHeading';

const SenderIDList = ({dashboardData}) => {
  if (isEmpty(dashboardData)) {
    return <Skeleton variant="rounded" width={'100%'} height={205} />
  }

  return (
    <Card>
      <CardContent>
        <Heading>Header</Heading>
        <SubHeading>List of Headers</SubHeading>
        <div className='dashboard-card-container overflow-auto'>
          {dashboardData.success && dashboardData.data?.senderIds ?
            <Grid container spacing={1} style={{marginTop: 0}}>
              {dashboardData.data.senderIds.map(el => <Grid item xs={6} key={el} style={{fontSize: 16}}>{dashboardData.data.defaultSenderId === el ? <b>{el}</b> : el}</Grid>)}
            </Grid> :
            <ErrorMsg msg={dashboardData?.err?.msg} />
          }
        </div>
      </CardContent>
    </Card>
  )

}

function mapStateToProps(state){
  return{
    // dashboardData : state.dashboard.dashboard
    dashboardData: state.smsResponse.smsDetailsResponse,
  }
}

export default connect(mapStateToProps)(SenderIDList)
