import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';


import Table from '../../components/Table';

const SmsReport = ({closeAllContacts, singleHistory}) => {
  const returnDate = (updatedAt)=>{
    let res=  "---";
    if(updatedAt){
      res = new Date(updatedAt).toLocaleString();
    }
    return res;
  };
  return (
    <Dialog
      open
      onClose={closeAllContacts}
    >
      <DialogTitle>Delivery Report</DialogTitle>
      <DialogContent>
        <Table
          column={[
            {name: 'Contact Number', value: 'mobile'},
            {name: 'Status', value: 'status', cell : (val, row) => <>{val}{val == "failed" ? <span> - {row.errorCode}</span> : ""}</>},
            {name: 'Error', value: 'errorCode', cell : val => val ?? '--'},
            {name: 'Updated Time', value: 'deliveryAt', cell: val => <div style={{whiteSpace: 'normal',wordWrap: 'break-word'}}>{returnDate(val)}</div>}
          ]}
          serialNumber
          data={singleHistory.list}
          showPagination
          pageOptions={[15]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAllContacts}>
            Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SmsReport
