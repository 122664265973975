import React, {Component} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';

import Select from '../../components/Select';

export default class AddSMSType extends Component {
  constructor(props){
    super(props);
    this.state={
      smsTypeTaken : props.data.smsTypeTaken,
      defaultSmsType : 1
    }
  }
  addSMSType = () =>{
    const {smsTypeTaken,defaultSmsType} = this.state;
    if(smsTypeTaken.length == 0){
      alert("Please select SMS Type");
      return;
    }
    if(defaultSmsType == 1){
      alert("Please select default SMS Type");
      return;
    }
    this.props.addSMSType({
      smsType : smsTypeTaken,
      defaultSmsType
    });
  }
  handleChangeSMSType = (e) => this.setState({smsTypeTaken: e.target.value});
  handleChangeSMSDefaultType = (e) => {this.setState({defaultSmsType: e.target.value});}
  render(){
    const {defaultSmsType,smsTypeTaken} = this.state;
    
    return(
      <Dialog
        open={true}
        onClose={this.props.closeAddSMSType}
      >
        <DialogTitle>
          Add More SMS Types
        </DialogTitle>
        <DialogContent>
          <div style={{display: 'flex', gap: 16, flexDirection: 'column', marginTop: 16}}>
            <Select
              multiple={true}
              fullWidth={true}
              label="SMS Type"
              required
              value={smsTypeTaken}
              onChange={this.handleChangeSMSType}
              options={["PROMO", "TRANS", "OTP"]}
            />
            <Select
              fullWidth={true}
              label="Default SMS Type"
              value={defaultSmsType}
              required
              onChange={this.handleChangeSMSDefaultType}
              sx={{minWidth: 200}}
              options={[{value:"PROMO", name:'Promotional'}, {value: "TRANS", name: 'Transactional'}, {value: "OTP", name: 'OTP'}]}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeAddSMSType}>
            Cancel
          </Button>
          <Button onClick={this.addSMSType} variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
