import React from 'react';
import {FormControlLabel, Switch} from '@mui/material';
import {func, string} from 'prop-types';

import {GAEventTracker} from '../../lib/googleAnalytics';

const SmsFlashOptionButton = ({isSmsFlashEnabledFn, pageType}) => {
  const onToggle = (e,isToggleOn) => {
    isSmsFlashEnabledFn(isToggleOn);
    GAEventTracker({category : 'Flash-SMS', action : 'Toggle Action', label: pageType});
  }

  return(
    <div style={{display: 'inline-block'}}>
      <FormControlLabel
        control={<Switch onChange={onToggle} />}
        label="Enable Flash SMS"
      />
    </div>
  )
}

SmsFlashOptionButton.propTypes = {
  isSmsFlashEnabledFn: func,
  pageType: string
};

export default SmsFlashOptionButton
