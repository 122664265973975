import React, {Component} from 'react';
import {Check, FileDownload, HighlightOff, Search} from '@mui/icons-material';
import {Alert, Button, CircularProgress, IconButton, TextField, Tooltip} from '@mui/material';
import {MobileDateRangePicker, SingleInputDateRangeField} from '@mui/x-date-pickers-pro';
import {isEqual} from 'lodash';
import moment from 'moment';
import {connect} from 'react-redux';

import {getSMSReportExportJobID, getSMSReportPolling} from '../../actions/smsHistory';
import Select from '../../components/Select';
import {dateFormat} from '../../lib/utils';
import Heading from '../NewDashboard/Heading';

const langMap = {TRANS: 'Transactional', PROMO: 'Promotional', OTP: 'OTP', ALL: 'ALL'};
const JOB_ID_KEY = 'epadhaiExportJobID'
const JOB_URL_KEY = 'epadhaiExportJobURL'

class Filter extends Component {
  constructor(props) {
    super(props);
    const startDate = moment().subtract(1, 'months')
    const endDate = moment()
    this.state = {
      smsType: 'ALL',
      startDate,
      endDate,
      mobileNo: '',
      senderIds: props.smsDetails && props.smsDetails.senderIds ? props.smsDetails.senderIds : [],
      showWait: false,
      jobId: window.localStorage.getItem(JOB_ID_KEY) || null,
      isJobCompleted: !!window.localStorage.getItem(JOB_URL_KEY) || false
    };
    this.senderIds = this.state.senderIds
    this.date = {startDate, endDate}
    this.poll = null
  }

  componentDidMount() {
    if (this.state.jobId) {
      this.pollReport(this.state.jobId)
    }
  }

  componentWillUnmount() {
    if (this.poll) {
      this.poll.cancelPoll()
    }
  }

  componentWillReceiveProps(nextProps) {
    let {smsHistoryExportJobID} = nextProps;
    if(!this.state.jobId && smsHistoryExportJobID){
      if(smsHistoryExportJobID.success){
        this.setState({jobId: smsHistoryExportJobID.data.jobId, showWait: false, isJobCompleted: false})
        window.localStorage.setItem(JOB_ID_KEY, smsHistoryExportJobID.data.jobId);
        window.localStorage.removeItem(JOB_URL_KEY);
        this.pollReport(smsHistoryExportJobID.data.jobId)
      } else if(smsHistoryExportJobID.err){
        alert(smsHistoryExportJobID.err.msg);
        this.setState({showWait: false})
      } else{
        alert("Something went wrong!");
        window.location.reload();
      }
    }
  }

  pollReport = (jobId) => {
    this.poll = getSMSReportPolling(jobId)
    this.poll.poll().then(response => {
      if(response?.data?.status === 'completed') {
        window.localStorage.setItem(JOB_URL_KEY, response.data.generatedUrl)
        const a = document.getElementById('download-full-report');
        if (a) {
          this.setState({isJobCompleted: true})
        } else {
          throw new Error("element not found")
        }
      } else {
        alert('Report export failed, please try again!')
      }
      window.localStorage.removeItem(JOB_ID_KEY);
      this.setState({jobId: null});
    })
      .catch(err=>{
        console.log(err);
      })
  }

  handleExport = () => {
    const a = document.getElementById('download-full-report');
    a.href = `${ window.localStorage.getItem(JOB_URL_KEY) }`;
    a.click();
    window.localStorage.removeItem(JOB_URL_KEY);
    this.setState({isJobCompleted: false})
  }

  handleChange = (event) => {
    this.setState({smsType: event.target.value}, () => this.props.applyFilter(this.state))
  }

  handleDateChange = (date) => {
    this.setState({startDate: date[0], endDate: date[1]})
  }

  formatDate = date => (dateFormat(date, true))

  isSameDate(a, b) {
    return Math.abs(a - b) < (1000 * 3600 * 24) && a.day() === b.day();
  }

  downloadReportsAsExcel = (e) => {
    e.stopPropagation()
    const {startDate, endDate, mobileNo, senderIds} = this.state;
    var increasedEndDate24hour = endDate
    if(this.isSameDate(moment(startDate),moment(endDate)) && !this.isSameDate(moment(endDate),moment())){
      increasedEndDate24hour = moment(endDate);
      increasedEndDate24hour.hour(increasedEndDate24hour.hour()+1);
    }

    this.setState({showWait: true, jobId: null});
    window.localStorage.removeItem(JOB_ID_KEY);
    this.props.getSMSReportExportJobID({
      startDate:startDate.startOf('day').valueOf(),
      endDate: endDate.endOf('day').valueOf(),
      mobileNo: mobileNo || 'NA',
      senderIds:senderIds && senderIds.length>0?senderIds: []
    })
  }

  handleMobileNoChange = (event) => this.setState({mobileNo: event.target.value})

  handleSenderIdChange = (event) => {
    this.setState({senderIds:event.target.value});
  }
  //TODO: change the design of Date picker

  render() {
    const {smsType, startDate, endDate, mobileNo, senderIds, showWait, jobId, isJobCompleted} = this.state;
    const {smsDetails} = this.props;
    const isMobile = window.isMobile();
    return (
      <>
        <Heading>Refine Your SMS Reports</Heading>
        <div className='sms-small-container'>
          <div style={{display: 'flex', gap: 16, alignItems: 'center', flexWrap: 'wrap'}}>
            <MobileDateRangePicker
              value={[moment(startDate), moment(endDate)]}
              slots={{field: SingleInputDateRangeField}}
              onChange={this.handleDateChange}
              onClose={() => {
                if (!moment(startDate).isSame(this.date.startDate) || !moment(endDate).isSame(this.date.endDate)) {
                  this.date={startDate, endDate}
                  this.props.applyFilter(this.state)
                }
              }}
              name="allowedRange"
              disableFuture
              label="Date Range"
              format="DD/MM/YYYY"
              sx={{minWidth: 225}}
            />
            <Select
              value={ smsType }
              fullWidth={ isMobile }
              onChange={ this.handleChange }
              sx={{minWidth: 150}}
              label="SMS Type"
              options={[
                {value: 'ALL', name: 'All'},
                ...smsDetails.smsType && smsDetails.smsType.length > 1 ? smsDetails.smsType.map(el => ({value: el, name: langMap[el]})) : []]
              }
            />
            <Select
              multiple
              label="Headers"
              value={senderIds}
              onChange={this.handleSenderIdChange}
              fullWidth={isMobile}
              onClose={() => {
                if (!isEqual(this.senderIds, this.state.senderIds)) {
                  this.senderIds = this.state.senderIds
                  this.props.applyFilter(this.state)
                }
              }}
              options={smsDetails?.senderIds || []}
              sx={{width: 150}}
            />
            {showWait ? <CircularProgress /> :
              <Button startIcon={<FileDownload />} onClick={this.downloadReportsAsExcel} variant='outlined'>Download</Button>  
            }
            
            <div style={{display: 'flex', alignItems: 'center', gap: 8, marginLeft: 'auto'}}>
              <TextField
                label="Search By Mobile Number"
                placeholder="Enter mobile number"
                id="mobileNo"
                value={mobileNo}
                onChange={this.handleMobileNoChange}
                maxLength="10"
                style={{minWidth: 200}}
                variant="standard"
                size="small"
              />
              <Tooltip title="Search">
                <IconButton aria-label="Search" onClick={e => {e.stopPropagation(); this.props.applyFilter(this.state)}}>
                  <Search />
                </IconButton>
              </Tooltip>
              {mobileNo &&
              <Tooltip title="Clear">
                <IconButton aria-label='Clear Search' onClick={(e) => {e.stopPropagation(); this.setState({mobileNo: ''}, () => this.props.applyFilter(this.state))}}>
                  <HighlightOff />
                </IconButton>
              </Tooltip>
              }
            </div>
          </div>
          <a href='' id='download-full-report' style={ {display: 'none'} } target='_blank' />
        </div>
        {jobId &&
          <Alert severity="info" style={{display: 'flex', alignItems: 'center', marginBottom: 8}}>
          Your export is in progress <CircularProgress size={20} style={{marginLeft: 16}} />
          </Alert>
        }
        {isJobCompleted && <Alert icon={<Check fontSize="inherit" />} severity="success" style={{display: 'flex', alignItems: 'center', marginBottom: 8}}>
          Your export is completed, please<Button onClick={this.handleExport}>click here</Button>to download the report.
        </Alert>}
      </>
    )
  }
}
function mapStateToProps(state){
  return {
    smsHistoryExportJobID : state.smsHistory.smsHistoryExportJobID
  };
}
export default connect(mapStateToProps, {getSMSReportExportJobID})(Filter);
