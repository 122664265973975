import Api from '../api';
import { SEND_SMS, SEND_SMS_URL, SCHEDULE_SMS_URL, GET_SCHEDULED_SMS, RE_SCHEDULED_SMS, CANCEL_SCHEDULED_SMS, SMS_DETAILS, SMS_DETAILS_URL, SEND_CUSTOM_SMS, UPDATE_SMS_DETAILS } from '../lib/constant';
import { routeToLogin, getCookie } from '../lib/utils';

export function sendSms(params) {
  const request = Api.post(SEND_SMS_URL, params, `bearer ${ getCookie('userToken') }`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: SEND_SMS, payload: data });
    }).catch((data) => {
      if (data.response.status == 404 && data.response.data.success == false) {
        routeToLogin();
      }
    });
  };
}

export function updateSMSCount(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_SMS_DETAILS, payload: data });
  };
}

export function sendCustomizeSms(params) {
  const request = Api.post(SEND_SMS_URL, params, `bearer ${ getCookie('userToken') }`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: SEND_CUSTOM_SMS, payload: data });
    }).catch((data) => {
      if (data.response.status == 404 && data.response.data.success == false) {
        routeToLogin();
      }
    });
  };
}

export function getScheduledSMS() {
  const request = Api.get(SCHEDULE_SMS_URL, `bearer ${ getCookie('userToken') }`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: GET_SCHEDULED_SMS, payload: data });
    }).catch((data) => {
      if (data.response.status == 404 && data.response.data.success == false) {
        routeToLogin();
      }
    });
  };
}

export function reScheduleSMS(id, params) {
  const request = Api.put(`${ SCHEDULE_SMS_URL }/${ id }`, params, `bearer ${ getCookie('userToken') }`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: RE_SCHEDULED_SMS, payload: data });
    }).catch((data) => {
      if (data.response.status == 404 && data.response.data.success == false) {
        routeToLogin();
      }
    });
  };
}

export function cancelScheduleSMS(id) {
  const request = Api.delete(`${ SCHEDULE_SMS_URL }/${ id }`, `bearer ${ getCookie('userToken') }`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: CANCEL_SCHEDULED_SMS, payload: data });
    }).catch((data) => {
      if (data.response.status == 404 && data.response.data.success == false) {
        routeToLogin();
      }
    });
  };
}

export function getSMSDetails() {
  const request = Api.get(SMS_DETAILS_URL, `bearer ${ getCookie('userToken') }`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: SMS_DETAILS, payload: data });
    }).catch((data) => {
      if (data.response.status == 404 && data.response.data.success == false) {
        routeToLogin();
      }
    });
  };
}
