import Api from '../api';
import {DASHBOARD_DETAILS,DASHBOARD_DETAILS_URL, DASHBOARD_SMS_STATS, DASHBOARD_SMS_STATS_URL,CAMAIGN_DETAILS} from '../lib/constant';
import {routeToLogin, getCookie} from '../lib/utils';

export function dashboardDetails(){
  const d = new Date();
  const request = Api.get(DASHBOARD_DETAILS_URL+"?date="+d.getDate()+"&month="+d.getMonth(), ('bearer '+ getCookie('userToken')));
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({type:DASHBOARD_DETAILS,payload:data})
    }).catch((data) => {
      if(data.response.status == 404 && data.response.data.success == false){
        routeToLogin();
      }
    })
  };
}

export function dashboardSMSStats(){
  const request = Api.get(DASHBOARD_SMS_STATS_URL, ('bearer '+ getCookie('userToken')));
  return (dispatch) => {
    request.then(({data}) => {
      if (data && data.data &&  data.data.lastActivitySent){
        const lastActivityRequest = Api.get(CAMAIGN_DETAILS.replace('{campaign_id}',data.data.lastActivitySent), ('bearer '+ getCookie('userToken')));
        lastActivityRequest.then(({data:lastActivityData}) => {
          if (lastActivityData && lastActivityData.data){
            data.data.lastActivity = lastActivityData.data.campaign
          }
          dispatch({type:DASHBOARD_SMS_STATS,payload:data})
        }).catch((err) => {
          data.data.lastActivity = {}
          dispatch({type:DASHBOARD_SMS_STATS,payload:data})
        })
      }else{
        data.data.lastActivity = {}
        dispatch({type:DASHBOARD_SMS_STATS,payload:data})
      }
    }).catch((data) => {
      if(data.response.status == 404 && data.response.data.success == false){
        routeToLogin();
      }
    })
  };
}
