import React, {Component} from 'react';
import {HighlightOff} from '@mui/icons-material';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip} from '@mui/material';
import {throttle} from 'lodash';

import Select from '../../components/Select';
import Table from '../../components/Table';
import {GAEventTracker} from '../../lib/googleAnalytics';
import {sortGroup} from '../../lib/utils';
import ContactGroupChip from './contactGroupChip';
import ContactGroupChipList from './contactGroupChipList';

export default class ContactGroupList extends Component {
  constructor(props){
    super(props);
    this.state={
      autoCompleteValue : '',
      selectedContacts : props.individualList,
      showAllSelected : false,
      selectValue : 0
    }
    this.autoComplete = this.autoComplete.bind(this);
    this.throttleInput = throttle(this.throttleInput.bind(this),300);
    this.userList = props.userList;
    this.visibleList = this.userList;
    this.newUserList = this.userList;
  }
  autoComplete = (e) => {
    this.setState({autoCompleteValue:e.target.value});
    this.throttleInput(e.target.value);
  }
  throttleInput = (val) => {
    this.newUserList = this.visibleList.filter(data => {return (data.name.toLowerCase().indexOf(val.toLowerCase()) > -1 || data.mobile.indexOf(val) > -1);});
    this.setState(prev => ({...prev}));
  }
  removeAutoCompleteValue = (e) => {
    e.stopPropagation()
    this.setState({autoCompleteValue : ''});
  }
  handleCheckBox = (id,details) => {
    let {selectedContacts,selectValue} = this.state;
    if(selectedContacts[id]){
      delete selectedContacts[id];
    } else{
      selectedContacts[id] = details;
      selectedContacts[id].gId = selectValue;
    }
    this.setState({selectedContacts});
  }
  handleCheckBoxAll = () => {
    let {selectedContacts,selectValue} = this.state;
    let vList = this.visibleList;
    if(Object.keys(selectedContacts).length === this.visibleList.length){
      for(let gc of vList){
        delete selectedContacts[gc.id];
      }
    } else {
      for(let gc of vList){
        if(!selectedContacts[gc.id]){
          selectedContacts[gc.id] = gc;
          selectedContacts[gc.id].gId = selectValue;
        }
      }
    }
    this.setState({selectedContacts});
  }
  handleChange = (e) => {
    this.visibleList = e.target.value == 0 ? this.userList : this.props.groupListMap[e.target.value].contacts;
    this.setState({selectValue : e.target.value,autoCompleteValue : ''});
    GAEventTracker({category : 'Select Contact-Group', action : 'Select Groups'});
  }
  selectedContactsCallback = (selectedContacts) => this.setState({selectedContacts});
  handleShowMoreChip = () => this.setState({showAllSelected:true});
  handleSelectedDialogClose = () => this.setState({showAllSelected:false});
  render(){
    let {selectedContacts,autoCompleteValue,showAllSelected,selectValue} = this.state;
    let {groupList} = this.props;
    let actions = [
      <Button onClick={this.props.handleRecipientListDialogCloseWithNoAction}>Cancel</Button>,
      <Button onClick={() => this.props.handleRecipientListDialogClose(selectedContacts)} variant='contained'>OK</Button>
    ];
    if(this.userList.length == 0){
      actions = [
        <Button onClick={this.props.handleRecipientListDialogCloseWithNoAction}>OK</Button>
      ];
    }
    let selectAllCheck = !this.visibleList.some(vl => !selectedContacts[vl.id]);
    let selectAllShow = selectAllCheck && selectValue == 0;
    let nameList = autoCompleteValue == '' ? this.visibleList : this.newUserList;
    const isMobile = window.isMobile();
    return(
      <Dialog
        open
        onClose={this.props.handleRecipientListDialogCloseWithNoAction}
      >
        <DialogTitle>
          Select Contacts using Groups
        </DialogTitle>
        <DialogContent style={{paddingTop: 16}} dividers>
          {
            this.userList.length ?
              <>
                <div style={isMobile ? {display: 'flex', alignItems: 'center', gap: 8, width: 300, flexWrap: 'wrap'} : {display: 'flex', alignItems: 'center', gap: 8, width: 450}}>
                  <Select
                    id="selectValue"
                    value={ selectValue }
                    onChange={ this.handleChange }
                    label="Select Group"
                    sx={{minWidth: 150}}
                    options={[{name: 'All', value: 0}].concat(sortGroup(groupList).map(el => ({value: el.id, name: el.name})))}
                  />
                  {isMobile && <div style={{flexBasis: '100%', height: 0}} />}
                  <TextField
                    label={isMobile ? "Search Contact" : "Search Contact By Name/Number"}
                    value={autoCompleteValue}
                    onChange={this.autoComplete.bind(this)}
                    size="small"
                    fullWidth
                    sx={{flex: 1}}
                    variant="standard"
                  />
                  {
                    autoCompleteValue ?
                      <Tooltip tooltipPosition="top-center" title="Clear Search">
                        <IconButton aria-label="Clear Search" onClick={this.removeAutoCompleteValue}>
                          <HighlightOff />
                        </IconButton>
                      </Tooltip>
                      : null
                  }
                </div>
                {
                  Object.keys(selectedContacts).length ?
                    <div style={{padding: '8px 0'}}>
                      <ContactGroupChip
                        renderLess={true}
                        selectAllShow={selectAllShow}
                        selectedContacts={selectedContacts}
                        selectedContactsCallback={this.selectedContactsCallback}
                        handleShowMoreChip={this.handleShowMoreChip} />
                    </div>
                    : null
                }
                {
                  nameList.length ?
                    <Table
                      column={[
                        {name: 'Name', value: 'name'},
                        {name: 'Mobile', value: 'mobile'}
                      ]}
                      data={nameList}
                      enableCheckbox
                      handleSelect={this.handleCheckBox}
                      selected={Object.keys(selectedContacts)}
                      onSelectAllClick={this.handleCheckBoxAll}
                      showPagination
                      pageOptions={[30]}
                    />
                    : <p style={{paddingTop: 8, fontSize: 14}}>No Contact Available!</p>
                }
              </>
              : <p>Currently No Contacts Available, Click on Contacts tab on Left to create them!</p>
          }
          {
            showAllSelected ?
              <ContactGroupChipList
                handleSelectedDialogClose={this.handleSelectedDialogClose}
                selectedContacts={selectedContacts}
                selectedContactsCallback={this.selectedContactsCallback} />
              : null
          }
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>

    )
  }
}
