import Api from '../api';
import { LOGOUT, LOGOUT_URL } from '../lib/constant';

export function logoutRequest(authToken){
    const request = Api.post(LOGOUT_URL,{},authToken);
    return (dispatch) => {
      request.then(({data}) => {
          dispatch({type:LOGOUT,payload:data})
      })
    };
}
