import { Map } from 'immutable';

import { GET_ALL_USER, ADD_USER, DELETE_USER, UPDATE_USER, BULK_CONTACT, DOWNLOAD_CONTACT, DELETE_BULK_CONTACT, GET_CONTACT_GROUP } from '../lib/constant';

const initialState = Map({userResponse : {}, addResponse : {}, deleteResponse : {}, updateResponse : {}, downloadContacts:{}, bulkDeleteResponse:{}, contactGroupResponse:{}});

export default function(state = initialState , action ) {
	switch(action.type){
        case GET_ALL_USER :
            return {...state,userResponse:action.payload};
		case ADD_USER :
            return {...state,addResponse:action.payload};
		case DELETE_USER :
            return {...state,deleteResponse:action.payload};
		case UPDATE_USER :
            return {...state,updateResponse:action.payload};
		case BULK_CONTACT :
            return {...state,bulkContactResponse:action.payload};
		case DOWNLOAD_CONTACT :
            return {...state,downloadContacts:action.payload};
		case DELETE_BULK_CONTACT :
			return {...state,bulkDeleteResponse:action.payload};
		case GET_CONTACT_GROUP :
			return {...state,contactGroupResponse:action.payload};
        default :
		     return state;
    }
}
