/* eslint-disable no-restricted-globals */
const workercode = () => {
  self.onmessage = function(e) { // without self, onmessage is not defined
    const {data} = e.data;
    let contactList = data.data.contacts || [];
    let groupDataDict = data.data.groups || {};
    contactList.forEach(contact => {
      contact.groups && contact.groups.length && contact.groups.forEach(group =>{
        groupDataDict[group.groupId] && groupDataDict[group.groupId].contacts &&  groupDataDict[group.groupId].contacts.push({
            id: contact && contact.id,
            mobile: contact && contact.mobile,
            name: contact && contact.name
          })
      })
    });
    data.data.groups = Object.values(groupDataDict);
    data.data.smsUI = true;
    self.postMessage({data:data})
  }
};

let code = workercode.toString();
code = code.substring(code.indexOf("{")+1, code.lastIndexOf("}"));

const blob = new Blob([code], {type: "application/javascript"});
const worker_script = URL.createObjectURL(blob);

module.exports = worker_script;