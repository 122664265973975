/* eslint-disable max-len */
import React from 'react';
import {Helmet} from "react-helmet";

import Footer from './footer';
import Header from './header';

const TermsConditions = () => {
  const brandName = process.env.REACT_APP_BRAND_NAME
  const companyName = process.env.REACT_APP_COMPANY_NAME
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL
  return (
    <div>
      <Helmet>
        <title>Terms and Conditions | {brandName}</title>
      </Helmet>
      <Header headerClass="activeBlue" />
      <div className="termsConditions container-lg">
        <h1>Our Terms and Conditions</h1>
        <h2>Legal Information</h2>
        <p>These Terms of Service (ToS) are an agreement between {companyName} (“{brandName}”, which term shall, unless it be repugnant to the context or meaning thereof, be deemed to mean and include all its affiliates, subsidiaries, successors and permitted assigns) and any Person who is a user/browser/accessed to the Site, (User), whether or not registered, of any of the services available on the this website (Site). The term 'Person' shall mean and include any individual, corporation, partnership, limited liability company, trust, joint stock company, business, limited liability partnership, trust, unincorporated association, Hindu undivided family, joint venture, organization similar to the foregoing, governmental authority or other entity of any nature whatsoever. These ToS shall be read in conjunction with all amendments, additional terms, Privacy Policy, or any other terms that govern the services provided through this product from time to time (together, the Service Agreements).</p>
        <div>
          <ul>
            <li>Registration</li>
            <li>The User, upon registration and successful completion of the registration formalities/payments (Registration) may avail the use of the paid services on the Site, including the use of APIs and the platform (collectively, referred to as the Services). Registration implies acceptance of the terms of the ToS, additional terms and Privacy Policy as may be in force from time to time, including the terms of payment as may be applicable at the relevant time or at any time thereafter.</li>
            <li>The User hereby declares and agrees that (i) all information (including personal information) provided by the User during Registration is true, genuine and accurate and that the User shall ensure that all such information is current, complete, and true at all times; (ii) the User shall maintain and update the User's registration data, in order to keep such data current, complete and true (iii) the User represents that he/she is of legal age (if the User is an individual) and has the requisite legal standing to form a binding contract with {brandName} and is not a person barred from receiving Services under the laws of India or other applicable jurisdiction.</li>
            <li>All personal information provided by the User in order to accomplish Registration shall be treated in accordance with the Privacy Policy as may be applicable from time to time.</li>
            <li>Upon Registration, the User will be provided a private account (User Account) and a username and password for such User Account. The User shall be solely responsible for the use and activities conducted through such User Account. The User acknowledges that, in order to regulate access to the Service, his/her authentication depends on the verification of the correct username and password. The User acknowledges and accepts that the User is solely responsible for safeguarding the confidentiality of his/her User Account information (including password(s) and user name(s)) and for any use or misuse of his/her User Account and/or the Site resulting from any third party using a password or user name issued to such User. The User agrees to notify {brandName} immediately of any known or suspected unauthorized access to or use of the User's Account, or any other breach of security or misuse of the Site known to or suspected by the User and that {brandName} is not responsible for fraud of any person or of other Users of the User's Account.</li>
            <li>The User accepts and acknowledges that usage of Credits, if any, granted free of charge by {brandName} to the User at the time of Registration or at any other time shall also be subject to the all the terms and conditions of the ToS and other Service Agreements.</li>
            <li>Usage</li>
            <li>General Conditions of Service</li>
            <li>General Terms
              <ol>
                <li>User acknowledges that the Services provided herein are restricted to India territory and to the recipients in India only.</li>
                <li>The User acknowledges and accepts that {brandName} may, at its sole discretion, deactivate the User's password and/or User Account, or suspend the User's access to any Service without notice at any time. Further, {brandName} reserves the right to remove or cancel any of the Content or prevent the distribution of any Content, without notice and without being obliged to provide any explanation for the same. The User hereby authorizes {brandName} to rely on any data, notice, instruction or request furnished by the User to {brandName}, or that {brandName} reasonably believes to have been furnished by such User.</li>
                <li>The User acknowledges and agrees that {brandName} acts merely as a facilitator in respect of the Services and shall not be liable or responsible in any manner whatsoever for the Content or any other aspect of such Service.</li>
                <li>The User agrees that {brandName} reserves the right to refuse, deny or otherwise restrict the use of the Services by any User, including the right to terminate the use of any Services by any User without notice at any time for any reason as {brandName} in its sole discretion may deem sufficient, or without reason. {brandName} shall not be liable in any manner whatsoever for such refusal, restriction or termination of the use of any Services by a User at any time.</li>
                <li>The User agrees that unless otherwise indicated, any modification of the Services which alter the way in which the Service can be used and/or the launch of new Services will be subject to, and therefore regulated by the ToS and other relevant Service Agreements.</li>
                <li>The User acknowledges and agrees that his/her access to and use of the Site, and any and/or all of the Services of, is at the User's own risk. The User acknowledges that the Service is dependent on telecommunications companies and mobile network providers (Operators) and {brandName} makes no guarantees of any kind regarding the dependability, accuracy, security, timeliness or availability of the Site network, or Services. Without limiting the foregoing, {brandName} does not make any representations or warranties about the condition, suitability, reliability, availability, completeness, security, timeliness, or accuracy of the information, Software, Services and materials contained on the Site for any purpose. All such information, Software, Services and materials are provided "as is" without warranty of any kind. {brandName} hereby disclaims all representations, warranties and conditions, express or implied, with regard to this information, Software, Services or materials, including all implied warranties and conditions of merchantability, fitness for a particular purpose, title and non-infringement.</li>
                <li>The User acknowledges and agrees that it shall be solely responsible for ensuring all compliances with the Telecom Regulatory Authority of India's (TRAI) Telecom Commercial Communications Customer Preference Regulations, 2010 (TRAI Regulations) and any and all other regulations, statutes, orders, decisions or law in force from time to time which may be applicable to the use of any of the Services (Law). The User acknowledges and agrees that it shall be solely liable, directly or indirectly, for all damage or loss caused or alleged to be caused to {brandName}, by or in connection with any misuse of the Services provided by the Company with respect to pushing any unsolicited commercial communications to subscribers and for any and all other violations of the provisions of the TRAI Regulations or Law.</li>
                <li>The User hereby agrees to defend, indemnify and hold harmless and agrees to continue to keep indemnified {brandName}, its affiliates, directors, officers, employees etc. without any demur or protest, against any/all losses, damages, actions, proceedings including but not limited to legal expenses with respect to violation of these ToS, or any third-party's rights, (including, without limitation, infringement of any copyright, violation of any proprietary right and invasion of any privacy rights) and also in respect to any action taken by any regulatory body or the Operator or any third party with respect to the misuse of the Services availed from the Company including but not limited to, for the breach of any of the provisions of the TRAI Regulations or breach of Law or for any other reason whatsoever. The User acknowledges and agrees that these obligations will be binding on the User during the entire term of the use of any and/or all of the Services and shall survive any termination of the User's relationship with {brandName} or use of the Site.</li>
                <li>The User acknowledges that {brandName} does not assume any implicit or explicit responsibility for the Content stored and/or published by the User through the Site. The User further accepts and agrees that all declarations and opinions expressed by the User are the sole declarations and opinions of such User and do not necessarily represent {brandName}'s opinions. Any person who believes that any Content stored and/or published on the Site or distributed through the Site by any User infringes any law, is kindly requested to contact {brandName} at the following email address {contactEmail}.</li>
                <li>The User acknowledges and agrees that all software utilized in connection with the Services (Software) is owned by {companyName} (and its group companies) and is protected by the applicable Intellectual Property Laws of India and laws of any other jurisdiction in which such Software is used as may be in force from time to time. The User agrees that it shall not, directly or indirectly, copy, modify, create derivative works, and in any way try to discover the source codes of the Software, sell license or transfer to the third parties any right on the Software. Further the User shall not have any rights over the Software in any manner. The User shall not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, Software, services or benefits obtained from the Site, except as expressly provided herein. The User further agrees that the User shall not reproduce or redistribute any Software and that the User shall not copy or reproduce the Software to any other server or location for further reproduction or redistribution. The User agrees that it shall not decompile, disassemble, reverse engineer or otherwise attempt to discover any trade secret contained in the Site or in any product, Service or Software provided through the Site.</li>
                <li>The right to use any Service is limited only to the User. Where the User is a Company, such right shall include its employees, provided that any contraventions by the employees of such Company of the provisions of the ToS or any Service Agreement shall be deemed to be violations by the Company and accordingly for the purposes of the ToS and/or any Service Agreements, the Company shall be liable to {brandName} for all such contraventions.</li>
                <li>The User agrees that the User shall not use a name in connection with using any of the Services that is confusing or misleading, or otherwise impersonate or deceive anyone with respect to its identity. The User further agrees that the User shall not restrict or inhibit any other User from using and enjoying such other User's rights in the Site or in respect to any Service and should not interfere with or disrupt the Site or servers or any network connected to the Site.</li>
                <li>The User agrees and accepts that the User shall not use any of the Services through or in connection with the Site to:
                  <ol>
                    <li>rent, lease, license, grant a security interest in, or otherwise transfer the User's rights hereunder to any third party unless otherwise expressly agreed by {brandName};</li>
                    <li>defame, libel, slander, impersonate, abuse, harass, threaten, invade the privacy of or otherwise violate or infringe the legal rights (including the rights of privacy and publicity) of any other person;</li>
                    <li>conduct, promote or forward illegal contests, pyramid schemes, chain letters, unsolicited or unauthorized advertising, unauthorized promotional materials, unsolicited e-mail ( including "spamming") or multi-level or illegal marketing campaigns;</li>
                    <li>harm minors in any way;</li>
                    <li>publish, post, distribute, disseminate, advertise or link to any content, site, topic, name, material or information which is illegal, inappropriate, profane, defamatory, libelous, slanderous, infringing, obscene, indecent, or which contains nudity or adult content;</li>
                    <li>software, content or other material protected by intellectual property laws, copyright licenses, rights of privacy or publicity, or other proprietary rights, unless the User owns, or controls such rights or has received all necessary consents for the publication, distribution, or linking of such software and other materials;</li>
                    <li>software, content or other material that contains viruses, worms, corrupted files, cracks or that may or are intended to damage the operation of or render inoperable another's computer, hardware, software, security measures or system or other programs written to defeat the security measures of any computer, system or programs including any hardware or Software of {brandName};</li>
                    <li>software, content, or other material that constitutes "hate speech", whether directed at an individual or a group, and whether based upon the race, sex, creed, national origin, religious affiliation, sexual orientation or language of such individual or group;</li>
                    <li>sell, offer to sell, distribute, promote, facilitate, disseminate or link to any sites for marketing, sales, promotion, facilitation or distribution of: firearms; explosives; ammunition; liquor; tobacco products; illegal gambling (including gambling in connection with sporting events); food that is not packaged or does not comply with all applicable laws for sale to consumers by commercial merchants; illicit drugs, pharmaceuticals and controlled substances; counterfeit, pirated or stolen goods; any goods or services that infringe or otherwise violate a third party's rights including copyright, patent, trademark, or rights of publicity or privacy; goods that are considered indecent, obscene or pornographic; registered or unregistered securities; goods or services that the User cannot legally sell; goods which are misrepresented or which do not in fact exist; fraudulent goods; goods, services or activities that if sold via any of the Services site would cause {brandName} to violate any law, statute or regulation; or any other illegal activity;</li>
                    <li>harvest or otherwise collect information about third parties, including e-mail addresses, without the express consent of such third parties;</li>
                    <li>violate any applicable government laws or regulations.</li>
                  </ol>
                </li>
                <li>The User agrees that the Services are provided by {brandName} without any implicit or explicit warranty and no advise, suggestion, help, written or verbally, provided by {brandName} to the User, shall imply any warranty from {brandName}. {brandName} cannot be held responsible of any harm coming from the inability to access or use any of the Services, or for the loss of any Content of the User or for any other loss suffered by the User as a result of using the Services. The User agrees and acknowledges that it shall be the sole responsibility of the User to keep a copy of the Content. {brandName} declines any responsibility whatsoever for any harm coming from the inability to access the Services, viruses, damaged files, errors, interruption of any of the Service, network problems, non-authorized access, modification of data, merchantability and fitness for a particular purpose or for any other claim whatsoever of a similar nature. {brandName} reserves the right to modify any and/or all the Services at any time, even without notice to the User.</li>
                <li>The User accepts and acknowledges that {brandName} may change any of the Services at any time and from time to time without notice, including terminating the offering of any Service altogether. The User agrees that the User may at any time be barred from accessing any of the Services or from using the Site or from receiving any services or benefits from the Site, if {brandName} determines the User to have violated the ToS or any other Service Agreements, if any representation or warranty made by the User is untrue in any respect or if {brandName} receives a court order or other legal action relating to the User Account. In addition the User agrees that, if the User violates any of these ToS or any applicable Service Agreement the User shall forfeit all Credits accrued to the User in connection with the Site and there will be no refund of any fees prepaid by the User to {brandName}.</li>
                <li>The User acknowledges and agrees that links (if any) within the Site may let the User leave the Site and that the linked sites are not under the control of {brandName} and that {brandName} is not responsible for the contents or operation of such linked sites or any link contained in such linked site, any changes or updates to such sites, or Web casting or any other form of transmission received from any linked site.</li>
                <li>The User acknowledges and accepts that {brandName} shall not in any event be liable for any direct, indirect, punitive, incidental, special, consequential or exemplary damages or any damages whatsoever including, without limitation: damages for loss of use, data, accounts, revenues or profits, arising out of or in any way connected with the use or performance of the Site or any of the Services, or with the delay or inability to use the Site or any Service and related graphics obtained through the Site; or otherwise arising out of the use of the Site, whether based on contract, tort, negligence, strict liability or otherwise, even if {brandName} or any of its suppliers has been advised of the possibility of damages. The User acknowledges that if the User is dissatisfied with any portion of the Site, or with any of the ToS or any other Service Agreement, the User's sole and exclusive remedy is to discontinue using the Site and its related Services. It is clarified that in the event of such discontinuation, the User shall not be entitled to receive any refund of monies paid by such User to {brandName} or make any other claims whatsoever against {brandName}.</li>
                <li>The User confirms and declares that the Services are being availed by the User for itself and the User is not an intermediary acting for and on behalf of a third party.</li>
                <li>{brandName} reserves the right to suspend the services anytime with or without notice in case of any order/direction by a Statutory Body (State or Central) or Judiciary.</li>
                <li>You agree to receive promotional, transactional and commercial communications from {brandName} through email or SMS.</li>
              </ol>
            </li>
            <li>Content
              <ol>
                <li>The User shall be responsible for all Content uploaded, displayed, transmitted or otherwise used on this Site or published and/or distributed through this Site by such User and {brandName} shall have no responsibility or liability whatsoever in respect of such Content. {brandName} reserves the right, but is not obligated, to monitor or to review the Content of the User and to monitor User's compliance with the ToS. {brandName} reserves the right in its sole discretion to edit, refuse to post or remove any Content which, in the opinion of {brandName} violates the ToS or that {brandName} for any other reason may deem unfit or improper to be used on the Site, at any time without notice to the User.</li>
                <li>The User represents and warrants that all Content uploaded or distributed/transmitted through the Site by it is owned by such User, or that such User is otherwise well entitled to display, sell, market or otherwise use such Content. {brandName} shall not be liable or responsible in any manner whatsoever in respect of any dispute as to the ownership of such Content or in respect of any other dispute, claim, etc. of any nature whatsoever in respect of such Content. The User further agrees to indemnify and continue to hold indemnified {brandName} against any claims of any nature whatsoever in respect of the ownership and use of all such Content.</li>
                <li>All Content uploaded or otherwise used on this site must conform to the content rules, if any, provided by {brandName} from time to time.</li>
                <li>The User acknowledges that {brandName} does not sell the Content, but rather provides a Service to transfer the Content supplied by the User from the web to mobile and other devices. {brandName} does not operate any control, monitor, validation or verification over the Content of the User, and therefore cannot be held responsible for the type of Content which can be found on its platform or transmitted through the platform. However, {brandName} shall not be responsible or liable in any manner whatsoever for the authenticity, ownership, accuracy or for any other claim whatsoever in respect of such Content.</li>
              </ol>
            </li>
            <li>Payment Terms
              <ol>
                <li>The User agrees that {brandName} reserves the right to charge the User for use of any Services at the rate and in the manner as may be decided by {brandName} from time to time (Consideration). The User acknowledges and agrees that {brandName} shall have no obligation to justify the amount of Consideration charged for any Service or be responsible or liable in any manner whatsoever to the User in respect of such Consideration.</li>
                <li>The User shall pay Consideration for all Services through use of Credit Card, net banking or offline mode by cheque or demand drafts against which Credits will be issued which shall be utilized for availing Services subject to the terms and conditions for the use of such Credits. The User shall pay Consideration in advance for the use of any Services.</li>
                <li>{brandName} shall not under any circumstances, be liable to refund or otherwise return any money paid by a User for use of the Services, whether by way of purchase of Credits or otherwise, except as specifically provided in these ToS.</li>
                <li>The Commercials mentioned here are subject to change/modification from time to time depending on the change in commercials offered by Network Operator(s) and or Statutory Body. Such changes shall be communicated to the registered e-mail ID with {brandName} or the same shall be communicated on the home page of {brandName} website.</li>
              </ol>
            </li>
            <li>Credits
              <ol>
                <li>"Credit" means one (1) standard short message (SMS) of 160 characters which shall be reflected in the User Account of the relevant User, and "Credits" shall be construed accordingly. A standard text message is 160 characters long (SMS sent using the GSM 3.38 Character set). Users can send longer SMS of upto 918 characters which are split into smaller SMS and later joined at the receiving end which is a mobile. In this scenario, SMS length is calculated for 153 characters as 7 characters are used to concatenate the message when it is delivered to the handset.
Unicode allows the support of a much wider character set, and includes most character sets in the world, the standard length of a Unicode message is 63 characters (compared to 160 for GSM). Long messages are supported for Unicode messages as well, and they are in multiples of 63 (compared to 153 for GSM).</li>
                <li>Any Credit once bought cannot be refunded, encashed or otherwise redeemed by the User except for the use of Services unless specifically stated otherwise in these ToS. If the User opts to close his User Account all Credits accumulated in such User Account will immediately thereafter expire and cease to be valid. If the User Account is closed by {brandName} in accordance with the rights granted to {brandName} under the ToS or any of the Service Agreements, all Credits accumulated in the User Account of such User shall stand forfeited immediately. {brandName} shall not under any circumstances incur any monetary or other liability to the User or any other person in respect of Credits expiration/forfeited as a result of closure of the User Account.</li>
                <li>Credits may not be transferred between Users or from one User Account to another User Account of the same User.</li>
                <li>{brandName} reserves the right to refuse and/or cancel the sale of Credits to any User based on its sole discretion.</li>
                <li>{brandName} reserves the right to withhold the Credits of any User, and/or to not permit such User to use such Credits for such period of time as {brandName} may in its sole discretion deem fit, in case if {brandName} observes that the User is misusing the Services (which includes but not limits to spamming, phishing etc). User shall not use the services for any unlawful activity (including but not limited to fraudulent transactions, fraudulent communications, phishing, communication to an individual or a group of individuals with an intention to commit any fraud or deceive them).</li>
                <li>{brandName} reserves the right to confiscate any Credits of a User in accordance with the provisions of this Agreement (Confiscated Credits). All Confiscated Credits shall stand forfeited immediately and the User shall not have any further monetary or other claim or right and {brandName} shall have no liability whatsoever in respect of such Confiscated Credits. {brandName} also reserves the right to amend the number of Credits based on the amendment in the cost of each Credit due to change in commercials by Operator/Statutory Body with or without intimation to the User.</li>
                <li>{brandName} has the right to alter or vary any of the above provisions and change its policies in respect of Credits in the manner that {brandName} may, in its sole discretion deem fit, without prior notice to the User and {brandName} shall not be liable in any manner whatsoever to the User in the event of such alteration or variation of terms. The User is advised to review the terms of use of Credits periodically so as to remain informed of changes, if any, in {brandName}'s policies in this regard.</li>
              </ol>
            </li>
            <li>Governing Law and Jurisdiction
              <ul>
                <li>The User and {brandName} agree that these ToS shall be governed by the laws of India. The User and {brandName} agree that all disputes that may arise from or in connection with theToS shall be subject to the jurisdiction of the Courts of Bengaluru, Karnataka, India.</li>
              </ul>
            </li>
            <li>Miscellaneous
              <ol>
                <li>The User agrees that if any provision of these ToS shall be held to be illegal, invalid or unenforceable, in whole or in part, under any enactment or applicable Law, such provision or part thereof shall to that extent be deemed not to form a part of these ToS, and the legality and enforceability of the remainder of these ToS shall not be affected. These ToS constitute the entire agreement between the User and {brandName} with regard to use of the Services and all other written or oral agreements or understandings previously existing between the User and {brandName} with respect to such use are hereby superseded and/or cancelled. {brandName} failure, if any, to insist on or enforce strict performance of these ToS shall not be construed as a waiver by {brandName} of any provision or any right or future right it has to enforce these ToS, nor shall any course of conduct between {brandName} and User or any other party be deemed to modify any provision of these ToS. These ToS shall not be interpreted or construed to confer any rights or remedies on any third parties.</li>
                <li>The User agrees not to represent himself/ herself to be a representative, agent, or employee of {brandName} and that {brandName} will not be liable by reason of any representation, act or omission to act by such User. The User agrees that no joint venture, partnership, employment, or agency relationship exists between the User and {brandName} as a result of these ToS or use of the Site.</li>
                <li>The User acknowledges and accepts that {brandName}'s performance of this agreement is subject to existing laws and legal process, and nothing contained in these ToS are in derogation of {brandName}'s right to comply with governmental, court and law enforcement requests or requirements relating to the use of the Site or information provided to or gathered by {brandName} with respect to such use.</li>
                <li>{brandName} shall provide the services on best effort basis and it takes no responsibility for the delay caused in the Services as the delivery is subject to inter-operator dependencies.</li>
                <li>The User acknowledges that {brandName} does not guarantee the continued use of any Service Number and that use of such Service Number may be discontinued at any time without notice to the User. If the Service Number of any User is discontinued {brandName} shall make all reasonable efforts to provide such User with an alternate Service Number for the continuation of the Services (Alternate Service Number).</li>
                <li>The User agrees that if {brandName} is unable to provide an Alternate Service Number due to any reason whatsoever, {brandName} shall have the right to refund the proportionate amount of Credits paid by the User for the use of the Service Number and such refund of Credits by {brandName} shall fully and finally settle all liabilities and claims whatsoever of the User in this regard. The User further agrees that the liability of {brandName} in the event that {brandName} does not provide an Alternate Service Number to the User shall not under any circumstances be in excess of the proportionate amount of Credits paid by the User in respect of the particular Service Number.</li>
                <li>The User shall provide {brandName} with a list of recipients (each a Recipient and together the Recipients) of a Promotional Campaign and {brandName} shall, in accordance with the terms and conditions push the relevant Promotional Messages to the gateway of the Operator for forwarding to the Recipients.</li>
                <li>The User agrees that {brandName} shall only ensure that the Promotional Messages are pushed to the gateway of the Operator through whom such Promotional Messages are transmitted to the Recipients and that {brandName} shall not be required to take any further action and shall not bear any further obligation in respect of such Promotional Messages. {brandName} does not guarantee the receipt of the Promotional Message by the Recipients and shall not be liable in any manner whatsoever for the failure of delivery of the Promotional Messages to any and/or all the Recipients.</li>
                <li>The User represents and warrants that all the Recipients listed in the list of Recipients provided to {brandName} have consented to receive the relevant Promotional Messages and that pushing of the Promotional Messages to such Recipients shall not constitute a violation or breach of Applicable Law including the TRAI Regulations . In the event that it is found that any of such Recipients has not consented to the Receipt of such Content/ Promotional Message or has objected to the receipt of such Content/Promotional Message for any reason whatsoever, all responsibility and liability in this regard shall rest solely on the User and {brandName} shall not be responsible or liable in any manner whatsoever in this regard. The User agrees to indemnify and continue to hold indemnified {brandName} from any loss, damage or claim whatsoever in respect of the sending of any Promotional Message or use of any other Service to a Recipient who has not consented to the receipt of such Promotional Message or other Service.</li>
                <li>The User agrees that {brandName} reserves the right, but is not obliged, to filter all Promotional Messages and also reserves the right to not push any and/or all Promotional Message to any and/or all Recipients for any reason as {brandName} may, in its sole discretion, deem fit. The User further agrees that {brandName} shall not be liable to the User or any other party in any manner whatsoever in respect of such decisions taken by {brandName} to not push any and/or all the Promotional Messages to any and/or all the Recipients and {brandName} shall also not be liable in any manner whatsoever in the event that it does not filter such Promotional Messages.</li>
                <li>The User agrees that the relevant Promotional Messages shall be pushed to the gateway of the Operator within the specific time period pre-agreed to between the User and {brandName}.</li>
                <li>The User acknowledges and agrees that it shall be solely responsible for ensuring all compliances with the TRAI Regulations. The User acknowledges and agrees that it shall be solely liable, directly or indirectly, for all damage or loss caused or alleged to be caused to {brandName}, by or in connection with any misuse of the Services provided by the Company with respect to pushing any commercial communications to subscribers and for any and all other violations of the provisions of the TRAI Regulations.</li>
                <li>The User hereby agrees to defend, indemnify and hold harmless and agrees to continue to keep indemnified {brandName}, its affiliates, directors, officers, employees etc. without any demur or protest, against any/all losses, damages, actions, proceedings including but not limited to legal expenses with respect to any action taken by any Statutory Body or the Operator with respect to the misuse of the Services availed from the Company including but not limited to, for the breach of any of the provisions of the TRAI Regulations during the entire period of the Promotional Campaign and for all periods after the expiry or termination of the relevant Promotional Campaign.</li>
                <li>The User agrees that if the User is desirous of terminating any Promotional Campaign it shall provide a request in writing to {brandName} for such termination of the relevant Promotional Campaign (PC Termination Request) and {brandName} shall subsequent to receipt of such PC Termination Request make all reasonable efforts to terminate the relevant Promotional Campaign at the earliest. The User further agrees that despite making such PC Termination Request, the User shall continue to be liable to make payments of all amounts due to {brandName} for Services provided between the time of receipt of PC Termination Request by {brandName} and the actual termination of the relevant Promotional Campaign. If, due to any reason whatsoever, {brandName} is unable to terminate any Promotional Campaign, the User agrees that it shall be liable to make all payments to {brandName} in respect of such Promotional Campaign as if no PC Termination Request had been made by the User. Nothing contained above shall cause or imply any liability of {brandName} to the User and the User agrees that it shall indemnify and continue to hold indemnified {brandName} in the manner prescribed in the ToS.</li>
                <li>The entire liability of {brandName} for the reasons directly attributable to it and your exclusive remedy for any dispute/difference in connection with the Affiliate Program and the Dual Brand Program shall in no event exceed the applicable Commission payable to You.</li>
                <li> Fair Use: The user shall not send more than 100 SMS per day via Email2SMS. The limit is not applicable for dedicated channel users.</li>
              </ol>
            </li>
            <li>APIs
              <ol>In addition to the General Conditions of Service any User of API Services shall be bound by the following terms and conditions:
                <li>The User acknowledges and undertakes that the User shall not have rights over any API offered by {brandName} for the User to enhance the options for utilization of Services and such API shall belong to {brandName}. {brandName} has disclosed the API only for the purpose for utilization of Services by the User. The User undertakes and agrees that it shall not, except with the prior written consent of {brandName}: (i) allow or in any manner facilitate or permit the utilization of any APIs by any person other than the User; (ii) develop/create/build any product or service using one or more of the APIs to be used except by the User; (ii) redistribute or reuse any APIs or distribute or sell any products or services developed using any APIs. The User acknowledges and accepts that if it wishes to undertake any of the actions stipulated in (i), (ii) or (iii) above, the same would be subject to the prior written consent of {brandName} and subject to specific additional terms and conditions stipulated by {brandName}, in its sole discretion, in this regard.</li>
                <li>The User acknowledges and agrees that it shall be solely liable, directly or indirectly, for all damage or loss caused or alleged to be caused to {brandName}, by or in connection with any misuse of the API or due to use of any API other than in accordance with the terms specified herein.</li>
                <li>The User hereby agrees to defend, indemnify and hold harmless and agrees to continue to keep indemnified {brandName}, its affiliates, directors, officers, employees etc. without any demur or protest, against any/all losses, damages, actions, proceedings including but not limited to legal expenses with respect to any action taken by any Statutory Body or the Operator with respect to the misuse of the API including but not limited to, for the breach of any of the provisions of the TRAI Regulations or any other Law for the time being in force.</li>
              </ol>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  )}

export default TermsConditions
