import React, {Component} from 'react';
import {HighlightOff, Info} from '@mui/icons-material';
import {Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip} from '@mui/material';
import {throttle} from 'lodash';
import {connect} from 'react-redux';

import {
  addBalance, addBlockNo, addSMSType, addSenderId, changeDefaultSenderId, changePassword, changeStatus, editDLT, getAllAgent, getBlockNumber, updateOperator
} from '../../actions/adminPanel';
import Table from '../../components/Table';
import {dateFormat,dateFromUnixEpoch} from '../../lib/utils';
import CreateInstitute from './createInstitute';
import CustomerDetails from './details';

class AdminPanel extends Component {
  constructor(){
    super();
    this.state = {
      filteredAgentList : [],
      agentList : [],
      showSubmit : false,
      showFullDetails : false,
      showWait : false,
      showBlockNumber: false,
      blockNumber: '',
      blockedList: [],
      searchTerm: ''
    }
    this.detailsData = null;
    this.agentReq = true;
    this.addBalanceReq = false;
    this.addSMSTypeReq = false;
    this.changeStatusReq = false;
    this.updateOperatorReq = false;
    this.editDLTReq = false;
    this.addSenderIdReq = false;
    this.changeDefaultSenderIdReq = false;
    this.addBlockNo = false
    this.getBlockNo = false
    this.changePasswordReq = false
    this.throttleInput = throttle(this.throttleInput.bind(this),300);
  }
  componentDidMount(){
    this.props.getAllAgent();
  }
  componentWillReceiveProps(nextProps){
    if(this.agentReq && nextProps.agentList && nextProps.agentList.success){
      this.agentReq = false;
      const agentList = nextProps.agentList.data.institutes.map(el =>({...el, id: el.instituteId}))
      this.setState({agentList:agentList, filteredAgentList: agentList});
      return;
    }
    if(this.addBalanceReq && nextProps.addBalanceRes){
      this.addBalanceReq = false;
      if(nextProps.addBalanceRes.success){
        this.agentReq = true;
        this.props.getAllAgent();
        alert("Balance added Successfully");
        this.setState({showFullDetails : false,showWait:false});
      } else{
        alert("Something went wrong, Please try again");
        this.setState({showWait:false});
      }
      return;
    }
    if(this.addSMSTypeReq && nextProps.addSMSTypeRes){
      this.addSMSTypeReq = false;
      if(nextProps.addSMSTypeRes.success){
        this.agentReq = true;
        this.props.getAllAgent();
        alert("SMS Type added Successfully");
        this.setState({showFullDetails : false,showWait:false});
      } else{
        alert("Something went wrong, Please try again");
        this.setState({showWait:false});
      }
      return;
    }
    if(this.changeStatusReq && nextProps.changeStatusReq){
      this.changeStatusReq = false;
      if(nextProps.changeStatusReq.success){
        this.agentReq = true;
        this.props.getAllAgent();
        alert("Status Changed Successfully");
        this.setState({showFullDetails : false,showWait:false});
      } else{
        alert("Something went wrong, Please try again");
        this.setState({showWait:false});
      }
      return;
    }
    if(this.updateOperatorReq && nextProps.updateOperatorReq){
      this.updateOperatorReq = false;
      if(nextProps.updateOperatorReq.success){
        this.agentReq = true;
        this.props.getAllAgent();
        alert("Operator Updated Successfully");
        this.setState({showFullDetails : false,showWait:false});
      } else{
        alert("Something went wrong, Please try again");
        this.setState({showWait:false});
      }
      return;
    }
    if(this.editDLTReq && nextProps.editDLTReq){
      this.editDLTReq = false;
      if(nextProps.editDLTReq.success){
        this.agentReq = true;
        this.props.getAllAgent();
        alert("DLT ID Updated Successfully");
        this.setState({showFullDetails : false,showWait:false});
      } else{
        alert("Something went wrong, Please try again");
        this.setState({showWait:false});
      }
      return;
    }
    if(this.addSenderIdReq && nextProps.addSenderIdReq){
      this.addSenderIdReq = false;
      if(nextProps.addSenderIdReq.success){
        this.agentReq = true;
        this.props.getAllAgent();
        alert("Header Added Successfully");
        this.setState({showFullDetails : false,showWait:false});
      } else{
        alert("Something went wrong, Please try again");
        this.setState({showWait:false});
      }
      return;
    }
    if(this.changeDefaultSenderIdReq && nextProps.changeDefaultSenderIdReq){
      this.changeDefaultSenderIdReq = false;
      if(nextProps.changeDefaultSenderIdReq.success){
        this.agentReq = true;
        this.props.getAllAgent();
        alert("Default Header Changed Successfully");
        this.setState({showFullDetails : false,showWait:false});
      } else{
        alert("Something went wrong, Please try again");
        this.setState({showWait:false});
      }
      return;
    }
    if(this.addBlockNo && nextProps.addBlockNoRes){
      this.addBlockNo = false;
      if(nextProps.addBlockNoRes.success){
        alert("Block Number Added Successfully");
        this.setState({showWait:false});
      } else{
        alert("Something went wrong, Please try again");
        this.setState({showWait:false});
      }
      return;
    }
    if(this.getBlockNo && nextProps.blockNumberRes){
      this.getBlockNo = false;
      if(nextProps.blockNumberRes.success){
        this.setState({showWait:false, showBlockNumber: true, blockedList: nextProps.blockNumberRes.data.list});
      } else{
        alert("Something went wrong, Please try again");
        this.setState({showWait:false});
      }
      return;
    }
    if(this.changePasswordReq && nextProps.changePasswordRes){
      this.changePasswordReq = false;
      if(nextProps.changePasswordRes.success){
        this.agentReq = true;
        this.props.getAllAgent();
        alert("Password changed for requested User");
        this.setState({showFullDetails : false,showWait:false});
      } else{
        alert("Something went wrong, Please try again");
        this.setState({showWait:false});
      }
      return;
    }
  }
  getStatusColor = (data) =>{
    let color = "red";
    if(data && data.status=="testing") color = "red";
    else if(data && data.status=="live") color = "green";
    else color = "yellow";
    return color;
  }
  hideSumbitRequest = (getInstituteList) =>{
    if(getInstituteList){
      this.agentReq = true;
      this.props.getAllAgent();
    }
    this.setState({showSubmit:false});
  }
  openDetails = (data) => {
    this.detailsData = data;
    this.setState({showFullDetails : true});
  }
  closeDetails = () =>{
    this.detailsData = null;
    this.setState({showFullDetails : false});
  }
  closeDetailsAndRefresh = () => {
    this.detailsData = null;
    this.agentReq = true;
    this.setState({showFullDetails : false});
    this.props.getAllAgent();
  }
  addBalance = (params)=>{
    this.addBalanceReq = true;
    this.setState({showWait:true});
    this.props.addBalance(this.detailsData.instituteId,params);
  }
  changePassword = (instituteId,accountId,params,) => {
    this.changePasswordReq  = true;
    this.setState({showWait:true});
    this.props.changePassword(instituteId,accountId,params);
  }
  addSMSType = params =>{
    this.addSMSTypeReq = true;
    this.setState({showWait:true});
    this.props.addSMSType(this.detailsData.instituteId,params);
  }
  changeStatus = params =>{
    this.changeStatusReq = true;
    this.setState({showWait:true});
    this.props.changeStatus(this.detailsData.instituteId,params);
  }
  updateOperator = params =>{
    this.updateOperatorReq = true;
    this.setState({showWait:true});
    this.props.updateOperator(this.detailsData.instituteId,params);
  }
  editDLT = params => {
    this.editDLTReq = true;
    this.setState({showWait:true});
    this.props.editDLT(this.detailsData.instituteId,params);
  }
  addSenderId = params =>{
    this.addSenderIdReq = true;
    this.setState({showWait:true});
    this.props.addSenderId(this.detailsData.instituteId,params);
  }
  changeDefaultSenderId = params =>{
    this.changeDefaultSenderIdReq = true;
    this.setState({showWait:true});
    this.props.changeDefaultSenderId(this.detailsData.instituteId,params);
  }
  addBlockNumber = () => {
    const {blockNumber} = this.state;
    const blockNumberList = blockNumber.replace(/ /g,'').split(',')
    if(blockNumber && blockNumberList.length > 0){
      this.addBlockNo = true;
      this.props.addBlockNo({"blockedNumberList" : blockNumberList})
      this.setState({showWait: true, blockNumber: ''})
    } else {
      alert("Please provide number")
    }
  }
  shoBlockedList = () => {
    this.getBlockNo = true
    this.props.getBlockNumber();
    this.setState({showWait: true})
  }
  closeBlockedList = () => {
    this.setState({showBlockNumber: false})
  }
  getTotalSMSCount = () => {
    const {agentList} = this.state
    const smsCount = {PROMO: 0, TRANS: 0, OTP: 0}
    agentList.forEach(data => {
      if (data.status && data.status === 'live') {
        smsCount.PROMO = smsCount.PROMO + (data.smsCount?.PROMO||0)
        smsCount.TRANS = smsCount.TRANS + (data.smsCount?.TRANS||0)
        smsCount.OTP = smsCount.OTP + (data.smsCount?.OTP||0)
      }
    })
    return (
      <div style={{marginTop: -12}}>
        <p style={{display: 'inline-block', fontSize: 16, marginRight: 10}}><span style={{color: 'rgba(0, 0, 0, 0.5)'}}>Promotional:</span> {smsCount.PROMO.toLocaleString()}</p>
        <p style={{display: 'inline-block', fontSize: 16, marginRight: 10}}><span style={{color: 'rgba(0, 0, 0, 0.5)'}}>Transaction:</span> {smsCount.TRANS.toLocaleString()}</p>
        <p style={{display: 'inline-block', fontSize: 16, marginRight: 10}}><span style={{color: 'rgba(0, 0, 0, 0.5)'}}>OTP:</span> {smsCount.OTP.toLocaleString()}</p>
      </div>
    )
  }

  searchTermHandle = (e) => {
    this.setState({searchTerm:e.target.value});
    this.throttleInput(e.target.value);
  }
  throttleInput = (val) => {
    let newList = this.state.agentList.filter(data => {
      const term = val.toLowerCase()
      return data.email?.toLowerCase().includes(term) || data.info?.toLowerCase().includes(term) || data.mobile?.toLowerCase().includes(term) || data.name?.toLowerCase().includes(term) || data.userId?.toLowerCase().includes(term)
    });
    this.setState({filteredAgentList: newList});
  }
  clearSearch = (e) => {
    e.stopPropagation()
    this.searchTermHandle({target: {value: ''}})
  }
  render() {
    const isMobile = window.isMobile();
    const {showWait,showFullDetails, blockNumber, showBlockNumber, blockedList, searchTerm, filteredAgentList} = this.state;
    return (
      <div className='sms-parent-container' style={isMobile ? {padding: 8} : {}}>
        <div className='sms-small-container'>
          {
            this.state.showSubmit ?
              <CreateInstitute hideSumbitRequest={this.hideSumbitRequest} />
              :
              <div>
                <div style={{display: 'flex', alignItems: 'center', gap: 16}}>
                  <div>
                    <p style={{fontSize: 14}}>Total SMS Count of Customer</p>
                    {this.getTotalSMSCount()}
                  </div>
                  <div style={{marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 16}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                      <TextField
                        className={"edit"}
                        value={blockNumber}
                        onChange={(e)=>{this.setState({blockNumber : e.target.value})}}
                        size="small"
                        variant="standard"
                        required
                        label="Enter Block Number" />
                      <Button onClick={this.addBlockNumber} variant='outlined'>
                        Add Block Number
                      </Button>
                    </div>
                    <Button onClick={() => this.setState({showSubmit : true})} variant='outlined'>
                      Create Institute
                    </Button>
                    <Button onClick={this.shoBlockedList} variant='outlined'>
                      Show Block Number
                    </Button>
                  </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: 16, padding: '16px 0'}}>
                  <TextField
                    className={"edit"}
                    value={searchTerm}
                    onChange={this.searchTermHandle}
                    size="small"
                    variant="standard"
                    label="Search Customer" />
                  {searchTerm && 
                    <Tooltip title="Clear Search">
                      <IconButton onClick={this.clearSearch}>
                        <HighlightOff />
                      </IconButton>
                    </Tooltip>
                  }
                </div>
                <Table
                  column={[
                    {name: 'User ID', value: 'userId'},
                    {name: 'Name', value: 'name'},
                    {name: 'Institute ID', value: 'instituteId'},
                    {name: 'Status', value: 'status', cell: val => <Chip
                      label={val}
                      color={val === 'testing' ? "error" : val === 'live' ? 'success' : 'warning'}
                      size="small"
                      variant="outlined"
                    />},
                    {name: 'Header', value: 'senderId'},
                    {name: 'Balance', value: 'smsCount', cell: (val, row) => row.smsTypeTaken ?
                      row.smsTypeTaken.map(el=>(
                        <span key={"smsCountadmin"+el} style={{fontSize:12,marginRight:10}}>{el} : <span style={{fontSize:16}}>{val[el]}</span></span>
                      ))
                      : null},
                    {name: 'Customer Name', value: 'info'},
                    {name: 'Created Date', value: 'createdAt', style: {width: 120}, cell: val => dateFormat(dateFromUnixEpoch(val))},
                    {name: 'Actions', value: 'id', cell: (val, row) => (
                      <Tooltip title="Details"><IconButton aria-label='Details' onClick={e => {e.stopPropagation();this.openDetails(row)}}><Info /></IconButton></Tooltip>
                    )}
                  ]}
                  serialNumber
                  data={filteredAgentList}
                  showPagination
                  pageOptions={[100, 200, 500]}
                  defaultPageSize={500}
                />
              </div>
          }
        </div>
       
        {
          showFullDetails ?
            <CustomerDetails
              data={this.detailsData}
              closeDetails={this.closeDetails}
              closeDetailsAndRefresh={this.closeDetailsAndRefresh}
              changeStatus={this.changeStatus}
              updateOperator={this.updateOperator}
              editDLT={this.editDLT}
              addSenderId={this.addSenderId}
              changeDefaultSenderId={this.changeDefaultSenderId}
              addBalance={this.addBalance}
              changePassword={this.changePassword}
              addSMSType={this.addSMSType} />
            : null
        }
        {
          showBlockNumber &&
          <Dialog open={true} onClose={this.closeBlockedList}>
            <DialogTitle>Blocked Number List</DialogTitle>
            <DialogContent dividers>
              <div>
                {blockedList.map(data=>(<div style={{fontSize: 16}} key={data._id}>{data.mobileNumber}</div>))}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeBlockedList}>Close</Button>
            </DialogActions>
          </Dialog>
        }
        {
          showWait ?
            <Dialog open={true}>
              <DialogContent>
                <CircularProgress />
              </DialogContent>

            </Dialog>
            : null
        }
      </div>
    )
  }
}
function mapStateToProps(state){
  return {
    agentList : state.adminPanel.agentResponse,
    addBalanceRes : state.adminPanel.addBalanceResponse,
    changeStatusReq : state.adminPanel.changeStatusResponse,
    updateOperatorReq: state.adminPanel.updateOperatorResponse,
    editDLTReq: state.adminPanel.editDLTResponse,
    addSenderIdReq : state.adminPanel.addSenderIdResponse,
    changeDefaultSenderIdReq : state.adminPanel.changeDefaultSenderIdResponse,
    addSMSTypeRes : state.adminPanel.addSMSTypeResponse,
    addBlockNoRes: state.adminPanel.addBlockNoResponse,
    blockNumberRes: state.adminPanel.blockNumberResponse,
    changePasswordRes: state.adminPanel.changePasswordResponse
  }
}
export default connect(mapStateToProps,{getAllAgent,addBalance,changeStatus,updateOperator,editDLT,addSenderId,changeDefaultSenderId,addSMSType,addBlockNo,getBlockNumber, changePassword})(AdminPanel)
