import { Map } from 'immutable';

import { GET_TEACHER, DELETE_TEACHER, ADD_TEACHER, UPDATE_TEACHER } from '../lib/constant';

const initialState = Map({getResponse : {}, deleteResponse : {}, addResponse : {}, updateResponse : {}});

export default function(state = initialState , action ) {
	switch(action.type){
        case GET_TEACHER :
            return {...state,getResponse:action.payload};
        case DELETE_TEACHER :
            return {...state,deleteResponse:action.payload};
        case ADD_TEACHER :
            return {...state,addResponse:action.payload};
        case UPDATE_TEACHER :
            return {...state,updateResponse:action.payload};
        default :
		     return state;
    }
}
