import Api from '../api';
import {LOGIN, LOGIN_URL} from '../lib/constant';
import {addToLocalStorage} from '../lib/utils';

export function loginRequest(param){
  const request = Api.post(LOGIN_URL,param);
  return (dispatch) => {
    request.then(({data}) => {
      if(data.success){
        addToLocalStorage(data.data);
      }
      dispatch({type:LOGIN,payload:data});
    })
  };
}
