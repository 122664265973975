import React from 'react';
import {CallOutlined, MailOutline} from '@mui/icons-material';
import {Card, CardContent} from '@mui/material';

import Heading from './Heading';

const Contact = () => {
  const handleIconClick = e => e.stopPropagation()
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL
  const contactPhonePrimary = process.env.REACT_APP_CONTACT_PHONE_PRIMARY
  return (
    <Card>
      <CardContent>
        <Heading>Contact us</Heading>
        <div className='dashboard-contact-us'>
          <p>
            <MailOutline onClick={handleIconClick} />
            <span>{contactEmail}</span>
          </p>
          <p>
            <CallOutlined onClick={handleIconClick} />
            <span>{contactPhonePrimary}</span>
          </p>  
        </div>
      </CardContent>
    </Card>
  )

}

export default Contact