import { Map } from 'immutable';

import { GROUP_DETAILS, ADD_USER_GROUP, DELETE_USER_GROUP, UPDATE_GROUP, DELETE_GROUP, ADD_GROUP, ALL_GROUP_DETAILS,DOWNLOAD_GROUP_CONTACT } from '../lib/constant';

const initialState = Map({allGroupDetails : {}, details : {}, addUser : {}, deleteUser : {}, updateGroup : {}, addGroup : {}, deleteGroup : {}, downloadContacts:{}});

export default function(state = initialState , action ) {
	switch(action.type){
		case ALL_GROUP_DETAILS :
			return {...state,allGroupDetails:action.payload};
        case GROUP_DETAILS :
            return {...state,details:action.payload};
		case ADD_USER_GROUP :
            return {...state,addUser:action.payload};
		case DELETE_USER_GROUP :
            return {...state,deleteUser:action.payload};
		case UPDATE_GROUP :
            return {...state,updateGroup:action.payload};
        case ADD_GROUP :
            return {...state,addGroup:action.payload};
        case DELETE_GROUP :
            return {...state,deleteGroup:action.payload};
		case DOWNLOAD_GROUP_CONTACT :
            return {...state,downloadContacts:action.payload};
        default :
		     return state;
    }
}
