import { Map } from 'immutable';

import { LOGIN } from '../lib/constant';

const initialState = Map({loginResponse : {}});

export default function(state = initialState , action ) {
	switch(action.type){
        case LOGIN :
            return {...state,loginResponse:action.payload};
        default :
		     return state;
    }
}
