import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';

import Table from '../../components/Table';

const SmsReport = ({closeAllContacts, singleHistory}) =>  {
  const returnDate = (clickTime)=>{
    let res=  "---";
    if(clickTime){
      res = new Date(clickTime).toLocaleString();
    }
    return res;
  };
   
  return (
    <Dialog
      open={true}
      onClose={closeAllContacts}
    >
      <DialogTitle>Smart URL Tracking Report</DialogTitle>
      <DialogContent>
        {singleHistory.length ? 
          <Table
            column={[
              {name: 'Contact Name', value: 'name', cell: val => val || "---"},
              {name: 'Contact Number', value: 'mobile'},
              {name: 'Recent Visited Time', value: 'clickTime', cell : val => returnDate(val)},
              {name: 'No of Visit(s)', value: 'clickCount'},
              {name: 'Device Type', value: 'deviceType', cell: val => val || "---"},
              {name: 'Browser', value: 'browser', cell: val => val || "---"}
            ]}
            data={singleHistory}
            showPagination
            serialNumber
            pageOptions={[15]}
          /> : <p style={{fontSize: 16}}>No User has visited the given URL through this Campaign.</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAllContacts}>
                Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SmsReport
