import React from 'react';
import {Close} from '@mui/icons-material';
import {Button, Chip} from '@mui/material';

let styles = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin : '8px 0',
    alignItems: 'center',
    gap: 8
  }
};

const RecipientChip = ({originalContactList, contactList, contactListCallback, renderLess, showClearAll, handleShowMoreChip, contactListClearAllCallback}) => {
  const handleRequestDeleteContact = (id,type) => {
    let contactListNew = originalContactList || contactList;
    if(id == 'all'){
      contactListNew.all = false;
    } else{
      const contactToDelete = contactListNew[type].map((data) => data.id).indexOf(id);
      contactListNew[type].splice(contactToDelete, 1);
    }
    contactListCallback(contactListNew);
  };
  const renderChip = (data,type) => {
    return (
      <Chip
        key={data.id}
        label={data.name}
        variant="outlined"
        onDelete={() => handleRequestDeleteContact(data.id,type)}
      />
    );
  }
  let dataGroup = [];
  let showLess = false;
  const isMobile  = window.isMobile();
  const renderLessVal = isMobile ? 1 : 7;
  if(contactList.all){
    dataGroup.push({data:{id : 'all', name : 'All'},type:'group'});
  }
  contactList.contact.forEach((data)=>{dataGroup.push({data,type:'contact'})});
  contactList.group.forEach((data)=>{dataGroup.push({data,type:'group'})});
  contactList.extra.forEach((data)=>{dataGroup.push({data,type:'extra'})});
  if(renderLess && dataGroup.length > renderLessVal){
    showLess = true;
    dataGroup = dataGroup.splice(0,renderLessVal);
  }
  if(isMobile && !renderLess){
    styles.wrapper.maxHeight = 'calc(100vh - 150px)';
  }
  return(
    <div style={styles.wrapper}>
      {dataGroup.map((data)=>{return renderChip(data.data,data.type)})}
      {
        showLess ?
          <Button onClick={handleShowMoreChip}>Show More...</Button>
          : null
      }
      {
        showClearAll ?
          <Button onClick={e => {e.stopPropagation();contactListClearAllCallback()}} endIcon={<Close />}>Clear All</Button>
          : null
      }
    </div>
  )
}

export default RecipientChip
