import React, {useMemo, useState} from 'react';
import {Checkbox, Table as MuTable, Paper, TableBody, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography} from '@mui/material';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import {alpha, styled} from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const EnhancedTableToolbar = ({numSelected, actionEl}) => {
  return (
    <Toolbar
      sx={{
        pl: {sm: 2},
        pr: {xs: 1, sm: 1},
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}
      {actionEl}
    </Toolbar>
  );
}


const Table = ({
  column, data = [], enableCheckbox = false, selected = [], onSelectAllClick, handleSelect, actionEl, showPagination = false, pageOptions=[20, 50, 100], showToolbar = true,
  defaultPageSize, serialNumber = false
}) => {
  const rowCount = data?.length || 0
  const isSelected = (id) => selected.indexOf(id) !== -1
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(showPagination ? defaultPageSize ? defaultPageSize : pageOptions[0] : -1)
  const handleChangePage = (e, newPage) => {
    e.stopPropagation()
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (e, newPageSize) => {
    e.stopPropagation()
    setPageSize(parseInt(e.target.value, 10));
    setPage(0);
  }
  const visibleRows = useMemo(
    () => 
      showPagination ? data.slice(
        page * pageSize,
        page * pageSize + pageSize,
      ) : data,
    [page, pageSize, data, showPagination]
  )

  return (
    <>
      {(enableCheckbox || actionEl) && showToolbar && <EnhancedTableToolbar numSelected={selected.length} actionEl={actionEl} />}
      <TableContainer component={Paper}>
        <MuTable aria-label="customized table">
          <TableHead>
            <TableRow>
              {serialNumber && <StyledTableCell align="left" sx={{width: 65}}>S. N.</StyledTableCell>}
              {enableCheckbox &&
              <StyledTableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={selected.length > 0 && selected.length < rowCount}
                  checked={rowCount > 0 && selected.length === rowCount}
                  onChange={onSelectAllClick}
                  style={{color: 'white'}}
                  inputProps={{
                    'aria-label': 'select all desserts'
                  }}
                />
              </StyledTableCell>
              }
              {column.map(el => (
                <StyledTableCell align="left" key={el.name} style={el.style || {}}>{el.name}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              return (
                <StyledTableRow
                  key={`table-row-${index}`}
                  {...(enableCheckbox ? {role: 'checkbox', 'aria-checked': isItemSelected, selected: isItemSelected, onClick: (event) => handleSelect(row.id, row)} : {})}
                >
                  {serialNumber && <StyledTableCell align="left">{(page * pageSize) + index + 1}</StyledTableCell>}
                  {enableCheckbox &&
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': `enhanced-table-checkbox-${index}`
                      }}
                    />
                  </StyledTableCell>
                  }
                  {column.map((el, index) => <StyledTableCell align="left" key={`${el.value}-${index}`} style={el.style || {}}>
                    {el.cell ? el.cell(row[el.value], row) : row[el.value]}
                  </StyledTableCell>)}
                </StyledTableRow>
              )})}
          </TableBody>
        </MuTable>
      </TableContainer>
      {showPagination &&
      <TablePagination
        rowsPerPageOptions={pageOptions}
        component="div"
        count={data?.length}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />}
    </>
  );
}

export default Table
