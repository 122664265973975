import React, {Component} from 'react';
import {Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel} from '@mui/material';
import ReactFileReader from 'react-file-reader';
import {connect} from 'react-redux';
import * as XLSX from 'xlsx';

import {uploadBulkContact} from '../../actions/user';
import {GAEventTracker, GAModalView} from '../../lib/googleAnalytics';
import Select from '../Select';
import GroupContactUpload from './groupContactUpload';

class UploadPanel extends Component{
  constructor(){
    super();
    this.state = {
      showWait : false,
      contactData : null,
      uploadContent : false,
      mobCol: 0,
      cols: [],
      excelData: [],
      nameCol: 0,
      noName: false,
      selectValue: []
    };
    this.uploadReq = false;
  }
  componentDidMount(){
    GAModalView('Bulk-Contact-Upload');
  }
  componentWillReceiveProps(nextProps){
    let {bulkContact} = nextProps;
    if(this.uploadReq && bulkContact){
      this.uploadReq = false;
      if(bulkContact.success){
        if(bulkContact.data.successCount){
          this.props.refreshList();
        }
        this.setState({uploadContent:bulkContact.data,showWait:false});
      } else if(bulkContact.err){
        alert(bulkContact.err.msg);
      }else{
        alert("Something went wrong!");
        window.location.reload();
      }
    }
  }
  uploadBulkContact = () =>{
    let params = {
      contacts : this.state.contactData,
      groups : this.state.selectValue ? [this.state.selectValue] : []
    }
    this.uploadReq = true;
    GAEventTracker({category : 'Bulk-Contact-Upload', action : 'Save', label : params.groups.length ? 'Group' : 'Non-Group', value : params.contacts.length});
    this.props.uploadBulkContact(params);
    this.setState({showWait:true});
  }
  nameNumberCheck = contact => (contact[0].length<=30 && this.numberCheck(contact[1]))
  numberCheck = contact =>{
    let numRegex = new RegExp("^[3-9][0-9]{9}$");
    return numRegex.test(Number(contact));
  }
  handleUploadFiles = files => {
    const reader = new FileReader();
    this.setState({
      showWait: true,
      noName: false,
      mobCol: 0,
      nameCol: 0,
      excelData: [],
      cols: [],
      contactData: null
    });
    reader.onload = () => {
      const bstr = reader.result;
      const wb = XLSX.read(bstr, {type: 'binary'});
      const ws = wb.Sheets[wb.SheetNames[0]];
      const excelData = XLSX.utils.sheet_to_json(ws);
      this.setState({excelData, showWait: false, cols: Object.keys(excelData[0])});
    };
    reader.readAsBinaryString(files[0]);
  }

  handleColChange = (type) => (e) => this.setState({[type]: e.target.value})

  getNumberNameCol = (label, type) => {
    const {cols} = this.state;
    if (cols.length === 0) {
      return null;
    }
    const options = [{name: 'Choose', value: 0}, ...cols.map(el => ({value: el, name: el.trim()}))]
    return (
      <Select
        value={this.state[type]}
        onChange={this.handleColChange(type)}
        label={label}
        sx={{width: 150}}
        options={options}
      />
    );
  }

  handleCheckBox = () => this.setState({noName: !this.state.noName})

  handleApply = () => {
    const {mobCol, nameCol, noName, excelData} = this.state;
    const contactData = [];
    excelData.forEach(data => {
      if (data[mobCol]) {
        const num = data[mobCol].toString().trim();
        if (this.numberCheck(num)) {
          contactData.push({mobile: num, name: noName ? `Contact${ data[mobCol] }` : data[nameCol].toString().trim()});
        }
      }
    });
    this.setState({contactData});
    GAEventTracker({category: 'Bulk-Contact-Upload', action: 'Upload Excel', label: contactData.length ? 'valid' : 'invalid', value: contactData.length});
  }

  handleChange = (e) => {
    this.setState({selectValue : e.target.value});
  }

  render(){
    const {showWait,contactData,uploadContent, noName, cols, mobCol, nameCol} = this.state;
    return(
      <>
        <Dialog open onClose={this.props.hideDialog}>
          <DialogTitle>Import Bulk Contacts</DialogTitle>
          <DialogContent dividers>
            {
              uploadContent == false ?
                <div>
                  <div>
                    <ReactFileReader handleFiles={this.handleUploadFiles} fileTypes={ '.csv,.xlsx,.xls' } elementId="fileUpload">
                      <Button variant='outlined'>
                        Upload Excel (.xlsx)
                      </Button>
                    </ReactFileReader>
                    <p style={{marginTop: 4, fontSize: 12}}>
                        Upload Excel File and choose columns for Mobile Number and Contact Name from your uploaded file to add bulk Contacts.
                    </p>
                  </div>
                  {cols.length > 0 &&
                      <div style={{padding: '10px 0', display: 'flex', flexDirection: 'column', gap: 16}}>
                        {this.getNumberNameCol('Number Column', 'mobCol')}
                        <div>
                          {this.getNumberNameCol('Name Column', 'nameCol')}
                          <FormControlLabel
                            label="I don't have Name column"
                            control={
                              <Checkbox
                                checked={noName}
                                onChange={this.handleCheckBox}
                              />
                            }
                            style={{marginLeft: 16}}
                          />
                        </div>
                        <div>
                          <Button onClick={this.handleApply} variant='outlined' disabled={mobCol === 0 || (noName === false && nameCol === 0)}>
                            Preview Contacts
                          </Button>
                        </div>
                      </div>
                  }
                  <div>
                    <GroupContactUpload
                      groupList={this.props.groupList}
                      selectValue={this.state.selectValue}
                      handleChange={this.handleChange}
                      contactData={contactData} />
                  </div>
                  {
                    contactData == null || contactData.length == 0 ?
                      <div>
                        {
                          contactData != null && contactData.length ==0 ?
                            <p style={{margin:10,color:'red'}}>No valid numbers are fetched from selected Column, Please select appropriate column!</p>
                            : null
                        }
                      </div>

                      : null
                  }
                </div>
                :
                <div>
                  <p>
                    {uploadContent.successCount ? <span>Successfully {uploadContent.successCount} contact added</span> : null}
                    {uploadContent.failedCount ? <span>{uploadContent.successCount ? " and " : null}{uploadContent.failedCount} contact already exists!</span> : null}
                  </p>
                </div>
            }

          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.hideDialog}>
                    Close
            </Button>
            <Button disabled={!(contactData && contactData.length)} variant="contained" onClick={this.uploadBulkContact}>
              Save
            </Button>
          </DialogActions>
          
        </Dialog>
        {
          showWait ?
            <Dialog open>
              <DialogContent sx={{width: 100}}>
                <div>
                  <CircularProgress />
                </div>
              </DialogContent>
            </Dialog>
            :
            null
        }
      </>
    )
  }
}
function mapStateToProps(state){
  return{
    bulkContact : state.userResponse.bulkContactResponse,
    groupList: state.userResponse.userResponse
  }
}

export default connect(mapStateToProps,{uploadBulkContact})(UploadPanel)
