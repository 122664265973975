import React, {Component} from 'react';
import {Button, CircularProgress, Dialog, DialogActions, DialogTitle} from '@mui/material';
import {connect} from 'react-redux';

import {deleteTemplate} from '../../actions/template';

class Delete extends Component {
  constructor() {
    super();
    this.state = {
      btnDisabled: false
    };
    this.deleteReq = false;
  }
  componentWillReceiveProps(nextProps) {
    const {deleteRes} = nextProps;
    if (this.deleteReq === true && deleteRes) {
      if (deleteRes.success) {
        alert('Template successfully deleted!');
        this.props.handleSuccess();
      } else if (deleteRes.err) {
        alert(deleteRes.err.msg);
        this.setState({btnDisabled: false})
      } else {
        alert('Something went wrong!');
        window.location.reload();
      }
    }
  }
  delete = () => {
    this.setState({btnDisabled: true});
    this.deleteReq = true;
    this.props.deleteTemplate(this.props.data.id);
  }
  render() {
    const {onClose} = this.props;
    const {btnDisabled} = this.state;
    return (
      <Dialog open>
        <DialogTitle>Delete Template ?</DialogTitle>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button disabled={ btnDisabled } onClick={this.delete} variant='contained' color="error">Delete</Button>
          {
            btnDisabled ?
              <CircularProgress size={ 20 } thickness={ 2 } />
              : null
          }
        </DialogActions>
      </Dialog>
    );
  }
}
function mapStateToProps(state) {
  return {
    deleteRes: state.template.deleteTemplate
  };
}

export default connect(mapStateToProps, {deleteTemplate})(Delete);
