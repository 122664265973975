import React from 'react';
import {Link} from 'react-router-dom';

import {linkedinLogo} from './WebLogo';

const footerItem = [
  {link : "/contact-us", name : "Free Demo"},
  {link : "/bulk-sms-pricing", name : "Pricing"},
  {link : "/bulk-sms-services", name : "Services"},
  {link : "/contact-us", name : "Contact Us"},
  {link : "/about-us", name : "About Us"}
]

const Footer = () => {
  return(
    <div className="globalContent footer-container">
      <div className="container-lg">
        <div className="col">
          <ul>
            {
              footerItem.map(data=>(
                <li key={"footer"+data.name}>
                  <Link to={data.link} className="" key={data.name}>{data.name}</Link>
                </li>
              ))
            }
            <li>
              <a href="/guide">Help</a>
            </li>
          </ul>
        </div>
        <div className="col col-center">Powered By {process.env.REACT_APP_COMPANY_NAME}
          <a
            href={process.env.REACT_APP_LINKEDIN_PROFILE}
            target="_blank" style={{width: 30,margin:'10px auto', display:'block'}}
            dangerouslySetInnerHTML={{__html: linkedinLogo}}
            rel="noreferrer"
          />
          {/* <a href='https://www.softwaresuggest.com/e-padhaisms' target='_blank' style={{width: 175,margin:'0 auto', display:'block'}} rel="noreferrer"                >
            <img src='https://d1myhw8pp24x4f.cloudfront.net/badge/find_us_on.png' width='174px' alt="Find Us" />
          </a> */}
          <p style={{width: 175,margin:'0 auto',display: 'flex',"justifyContent": 'center'}}>
            <img src='https://client-ui.s3.ap-south-1.amazonaws.com/Website/Images/ISO-27001.svg' width='70px' />
            <img src='https://client-ui.s3.ap-south-1.amazonaws.com/Website/Images/ISO-9001.svg' width='70px' />
          </p>
        </div>
        <div className="col col-right">
          <ul>
            <li>Call : {process.env.REACT_APP_CONTACT_PHONE_PRIMARY}</li>
            <li>Email : {process.env.REACT_APP_CONTACT_EMAIL}</li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions">Terms of Use</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer
