import { Map } from 'immutable';

import { GET_ALL_AGENT_DETAILS, POST_AGENT_DETAILS, ADD_BALANCE, CHANGE_STATUS, UPDATE_OPERATOR,EDIT_DLT, ADD_SENDER_ID,CHANGE_DEFAULT_SENDER_ID,ADD_SMS_TYPE, ADD_BLOCK_NUMBER,GET_BLOCK_NUMBER,CHANGE_PASSWORD, DELETE_SENDER_ID } from '../lib/constant';

const initialState = Map({agentResponse : {},sumbitAgentResponse:{},addBalanceResponse:{},changeStatusResponse:{}, updateOperatorResponse: {},editDLTResponse: {}, addSenderIdResponse:{}, changeDefaultSenderIdResponse:{}, addSMSTypeResponse:{}, addBlockNoResponse:{}, blockNumberResponse:{}, changePasswordResponse:{}, deleteSenderIdResponse: {} });

export default function(state = initialState , action ) {
	switch(action.type){
        case GET_ALL_AGENT_DETAILS :
            return {...state,agentResponse:action.payload};
		case POST_AGENT_DETAILS :
            return {...state,sumbitAgentResponse:action.payload};
		case ADD_BALANCE :
			return {...state,addBalanceResponse:action.payload};
		case CHANGE_STATUS :
			return {...state,changeStatusResponse:action.payload};
		case UPDATE_OPERATOR :
			return {...state,updateOperatorResponse:action.payload};
		case EDIT_DLT :
			return {...state,editDLTResponse:action.payload};
		case ADD_SENDER_ID :
			return {...state,addSenderIdResponse:action.payload};
		case CHANGE_DEFAULT_SENDER_ID :
			return {...state,changeDefaultSenderIdResponse:action.payload};
		case ADD_SMS_TYPE :
			return {...state,addSMSTypeResponse:action.payload};
		case ADD_BLOCK_NUMBER:
			return {...state,addBlockNoResponse:action.payload};
		case GET_BLOCK_NUMBER:
			return {...state,blockNumberResponse:action.payload};
		case CHANGE_PASSWORD:
			return {...state,changePasswordResponse:action.payload};
  case DELETE_SENDER_ID:
    return { ...state, deleteSenderIdResponse: action.payload };
        default :
		     return state;
    }
}
